import React from 'react'
import LuxrideSouthamDecide from '../../components/luxridesoutham/LuxrideSouthamDecide'
import LuxrideSouthamex from '../../components/luxridesoutham/LuxrideSouthamex'
import LuxrideSouthamFaqs from '../../components/luxridesoutham/LuxrideSouthamFaqs'
import LuxrideSouthamLinefleet from '../../components/luxridesoutham/LuxrideSouthamLinefleet'
import LuxrideSouthampExcl from '../../components/luxridesoutham/LuxrideSouthampExcl'
import LuxrideSouthamworkout from '../../components/luxridesoutham/LuxrideSouthamworkout'
import LuxrideSouthBusiness from '../../components/luxridesoutham/LuxrideSouthBusiness'
import LuxrideSouthCovid from '../../components/luxridesoutham/LuxrideSouthCovid'
import LuxrideSouthmain from '../../components/luxridesoutham/LuxrideSouthmain'
import LuxrideSouthPremium from '../../components/luxridesoutham/LuxrideSouthPremium'
import LuxrideSouthquote from '../../components/luxridesoutham/LuxrideSouthquote'
import LuxrideSouthTest from '../../components/luxridesoutham/LuxrideSouthTest'

const LuxrideSouthampPage = () => {
  return (
    <div style={{paddingTop:"200px",backgroundColor:"#000"}}>
        <LuxrideSouthmain />
        <LuxrideSouthamex />
        <LuxrideSouthampExcl />
        <LuxrideSouthamLinefleet />
        <LuxrideSouthquote />
        <LuxrideSouthPremium />
        <LuxrideSouthBusiness />
        <LuxrideSouthTest />
        <LuxrideSouthCovid />
        <LuxrideSouthamworkout />
        <LuxrideSouthamDecide />
        <LuxrideSouthamFaqs />
    </div>
  )
}

export default LuxrideSouthampPage