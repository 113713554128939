import { domainUrl} from "../../constants/apiconstans/domanUrl"
import { message } from "antd"
export const ContactUs = (formData)=>{
    var requestOptions = {
        method: 'POST',
        redirect: 'follow',
        body: formData
    }
   return (
        fetch(domainUrl + `/StoreContactUs`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result?.success)
                {
                    message.success("Submit successfully. Thank you!!!")
                    return 1
                }
            else
                throw result?.message
        })
        .catch(error => {
            message.error(error)
            return 0
        })
   )
}