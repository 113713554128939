import React from 'react'
import { Row, Col, Typography,Space, Image, Card, Avatar } from 'antd'
import '../index.css'
import {
    StarFilled
} from '@ant-design/icons'

const { Title, Text } = Typography

const TestimonialCards = () => {
  return (
    <div className='card-cs-test'>
        <Row gutter={[24,24]}>
            <Col 
                lg={7} md={24} sm={24} xs={24} 
                className='align-center'
                data-aos="fade-right"
                data-aos-duration="1000" 
                data-aos-once="true"
                data-aos-delay="500"
                >
                <Card className='border-0 py-4 testmonials-card-shadow'>
                    <Space 
                        direction='vertical' 
                        align='center' 
                        className='w-100 text-center'
                        size={13}
                        >
                        <Image 
                            src='./luxride/quote-1.png' 
                            alt="epic-ride-london" 
                            preview={false}
                            />
                        <Title level={5}>
                            “Epic ride have been providing their services to us for 3 years now and are our first choice for chauffeur driven cars. Efficient and professional, they provide excellent service with knowledgeable chauffeurs. I traveled in utmost comfort and style.”
                        </Title>
                        <Space size={2}>
                            {
                                new Array(5)?.fill(null)?.map((_, index)=>
                                    <StarFilled 
                                        className='brand-color testmonials-stars'
                                        key={index}
                                        />
                                    )
                            }
                        </Space>
                        <Avatar
                            src="/luxride/test-2.jpg" 
                            alt="epic-ride-london" 
                            size={60}
                            />
                        <Space size={0} direction='vertical'>
                            <Title level={4} className='m-0'>John Smith</Title>
                            <Text 
                                strong
                                className='text-uppercase brand-color-blue'
                                >
                                business
                            </Text>
                        </Space>
                    </Space>
                </Card>
            </Col>
            <Col lg={10} md={24} sm={24} xs={24}>
                <Card className='border-0 bg-card py-4 px-3'>
                    <Space 
                        direction='vertical' 
                        size={13} 
                        align='center' 
                        className='w-100 text-center'
                        >
                        <Image 
                            src='./luxride/quote.png' 
                            alt="epic-ride-london" 
                            preview={false}
                            />
                        <Title level={4} className='text-white'>
                            "The car service worked very well.The best I have used in terms of communication - they have an automated system that sends out emails like the one below. It even sends wake up calls to the drivers! Nice car, professional driver and a personal touch that we have not experienced elsewhere. Price was reasonable for a luxury transfer. Overall, I can confidently say that Epic Ride London surpassed my expectations in every aspect.  I highly recommend their chauffeur services to anyone in need of a reliable and luxurious transportation experience in London. "
                        </Title>
                        <Space size={2}>
                            {
                                new Array(5)?.fill(null)?.map((_, index)=>
                                    <StarFilled 
                                        className='brand-color testmonials-stars'
                                        key={index}
                                        />
                                    )
                            }
                        </Space>
                        <Avatar
                            src="/luxride/test-1.jpg" 
                            alt="epic-ride-london" 
                            size={60}
                            />
                        <Space size={0} direction='vertical'>
                            <Title level={4} className='m-0 text-white'>PETER HARVEY</Title>
                            <Text 
                                strong
                                className='text-uppercase brand-color'
                                >
                                Traveler
                            </Text>
                        </Space>
                    </Space>
                </Card>
            </Col>
            <Col 
                lg={7} md={24} sm={24} xs={24} 
                className='align-center'
                data-aos="fade-left"
                data-aos-duration="1000" 
                data-aos-once="true"
                data-aos-delay="500"
                >
                <Card className='border-0 py-4 testmonials-card-shadow'>
                    <Space 
                        direction='vertical' 
                        align='center' 
                        className='w-100 text-center'
                        size={13}
                        >
                        <Image 
                            src='./luxride/quote-1.png' 
                            alt="epic-ride-london" 
                            preview={false}
                            />
                        <Title level={5}>
                            “A wonderful service. The scenic route along the Embankment as a freebie at the end of the evening really made our night. My mum's birthday was made all the better by this excellent service. We traveled in comfort and style.”
                        </Title>
                        <Space size={2}>
                            {
                                new Array(5)?.fill(null)?.map((_, index)=>
                                    <StarFilled 
                                        className='brand-color testmonials-stars'
                                        key={index}
                                        />
                                    )
                            }
                        </Space>
                        <Avatar
                            src="/luxride/ceo.jpg" 
                            alt="epic-ride-london" 
                            size={60}
                            />
                        <Space size={0} direction='vertical'>
                            <Title level={4} className='m-0'>Mike Peter</Title>
                            <Text 
                                strong
                                className='text-uppercase brand-color-blue'
                                >
                                business
                            </Text>
                        </Space>
                    </Space>
                </Card>
            </Col>
        </Row>
    </div>
  )
}

export {TestimonialCards}