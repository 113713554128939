import React from 'react'
import { Col, Row, Space, Typography } from 'antd'
import { BreadCrumb } from '../../ui-elements'

const { Text, Title } = Typography

const TermsCondition = () => {

    const data = {
        title:"EPIC Ride London LIMITED TERMS & CONDITIONS",
        desc:"Outlined below are the Terms & Conditions by which Epic Ride London conduct business with our Clients, our Passengers and the Bookers (person booking the journeys).",
        content:[
            {
                title:"General",
                list:[
                    {
                        lst:"Epic Ride London is the Trading name of Epic Chauffeurs. We are Registered in England & Wales Epic Ride London Limited Company with Registration # *******, TFL Operator License # ******* and VAT Registration # *******. By using our services, you agree to the Epic Ride London terms and conditions."
                    },
                    {
                        lst:"Epic Ride London reserves the right to change T&Cs at any time for quality testing and development. Information is subject to change and will be displayed on the Epic Ride London’s home page to notify users within 14 days of new terms & Conditions in place. Continued use of our website by the user agrees to comply with and be bound by the terms and conditions unless objectified. Further usage of Epic Ride London services is solely accepted by the user."
                    },
                    {
                        lst:"Please note that loud music inside any of our vehicles is not permitted. This is for your safety and to avoid any distraction for the chauffeur driving you."
                    },
                    {
                        lst:"Alcohol consumption is not allowed in our vehicles. Prior permission is required for alcohol in the car. The Customer is liable to pay for any damages caused as a result of any spilling on the seats or other vehicle surfaces."
                    },
                    {
                        lst:"Under no circumstances are any drugs permitted. Our Chauffeur has the right to remove any passenger under the influence of drugs. Additionally, there will be no refunds, and the customer will bear all responsibility for the full trip."
                    },
                    {
                        lst:"All of our chauffeur services are pre-booked, which means that the vehicle may have another job waiting after the existing trip. If more time is required for hourly bookings, it is at the chauffeur’s discretion. Additional hours should be scheduled in advance to prevent any inconvenience."
                    },
                ]
            },

            {
                title:"User Registration",
                list:[
                    {
                        lst:"All information provided by the user must be accurate and complete through all parts of the registration process. Unauthorised use of this website may give rise to a claim for damages and/or be a criminal offence and may result in Suspension of registered customer accounts."
                    },
                ]
            },
            {
                title:"Contract of Service and changes to offers",
                list:[
                    {
                        lst:"All conditions of contract apply to arranged services provided by Epic Ride London."
                    },
                    {
                        lst:"Subsequent to the booking and starting the service, additional fees may occur, such as extra hours, additional stops, Waiting time, car parking, and address changes according to the latest price structure."
                    },
                    {
                        lst:"All hourly rides must end in the same city as the start of the service."
                    },
                    {
                        lst:"Users can choose between our different types of class vehicles; Business Class, Business Van, and Luxury Class alongside Luxury SUV. Users can choose vehicles from our Vehicles’ page on the Epic Ride London homepage. Images shown on our Vehicle page are in place to demonstrate the class of vehicles we provide."
                    },
                    {
                        lst:"Given the opportunity, the user may choose to upgrade to a higher class vehicle. Upon the availability of vehicles, the Class upgrade is free, but payment for the different categories will be charged accordingly. You can call Epic Ride London anytime to discuss the concerned matter. "
                    },
                    {
                        lst: <div>
                            <Title level={5} className='brand-color m-0'>Booking times</Title>
                            <ul> 
                                <li>
                                    For all vehicles (except Rolls Royce Phantom) – booking must 60 minutes before the pickup time.
                                </li>
                                <li>
                                    For Rolls Royce Phantom – booking time must be at least 24 hours.
                                </li>
                            </ul>
                        </div>
                    },
                    {
                        lst: <div>
                            <Title level={5} className='brand-color m-0'>Booking areas</Title>
                            <ul> 
                                <li>
                                    For booking in London – booking must be at least 60 minutes before the pickup time.
                                </li>
                                <li>
                                    For bookings outside London but within the UK – it must be at least 6 hours before the pickup time.
                                </li>
                                <li>
                                    For booking worldwide – booking must be at least 24 hours before the pickup time.
                                </li>
                                <li>
                                    Please note that we need full address including post code for the accurate calculation of fares.
                                </li>
                            </ul>
                        </div>
                    },
                    {
                        lst: <div>
                            <Title level={5} className='brand-color m-0'>English Channel crossing</Title>
                            <ul> 
                                <li>
                                    The English channel crossing costs will be paid by customers for rides to Europe. Similarly Dock permissions are to be arranged by customers also.
                                </li>
                            </ul>
                        </div>
                    },
                ]
            },

            {
                title:"Transport safety",
                list:[
                    {
                        lst: <div>
                            <Title level={5} className='brand-color m-0'>Children</Title>
                            <ul> 
                                <li>
                                    When booking for children, child seats must be requested in the comments section, including the age of children and the type of seat needed.
                                </li>
                            </ul>
                        </div>
                    },
                    {
                        lst: <div>
                            <Title level={5} className='brand-color m-0'>Conveyance of Animals</Title>
                            <ul> 
                                <li>
                                    If the Customer wants to bring animals on board, they must take prior permission before booking to make sure with the Epic Ride London and partners if they are willing to accept such a case. Guide dogs will not be refused. Epic Ride London’s chauffeurs have the right to refuse to take animals (with the exception of guide dogs) that were not agreed upon.
                                </li>
                                <li>
                                    For trips to and from Europe, The cost for your pet to travel with Eurotunnel – DFDS channel crossing is to be paid by the customer/pet owner. This term applies to each cat, dog, or ferret in transit.
                                </li>
                            </ul>
                        </div>
                    },
                    {
                        lst: <div>
                            <Title level={5} className='brand-color m-0'>Policy on passengers and luggage</Title>
                            <ul> 
                                <li>
                                    The luggage capacity is shown on all of our Vehicle illustrations, as well as the seating capacity. While making a booking on the Epic Ride London website, any Extra luggage will be charged additionally subject to availability of the space. Epic Ride London has the right to refuse any luggage which was not agreed upon or may not fit in the vehicle boot or safety conditions are compromised. 
                                </li>
                                <li>
                                    In the same way, the seating capacity of the vehicle is also shown; any passenger not agreed upon during the booking may be refused if space becomes minimal and safety conditions are compromised. 
                                </li>
                            </ul>
                        </div>
                    },

                    {
                        lst: <div>
                            <Title level={5} className='brand-color m-0'>Delays</Title>
                            <ul> 
                                <li>
                                    In extreme weather conditions and certain unavoidable situations, traffic delays that the chauffeur has no control over or any such situation where the delay is inevitable, Epic Ride London Ltd bears no responsibility.
                                </li>
                                <li>
                                    In the event of an unexpected Traffic delay, Road blockage, GPS Navigation error or such incidents that might cause financial loss like missing important meetings, hotel bookings, missing a flight, or connecting flights for the customer; Epic Ride London does not have control over these factors so we do not take any responsibility or pay compensation. 
                                </li>
                            </ul>
                        </div>
                    },
                    {
                        lst: <div>
                            <Title level={5} className='brand-color m-0'>Penalties caused due to customers</Title>
                            <ul> 
                                <li>
                                    Any Penalties like parking tickets as a result of customers fault or not taking prior approval will be charged to customers like building management special parkings etc. 
                                </li>
                            </ul>
                        </div>
                    },
                    {
                        lst: <div>
                            <Title level={5} className='brand-color m-0'>Dashboard camera Awareness</Title>
                            <ul> 
                                <li>
                                     Epic Ride London Fleet comes with Dashcam in the cars for security and safety of the passengers. Dashcams record the footage video only. The microphone is not enabled, considering the privacy of the passengers. If any customer wishes the footage not to be recorded, please advise us before the journey, and we can disable it during the trip. 
                                </li>
                            </ul>
                        </div>
                    },
                    {
                        lst: <div>
                            <Title level={5} className='brand-color m-0'>Vouchers</Title>
                            <ul> 
                                <li>
                                    Discount Vouchers on website are only redeemable individually and cannot be combined with other vouchers 
                                </li>
                            </ul>
                        </div>
                    },
                    {
                        lst: <div>
                            <Title level={5} className='brand-color m-0'>Third Party website bookings</Title>
                            <ul> 
                                <li>
                                    Epic Ride London does not take not take responsibility to third-party ground transportation service provider websites . We only entertain direct bookings from Epic Ride London website.
                                </li>
                            </ul>
                        </div>
                    },
                    {
                        lst: <div>
                            <Title level={5} className='brand-color m-0'>Change of Vehicle</Title>
                            <ul> 
                                <li>
                                    If Vehicle is not available due to sudden mechanical failure or other reason we will send another substitute vehicle of similar class of the vehicle.
                                </li>
                            </ul>
                        </div>
                    },
                ]
            },
            {
                title:"Passenger no-show policy:",
                list:[
                    {
                        lst:"No sho means the passenger is not contactable through call / SMS / whatsapp / email"
                    },
                    {
                        lst:"“No show” means a cancellation to the Customer’s service for the booked periods when the Customer does not show up at the pickup location. This rule may be ignored if a later pickup time is agreed by the Customer and notified to Epic Ride London."
                    },
                    {
                        lst:"For airport pick ups 90 minutes"
                    },
                    {
                        lst:"For none airport pick ups 30 minutes"
                    },
                    {
                        lst:"The chauffeur will be released with a full charge of the booking cost if the passenger’s no-show."
                    },
                    {
                        lst: <div>
                            <Title level={4} className='brand-color m-0'>No show cancellations in case of:</Title>
                            <ul> 
                                <li>
                                    <Title level={5} className='brand-color m-0'>(a) Transfer Services</Title>
                                    <ul>
                                        <li>
                                            A No Show is only considered if the Customer is not present without cancellation 30 minutes past booked pickup time at the pickup location. No shows such as this will mean a full charge of the Customer’s booked service must be paid for. No extra waiting time costs will apply where possible.
                                        </li>
                                        <li>
                                             Airport and train station pickups are considered no shows when the Customer has not canceled 60 minutes prior to the booked pickup time. Customers booked service must be paid for in full in this situation; however, no extra waiting time costs will apply where possible.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Title level={5} className='brand-color m-0'>(b) Hourly Services less than 1 hour</Title>
                                    <ul>
                                        <li>
                                            A no show is considered if the Customer does not show up at all during the booked hours at the agreed pickup time and location. The service for the booking must be paid in full in this situation. The Chauffeur will wait 1 hour irrespective of the number of hours booked and then leave, considering it a no show. 
                                        </li>
                                        <li>
                                             Airport and train station pickups are considered no shows when the Customer has not shown up for the booked hours after the agreed pickup time and location. The Customer’s booked service must be paid for in full, no extra waiting time costs will apply where possible.
                                        </li>
                                    </ul>
                                </li>
                                
                            </ul>
                        </div>
                    },
                    {
                        lst: <div>
                            <Title level={4} className='brand-color m-0'>Cancellations</Title>
                            <ul> 
                                <li>
                                    <Title level={5} className='brand-color m-0'>(a) Transfer Services</Title>
                                    <ul>
                                        <li>
                                            Cancellations are free of charge as long as the Epic Ride London Chauffeur has not yet departed to pick up the Customer or more than an hour is left before the agreed pickup time. Any other no shows will be due payment in full for the arrangement fee.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Title level={5} className='brand-color m-0'>Cases when cancelled by client:</Title>
                                    <ul>
                                        <li>
                                            If Epic Ride London Chauffeur has not begun the journey to the pickup location – No charge 
                                        </li>
                                        <li>
                                             Cancellations can be made free of charge before the driver presses the 60-minute button.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Title level={5} className='brand-color m-0'>(b) Hourly Services</Title>
                                    <ul>
                                        <li>
                                            Cancellations are free of charge as long as they are made 60 minutes prior to the agreed pickup time, or our chauffeur has not started the on route journey to pick you up.
                                        </li>
                                        <li>
                                            Some destinations are outside London, and away from our site (near Heathrow Airport). In such cases the cancellations must be before the chauffeur starts the on route journey. For example, if a pick up is from destination B and we are located at A and need 3 hours to reach destination B, the trip must be canceled more than 3 hours so that the chauffeur does not start the on route option on our System.
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <Title level={5} className='brand-color m-0'>Cases when cancelled by client:</Title>
                                    <ul>
                                        <li>
                                            Business Class, Business Van and 1st Class should be cancelled 1 hour before the pickup time or before the chauffeur presses the On-Route option for the journey.
                                        </li>
                                        <li>
                                            Luxury class, SUV, and Elite Class must be cancelled 48 hours prior to pick up time; otherwise will be charged.
                                        </li>
                                        <li>
                                            If a driver cancels a ride, no cancellation notification will appear for the Customer as the Customer will only see details of the new driver who has accepted the job. (Any other cases, the Customer will be contacted by Epic Ride London’s customer service department)
                                        </li>
                                        <li>
                                            Cancellations can be made via phone, using Epic Ride London website, or through the Customer booking app.
                                        </li>
                                    </ul>
                                </li>
                                
                            </ul>
                        </div>
                    },
                    {
                        lst: <div>
                            <Title level={4} className='brand-color m-0'>Changes to booking</Title>
                            <ul> 
                                <li>
                                    Changes can be made during a ride for a change of destination. This will be sent over for review, and Epic Ride London will charge the extra kilometers accordingly.
                                </li>
                            </ul>
                        </div>
                    },
                    {
                        lst: <div>
                            <Title level={4} className='brand-color m-0'>Behaviour in the vehicle</Title>
                            <ul> 
                                <li>
                                    All passengers must abide by the following standard rules and laws when users are travelling with Epic Ride London Fleet:
                                </li>
                                <li>
                                    Epic Ride London always ensures a safe ride for all passengers (including young children and pets)
                                    <ul>
                                        <li>
                                            Doors must remain closed while the vehicle is moving
                                        </li>
                                        <li>
                                            Users must not throw objects from the vehicle
                                        </li>
                                        <li>
                                            Users must not stick body parts out of the vehicle
                                        </li>
                                        <li>
                                            Users must not shout from the vehicle
                                        </li>
                                        <li>
                                            Users must not smoke within the vehicle
                                        </li>
                                        <li>
                                            Devices provided by the Epic Ride London may be used by users after short instructions from Epic Ride London Chauffeur
                                        </li>
                                        <li>
                                            If the distance or number of hours are less than originally booked, once on passenger is on board and directs this change, the price remains unaffected
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Any physical or verbal abuse, threat, sexual and other harassment by or towards
                                </li>
                                <li>
                                    Epic Ride London staff will not be tolerated, and severe action will be taken. Any passenger who is thought to be under the influence of alcohol or drugs and whose behaviour poses a threat to the driver, the vehicle or other passengers may be refused travel.
                                </li>
                            </ul>
                        </div>
                    },
                    {
                        lst: <div>
                            <Title level={4} className='brand-color m-0'>Lost Property</Title>
                            <ul> 
                                <li>
                                     Any items lost within the vehicles will be returned to the Lost and Found department, which can be retrieved by either calling your driver or the office number. The cost incurred to deliver the items will be paid by the Customer like transportation cost, Courier, etc. However, we do not charge for the storage facility of the items. 
                                </li>
                                <li>
                                    No responsibility for any loss or damage to any luggage or property carried in or on the car unless the loss or damage is a result of negligence by Epic Ride London Chauffeur.
                                </li>
                                <li>
                                    Customer’s properties are carried entirely at their own risk and ICL shall not be held responsible/liable for any loss/damage to such property.
                                </li>
                            </ul>
                        </div>
                    },
                    {
                        lst: <div>
                            <Title level={4} className='brand-color m-0'>Complaints</Title>
                            <ul> 
                                <li>
                                    In the event of a complaint about the company’s services, the hirer should endeavour to seek a solution at the time by seeking assistance from the driver or from the company. If this has not provided a remedy, complaints should be submitted in writing and within 14 days of the termination date of the hire. The Company will acknowledge all complaints within 14 days and will normally reply fully within 28 days
                                </li>
                            </ul>
                        </div>
                    },
                ]
            },
            {
                title:"Payments and Premiums:",
                list:[
                    {
                        lst: <div>
                            <Title level={4} className='brand-color m-0'>(a) Waiting times for Transfer Services</Title>
                            <ul> 
                                <li>
                                    Only for transfer services, the first 60 minutes of waiting time is free for airports after the agreed pick up time, and for any other pickup, customers receive 15 minutes of waiting time for free. Any extra waiting times costs, after this free waiting time, is up, the cost will be charged for every minute at a flat rate, which will be calculated by the hourly booking price of the area of agreed pick up location and also includes the vehicle category rate (including VAT).
                                </li>
                            </ul>
                        </div>
                    },
                    {
                        lst: <div>
                            <Title level={4} className='brand-color m-0'>(b) Additional Kilometres for Hourly Booking</Title>
                            <ul> 
                                <li>
                                     Epic Ride London and partners have a fixed kilometer-per-hour rate, and any extra kilometers outside of the hourly rate will be added as extras and calculated by the hourly booking price of the area of agreed pick up location and also includes the vehicle category rate (including VAT).
                                </li>
                            </ul>
                        </div>
                    },
                    {
                        lst: <div>
                            <Title level={4} className='brand-color m-0'>(c) Terms of Payment and Transaction Fees</Title>
                            <ul> 
                                <li>
                                    The Customer can pay using all major credit cards. Payment will be taken instantly as the booking is complete and any extras added along the user’s journey will be debited 24-48 hours after the trip is over.
                                </li>
                            </ul>
                        </div>
                    },
                    {
                        lst: <div>
                            <Title level={4} className='brand-color m-0'>(d) Invoice</Title>
                            <ul> 
                                <li>
                                    Invoices are sent to the user as soon as their journey is finished (only if no extras are added), including a review form that they can send back to us in email form or through our social media pages. Journeys, where the Customer has added extras, the invoice, and review form, will be sent within 24-48 hours after journey completion.
                                </li>
                            </ul>
                        </div>
                    },
                    {
                        lst: <div>
                            <Title level={4} className='brand-color m-0'>(e) Payments by Credit Card</Title>
                            <ul> 
                                <li>
                                    Charges incurred due to different currencies or different local accounts are carried by the User customers are liable to show credit card with ID if Epic Ride London suspects anything suspicious.
                                </li>
                            </ul>
                        </div>
                    },
                    {
                        lst: <div>
                            <Title level={4} className='brand-color m-0'>(f) Extra Charges</Title>
                            <ul> 
                                <li className='brand-color'>
                                    Airport pick up policy:
                                </li>
                                <li>
                                    The arrival pick up 60 minutes of free waiting time
                                </li>
                                <li className='brand-color'>
                                    None airport pick up pick up policy:
                                </li>
                                <li>
                                    15 minutes is the free waiting time
                                </li>
                                <li className='brand-color'>
                                    Extra pick up and drop off charge policy:
                                </li>
                                <li>
                                    Any route deviation from the original or any stop in between is chargeable
                                </li>
                                <li className='brand-color'>
                                    Hourly booking extra charge policy:
                                </li>
                                <li>
                                    If you would like to extend an hourly booking, we need to know in advance because accepting an extending hourly booking is subject to the driver’s decision. Any extra charge will be charged as per the original booking details and as per our terms and condition
                                </li>
                            </ul>
                        </div>
                    },
                ]
            },
            {
                title:"Damages to cars by customers",
                list:[
                    {
                        lst:"In the event that the interior or exterior of the vehicle is soiled or damaged by the customer, a valeting or repair charge will apply to the credit or debit card provided at the time of booking. Refusing to pay for the damage caused, legal action will be taken."
                    },
                    {
                        lst: <div>
                            <Title level={4} className='brand-color m-0'>Any accidental injuries</Title>
                            <ul> 
                                <li>
                                    Epic Ride London is not responsible for any injuries or damages that may occur due to horse playing while the vehicle is in motion or at a standstill
                                </li>
                            </ul>
                        </div>
                    },
                    {
                        lst: <div>
                            <Title level={4} className='brand-color m-0'>COVID 19 Regulations</Title>
                            <ul> 
                                <li>
                                    Epic Ride London follows the TFL and Government regulations to ensure the safety of passengers and all our vehicles are disinfected before and after every trip and all contact locations are cleaned and sanitized. Passengers travelling in Epic Ride London fleet are supposed to ensure they follow the local government regulations for their safety and health. Face mask is a must. Our Chauffeurs are aware of the social distance and will not contact the customers directly.
                                </li>
                            </ul>
                        </div>
                    },
                ]
            },
        ]
    }
  return (
    <div>
        <BreadCrumb title='Terms & Conditions' pageName='terms & conditions' breadcrumb={[{to:'/',name:'Home'}]}/>
        <div className='justify-center'>      
            <div className='width-93 py-5'>
                <div className='pb-5'>
                    <Title level={3} className='brand-color'>
                        {
                            data?.title
                        }
                    </Title>
                    <Text strong>
                        {
                            data?.desc
                        }
                    </Text>
                </div>
                <div className='pb-5'>
                    <Title level={3} className='brand-color'>
                        Following the Divisional Court's
                    </Title>
                    <Text strong>
                        Judgement given on 6 December 2021, in accordance to fully operate lawfully, under the Private Hire Vehicles (London) Act 1998 (the 1998 Act) We Epic Ride London (drivers& operators) agree to comply with the below statement which forms part of the Regulations 9(14) of the Private Hire Vehicles: "The operator shall enter into a contractual obligation as principal with the person making the private hire booking to provide the journey which is the subject of the booking and any such contractual obligation must be consistent with the 1998 Act and these Regulations."
                    </Text>
                </div>
                <div>
                    
                    {
                        data?.content?.map((datc,dc)=>                        
                        <div className={dc<data?.content?.length-2?'pb-3':''} key={dc}>
                            <Space direction='vertical'>
                                <Title level={3} className='brand-color'>
                                    {
                                        datc?.title
                                    }
                                </Title>
                                <ul>
                                    {
                                        datc?.list.map((datlst,dl)=>
                                        <li key={dl}>
                                            <Text strong>
                                                {
                                                    datlst?.lst
                                                }
                                            </Text>
                                        </li>
                                        )
                                    }
                                </ul>
                            </Space>
                        </div>
                        )
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default TermsCondition