import React from 'react'
import { Row, Col, Image, Space, Typography } from 'antd'
import { BreadCrumb } from '../../ui-elements'
import {LuxrideLastestnews} from '../../components'
import { CarsGroup, BookingInstructions} from '../../components'
import BusinessPartner from '../../components/businessPartner'
const {Title}= Typography

const LuxrideServicePage = () => {

  const data2 = [
        {
            title:"Safety First",
            desc:"Experienced staff and professionally trained chauffeurs.",
        },
        {
            title:"Resonable Rates",
            desc:"We can offer you the right vehicle at the right price to fit your budget.",
        },
        {
            title:"Largest Fleet",
            desc:"We offer an extensive fleet of vehicles including sedans, limousines and coach buses.",
        }
  ]
  const data = [
    "Professionally Trained Chauffeurs",
    "24/7 Full-Service Transportation",
    "Global network of Transportation Partners"
    ]
  return (
    <div className='dflex'>
        <BreadCrumb title='Epic Services' description='We Can Help You Succeed' pageName='Epic Services' breadcrumb={[{to:'/',name:'Home'}]}/>
        <div className='flex-col-center py-5'>
          <div className='width-93'>
            <div className='pb-5'>
                <Row gutter={[32,24]}>
                    <Col xs={24} sm={24} md={24} lg={10} xl={8} className='center'>
                        <Image 
                            src='/luxride/8.jpg'
                            alt='epic-ride-london'
                            preview={false} 
                            />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={14} xl={16} className='align-center'>
                        <Space size={20} direction='vertical' style={{width:"100%"}}>
                            <Title level={3}>
                                When organizing a special event you and your family or friends might want to have a good time and not worry about driving.
                            </Title>
                            <p>
                                We have developed an extensive global network of transportation partners enabling them to provide first rate ground transportation for groups of any sizge all over the world. When organizing a special event you and your family or friends might want to have a good time and not worry about driving.
                            </p>
                            <Row gutter={[16,16]}>
                              {
                                data?.map((data,dt)=>
                                    <Col sm={12} xs={24} key={dt}>
                                        <Space size={20}>
                                            <Image 
                                                src={'/luxride/tick.png'} 
                                                alt='epic-ride-london'
                                                preview={false}
                                                />
                                            <Title level={5} style={{margin:"0px"}}>
                                                {
                                                    data
                                                }
                                            </Title>
                                        </Space>
                                    </Col>
                                )
                              }
                            </Row>
                        </Space>
                    </Col>
                </Row>
            </div>
            <div>
                <Row gutter={[32,24]}>
                    <Col  xs={24} sm={24} md={24} lg={14} xl={16} className='align-center'>
                        <Space size={20} direction='vertical' style={{width:"100%"}}>
                            <Title level={3}>
                                Our experienced chauffeurs will guide you through what every city you are in.
                            </Title>
                            <p>
                                We have developed an extensive global network of transportation partners enabling them to provide first rate ground transportation for groups of any sizge all over the world. When organizing a special event you and your family or friends might want to have a good time and not worry about driving.
                            </p>
                            <Row gutter={[16,16]}>
                              {
                                data2?.map((data,dt)=>
                                    <Col sm={12} xs={24} key={dt}>
                                        <Space size={20}>
                                            <Image 
                                                src={'/luxride/tick.png'} 
                                                alt='epic-ride-london'
                                                preview={false}
                                                />
                                            <Title level={5} style={{margin:"0px"}}>
                                                {
                                                    data?.title
                                                }
                                            </Title>
                                        </Space>
                                    </Col>
                                )
                              }
                            </Row>
                        </Space>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={10} xl={8} className='center'>
                        <Image 
                            src='/luxride/11.jpg' 
                            alt='epic-ride-london'
                            preview={false}
                            />
                    </Col>
                </Row>
            </div>
          </div>
        </div>
        <BookingInstructions/>
        <CarsGroup/>
        <LuxrideLastestnews/>
        <BusinessPartner/>
    </div>
  )
}

export default LuxrideServicePage