import React,{ useState } from 'react'
import {Typography, Collapse } from 'antd';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { SectionTop } from '../../ui-elements/sectionTop';
const { Panel } = Collapse;
const {Title, Text} = Typography;

const LuxrideFaqs = () => {
        const [currentPanel,setCurrentPanel]=useState(['1'])
        const faqData = 
            {   
                title: 'Frequently Asked Questions',
                faqs:[
                    {
                        
                        name:'How do I create an account?',
                        description:`Use a valid mobile number and e-mail address in order to create an account. Then sign up from your mobile number. A verification code will be send to you via SMS. Once your mobile number is verified, you are ready to use the app. In case you forgot your password then we send detail instructions via email so that you can reset your password.`
                    },
                    {
                        
                        name:'How do I earn reward points?',
                        description:`Reward points are earned on the basis of number of rides and cost of ride. The customer pays either online payment or cash, in both cases customer gets reward points. Free rides will not earn you points.`
                    },
                    {
                        
                        name:'How can I add my credit card on the app for payment?',
                        description:`Just go to the payment section, go to “Credit Card Payment” and fill in the required credentials. We send an OTP for verification.`
                    },
                    {
                        
                        name:'How do I become a Chauffeur?',
                        description:`In order to become a Chauffeur you need to register and fill in the form available on our website. Your application will be reviewed. After verification you will be facilitated with a training session. After the completion of training you will be ready to start working with us.`
                    },
                    {
                        
                        name:'Where can I get more information, support or make a claim?',
                        description:`If you need further information, support or claim related to London ride share 2023, then contact us on our email or contact number.`
                    },
                ]
            }
        
  return (
    <div className='py-5 flex-col-center'>
        <SectionTop 
            description='Frequently Asked Questions' 
            title='Your Questions'
            />
        <div style={{width:'93%'}}>
            <Collapse
                defaultActiveKey={['1']}
                onChange={(keys)=>{setCurrentPanel(keys)}}
            >
                {
                    faqData?.faqs?.map((faq,f)=>
                        <Panel 
                            showArrow={false}  
                            header={
                                <Title className='my-0 brand-color' level={5}>{faq?.name}</Title>
                            } 
                            key={f} 
                            extra={
                                ((currentPanel?.findIndex(x=>x==f))>-1)?
                                <MinusCircleOutlined className='font-20 brand-color'/>:
                                <PlusCircleOutlined className='font-20 brand-color'/>
                            }
                            // data-aos={f%2===0 ? "fade-right": "fade-left"}
                            // data-aos-duration="500" 
                            // data-aos-once="true"
                            // data-aos-delay="300"
                            >
                            <Text strong>
                                {
                                    faq?.description
                                }
                            </Text>
                        </Panel>
                    )
                }
            </Collapse>
        </div>
    </div>
  )
}

export default LuxrideFaqs