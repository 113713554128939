import { SectionTop} from '../../ui-elements';
import Slider from "react-slick";
import { Typography, Image, Button } from 'antd'
import "./index.css"
import { NavLink } from 'react-router-dom';
const {Title} = Typography

export const LuxrideLastestnews=()=>
{
    const news=[
        {
            title:'Airport Transfers',
            description:"Our chauffuers are there always to transfer you to all london airports",
            image:'airport-transfers.jpg',
            type:'Epic Ride',
            url:'/services/airport-transfer-services',
            alt:'epic ride london airport transfer services'
        },
        {
            title:'Event Transfers',
            description: 'We cover event transportation also in bulk with our luxury fleet',
            image:'Event-Transfer-Service.png',
            type:'Epic Ride',
            url:'/services/event-transfer-services',
            alt:'epic ride london event transfer services'
        },
        {
            title:"Executive Transfer Service",
            description:'Epic Ride London provides executive and high class chauffeuring',
            image:'Executive Transfer Services.jpg',
            type:'Epic Ride',
            url:'/services/executive-transfer-services',
            alt:'epic ride london executive transfer services'
        },
        {
            title:"Long Distance Chauffeur",
            description:'Our chauffuers are there always to transfer you to all UK in comfortable cars',
            image:'Long-Distance Chauffeur Services.jpg',
            type:'Epic Ride',
            url:'/long-distance-chauffeur-services',
            alt:'epic ride london long distance chauffeur services'
        }
    ]
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 786,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 0
            }
          }
        ]
      }
    return(
          <>
            <div className="py-5 light-bg" style={{overflowX:'hidden'}}>
                <div className='flex-col-center'>
                    <SectionTop description='EPIC RIDE LONDON' title='ALL SERVICES'/>
                    <div style={{width:'93%'}}>
                    <Slider {...settings}>
                        {
                            news?.map((news,n)=>{
                                return(
                                    <div 
                                        className='px-2' 
                                        key={'news-'+n}
                                        data-aos-delay={300*(n+1)}
                                        data-aos="zoom-in"
                                        data-aos-duration={500}
                                        data-aos-once={true}
                                        >
                                        <div className=" rounded-1 news-slider bg-white border">
                                                <div className='news-slider-image-box brand-bg center'>
                                                    <Image 
                                                        src={'/luxride/'+news?.image} 
                                                        preview={false} 
                                                        alt={news?.alt}
                                                        className='img-fluid one animation' 
                                                        style={{maxWidth:'100%',maxHeight:'100%'}}
                                                        />
                                                    <div className='two animation'>
                                                        <Image 
                                                            src={'/luxride/plus.png'}
                                                            alt='epic-ride-london'
                                                            preview={false}
                                                            />
                                                    </div>
                                                    <span className='tag px-3 py-1 brand-bg-blue'>
                                                        <Typography.Text strong className='text-white'>
                                                            {
                                                                news?.type
                                                            }
                                                        </Typography.Text>
                                                    </span>
                                                </div>
                                                <div className='p-3'>
                                                    <Title className='news-heading mb-2' level={3}>
                                                        {
                                                            news?.title
                                                        }
                                                    </Title>
                                                    <p style={{width:'100%'}}>
                                                        {
                                                            news?.description
                                                        }
                                                    </p>
                                                    <NavLink to={news?.url}>
                                                        <Button type='primary'>
                                                            Read More
                                                        </Button>
                                                    </NavLink>
                                                </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                    </div>
                </div>
            </div>
        </>
        )
    }
