import React,{ useEffect } from 'react'
import Routing from './routes'
import "./App.less"
import AOS from 'aos';
import 'aos/dist/aos.css';
import "antd/dist/antd.less"
import { store } from "./shared";
import { Provider } from "react-redux";
const App = () => { 
  useEffect(() => {
    AOS.init();
  }, [])   
    return (
        <Provider store={store}>
          <Routing />
        </Provider>
      )
}

export default App
