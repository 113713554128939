import {Row, Col, Typography, Space, Image} from "antd"
import './index.css'
const {Title}=Typography
const AboutEpic=()=>{
    return (
            <div className="justify-center intro py-5">
                <Row gutter={[32,32]} style={{width:'93%'}}>
                    <Col 
                        xl={{span:12}} lg={{span:24}} md={{span:24}} sm={{span:24}} xs={{span:24}} 
                        className='align-center'
                        >
                        <Space direction='vertical' size={25}>
                            <div>
                                <Title 
                                    level={3} 
                                    className='brand-color'
                                    data-aos="fade-down"
                                    data-aos-duration="500" 
                                    data-aos-once="true"
                                    data-aos-delay="300"
                                    >
                                    Stay protected
                                </Title>
                                <Title 
                                    level={1} 
                                    className='my-0'
                                    data-aos="fade-down"
                                    data-aos-duration="500" 
                                    data-aos-once="true"
                                    data-aos-delay="300"
                                    >
                                    Feel safe with our chauffeurs
                                </Title>
                            </div>
                            <p 
                                className="my-0"
                                data-aos="fade-up"
                                data-aos-duration="500" 
                                data-aos-once="true"
                                data-aos-delay="300"
                                >
                                Our chauffeurs are handpicked for their professionalism, knowledge, and commitment to excellence. They possess in-depth knowledge of London's roads and traffic patterns, ensuring efficient and timely transportation. Our chauffeurs go the extra mile to ensure that your experience is personalized, ensuring your comfort and catering to your specific needs.
                            </p>
                            <Space 
                                direction="vertical"
                                >
                                <Space 
                                    align="top"
                                    data-aos="fade-right"
                                    data-aos-duration="500" 
                                    data-aos-once="true"
                                    data-aos-delay="300"
                                    >
                                    <Image src="/assets/icons/rec.png" alt="epic ride london" preview={false} width={14}/>
                                    <p>
                                        Stringent driver background checks ensure trustworthy and reliable chauffeurs.
                                    </p>
                                </Space>
                                <Space 
                                    align="top"
                                    data-aos="fade-right"
                                    data-aos-duration="500" 
                                    data-aos-once="true"
                                    data-aos-delay="500"
                                    >
                                    <Image src="/assets/icons/rec.png" alt="epic ride london" preview={false} width={14}/>
                                    <p>
                                        Compliance with local regulations and comprehensive insurance coverage provide peace of mind for clients.
                                    </p>
                                </Space>
                                <Space 
                                    align="top"
                                    data-aos="fade-right"
                                    data-aos-duration="500" 
                                    data-aos-once="true"
                                    data-aos-delay="700"
                                    >
                                    <Image src="/assets/icons/rec.png" alt="epic ride london" preview={false} width={14}/>
                                    <p>
                                        Regular vehicle maintenance and safety inspections ensure optimal performance and passenger safety.
                                    </p>
                                </Space>
                            </Space>
                        </Space>
                    </Col>
                    <Col 
                        xl={{span:12}} lg={{span:24}} md={{span:24}} sm={{span:24}} xs={{span:24}}
                        className="center"
                        >
                        <div className="about-left align-center">
                            <div className="brand-bg">
                            </div>
                            <Image 
                                src='/luxride/9.jpg' 
                                alt='epic ride london chauffeur on executive transfer service' 
                                className="img" 
                                width={'97%'}
                                preview={false}
                                data-aos="fade-left"
                                data-aos-duration="500" 
                                data-aos-once="true"
                                data-aos-delay="300"
                                />
                        </div>
                    </Col>
                </Row>
            </div>
    )
}
export default AboutEpic