import React from 'react'
import {Space, Typography } from 'antd'

const { Title } = Typography;

export const ServiceComponent2 = () => {
  return (
    <div className='justify-center py-5'>
        <div className='width-93'>
            <Space direction='vertical' size={10}>
                <Title 
                    level={1} 
                    className='brand-color-blue'
                    data-aos="fade-right"
                    data-aos-duration="800" 
                    data-aos-once="true"
                    data-aos-delay="300"
                    >
                    LUXURY CHAUFFEUR DRIVE SERVICES THROUGHOUT LONDON
                </Title>
                <p
                    data-aos="fade-up-left"
                    data-aos-duration="800" 
                    data-aos-once="true"
                    data-aos-delay="300"
                    >
                    No matter what you need, Epic Chauffeur will deliver a quality service. All of our limo drivers are PCO Your trip will always be in luxury and comfort thanks to our fleet of Mercedes S Class, Viano, SUV, Range Rover, and Rolls Royce Phantom cars driven by licenced, courteous, and perfectly dressed experienced experts.
                </p>
                <p 
                    className='pb-3'
                    data-aos="fade-up-left"
                    data-aos-duration="800" 
                    data-aos-once="true"
                    data-aos-delay="300"
                    >
                    No matter what you need, Epic Chauffeur will deliver a quality service. All of our limo drivers are PCO Your trip will always be in luxury and comfort thanks to our fleet of Mercedes S Class, Viano, SUV, Range Rover, and Rolls Royce Phantom cars driven by licenced, courteous, and perfectly dressed experienced experts.
                </p>
            </Space>
        </div>
    </div>
  )
}