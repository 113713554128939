import React, { useState } from 'react'
import { Row, Col, Space, Input, Form, Typography, Button } from 'antd'
import { useSelector } from 'react-redux'
import {emailJourneyDetails} from "./api"
import { ChoosePaymentMethod } from './ChoosePaymentMethod'
const { TextArea } = Input
const { Title,Text} = Typography

const LuxrideLastForm = ({next}) => {

    const {epicRide}=useSelector(state=>state)
    const [form] = Form.useForm()
    const [loading, setLoading]= useState(false)
    const [paymentMethod, setPaymentMethod]= useState(1)
    const save= async()=>{
        setLoading(true)
        let data=form.getFieldsValue()
        let object={
            Title: "Epic Ride",
            Status: "Pending",
            ServiceType: epicRide?.serviceType===1?'Distance':'Hourly',
            TransferType:epicRide?.travelMode===1?'One Way':'Two Way',
            PickupDateTime: epicRide?.pickupDate+" "+ epicRide?.pickupTime,
            OrderTotalAmount: "£"+ (parseFloat(epicRide?.vehicle?.vehicleAmount)+ epicRide?.extrasAmount).toFixed(2),
            Taxes: "£0.00 (0%)",
            toPay: "(deposit 100%) £"+ (parseFloat(epicRide?.vehicle?.vehicleAmount)+ epicRide?.extrasAmount).toFixed(2),
            Distance: epicRide?.Distance || ' - ',
            Duration: epicRide?.Duration,
            routeLocations:[epicRide?.wayTo?.pickupLocation, ...epicRide?.wayTo?.wayPoints, epicRide?.wayTo?.dropOffLocation],
            returns:epicRide?.travelMode===1?null: [epicRide?.return?.pickupLocation, ...epicRide?.return?.wayPoints, epicRide?.return?.dropOffLocation],
            VehicleName: epicRide?.vehicle?.VehicleName,
            extras:[
                epicRide?.extras?.childSeat?.quantity+" Child Seat",
                epicRide?.extras?.bouquet?.quantity+" Bouquet of Flowers",
                epicRide?.extras?.champagne?.quantity+" Champagne",
            ],
            BagCount: epicRide?.vehicle?.BagCount,
            PassengersCount: epicRide?.vehicle?.PassengersCount,
            ...data
           }
        await emailJourneyDetails(object)
        setLoading(false)
        next()
    }
  return (
    <div className='py-3'>
        <Row>
            <Col span={24}>
                <Title level={3}>Contact Details</Title>
            </Col>
            <Col span={24}>
                <Form
                    layout="vertical"
                    onFinish={save} 
                    form={form}
                    initialValues={{
                        remember: true,
                    }}
                >
                    <Row gutter={24}>
                        <Col sm={12} xs={24}>
                            <Form.Item
                                name='FirstName'
                                label={<Text strong>First Name</Text>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your first name',
                                    },
                                ]}
                            >
                                <Input 
                                    value={form.getFieldValue('FirstName') || ''}
                                    size='large'
                                    />
                                
                            </Form.Item>
                        </Col>
                        <Col sm={12} xs={24}>
                            <Form.Item
                                name='LastName'
                                label={<Text strong>Last Name</Text>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your last name',
                                    },
                                ]}
                            >
                                <Input 
                                    value={form.getFieldValue('LastName') || ''}
                                    size='large'
                                    />
                                
                            </Form.Item>
                        </Col>
                        <Col sm={12} xs={24}>
                            <Form.Item
                                name='EmailAddress'
                                label={<Text strong>Email Address</Text>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your email address',
                                    },
                                ]}
                            >
                                <Input 
                                    type='email'
                                    value={form.getFieldValue('EmailAddress') || ''}
                                    size='large'
                                    />
                            </Form.Item>
                        </Col>
                        <Col sm={12} xs={24}>
                            <Form.Item
                                name='PhoneNumber'
                                label={<Text strong>Phone Number</Text>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your phone number',
                                    },
                                ]}
                            >
                                <Input 
                                    type='number'
                                    value={form.getFieldValue('PhoneNumber') || ''}
                                    size='large'
                                    />
                                
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name='Comment'
                                label={<Text strong>Comments</Text>}
                            >
                                <TextArea
                                    rows={3} 
                                    value={form.getFieldValue('Comment') || ''}
                                    size='large'
                                    />
                                
                            </Form.Item>
                        </Col>
                        <Col sm={12} xs={24}>
                            <Form.Item
                                name='FlightDetails'
                                label={<Text strong>Flight Details</Text>}
                            >
                                <Input 
                                    value={form.getFieldValue('FlightDetails') || ''}
                                    size='large'
                                    />
                                
                            </Form.Item>
                        </Col>
                        <Col sm={12} xs={24}>
                            <Form.Item
                                name='NameBoard'
                                label={<Text strong>Name Board</Text>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your name board',
                                    },
                                ]}
                            >
                                <Input 
                                    value={form.getFieldValue('NameBoard') || ''}
                                    size='large'
                                    />
                                
                            </Form.Item>
                        </Col>
                        <Col span={24} >
                            <Space direction='vertical' className='width-100 center' size={20}>
                                <ChoosePaymentMethod
                                    paymentMethod={paymentMethod}
                                    setPaymentMethod={(value)=>{setPaymentMethod(value)}}
                                    />
                                <Button 
                                    type='primary' 
                                    htmlType='submit'
                                    loading={loading}
                                    size='large'
                                    >
                                        Checkout
                                </Button>
                            </Space>
                        </Col>
                        {/* <Col xs={24} lg={24}>
                            <Form.Item
                            >
                                <Checkbox className='checkCss' checked={selectshow} onChange={(event)=>{setSelectShow(event.target.checked)}}>Billing Address</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            {
                                selectshow &&
                                <Row gutter={24}>
                                    <Col xs={24} lg={12}>
                                        <Form.Item
                                            label="Company Registered Name"
                                        >
                                            <Input size='large' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <Form.Item
                                            label="Tax Number"
                                        >
                                            <Input size='large' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <Form.Item
                                            label="Street"
                                        >
                                            <Input size='large' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <Form.Item
                                            label="Street Number"
                                        >
                                            <Input size='large' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <Form.Item
                                            label="City"
                                        >
                                            <Input size='large' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <Form.Item
                                            label="State"
                                        >
                                            <Input size='large' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <Form.Item
                                            label="Postal Code"
                                        >
                                            <Input size='large' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <Form.Item
                                            label="Select Country"
                                        >
                                            <Select
                                                size='large'
                                                >
                                                <Option value="male">United Kingdom</Option>
                                                <Option value="female">UK</Option>
                                                <Option value="other">Pakistan</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            }
                        </Col>
                        <Col xs={24} lg={24}>
                            <Space style={{marginBottom:"30px",width:"100%",justifyContent:"center"}} size={20}>
                                <Space className={payment===0?'borderpayActive active payCss':'payCss'} onClick={()=> setPayment(0)}>
                                    <Image src='/luxride/payment-1.jpg' style={{width:"200px"}} preview={false} />
                                </Space>
                                <Space className={payment===1?'borderpayActive active payCss':'payCss'} onClick={()=> setPayment(1)}>
                                    <Image src='/luxride/payment-2.jpg' preview={false} />
                                </Space>
                            </Space>
                        </Col> */}
                    </Row>
                </Form>
            </Col>
        </Row>
    </div>
  )
}

export default LuxrideLastForm