import React, { useState } from 'react'
import { Row, Col, Space, Typography, Image, Button, Input, Divider } from 'antd'
const { Title, Text } = Typography;

const LuxrideCarDetailsBar = () => {
    
    const [datacounter, setDataCounter] = useState(0)
    const CounterPlus = () =>{
        setDataCounter(datacounter + 1);
    }

    const CounterMinus = () =>{
        if(datacounter > 0){
            setDataCounter(datacounter - 1);
        }
        else{
            setDataCounter(0);
        }
    }

const data = 
    {   
        carCard:
        [
            {
                carimge:"car1.png",
                title:"MERCEDES-BENZ E-CLASS (BUSINESS CLASS)",
                amount:"£85.00",
                btntext:"Select",
                desc:"No Hidden, Waiting, Airport Parking, Congestion/tolls Charges - & We Offer Free 60 Minute Waiting At Airport And 15 Minutes At Any Other Address.",
                serviceExtra:[
                    {
                        icons:"glass.png",
                        icontext:"Free water",
                    },
                    {
                        icons:"hand-shake.png",
                        icontext:"Meet & Greet",
                    },
                    {
                        icons:"wifi.png",
                        icontext:"WiFi available",
                    },
                    {
                        icons:"mutiusers.png",
                        icontext:"3 Passengers",
                    },
                    {
                        icons:"bags.png",
                        icontext:"2 Bags",
                    },
                    {
                        icons:"history.png",
                        icontext:"60 Min Airport Waiting",
                    },
                    {
                        icons:"newspaper.png",
                        icontext:"Newspaper",
                    },
                ],  
            },
            {
                carimge:"car2.png",
                title:"MERCEDES-BENZ S-CLASS (LUXURY CLASS)",
                amount:"£120.00",
                btntext:"Select",
                desc:"No Hidden, Waiting, Airport Parking, Congestion/tolls Charges - & We Offer Free 60 Minute Waiting At Airport And 15 Minutes At Any Other Address.",
                serviceExtra:[
                    {
                        icons:"glass.png",
                        icontext:"Free water",
                    },
                    {
                        icons:"hand-shake.png",
                        icontext:"Meet & Greet",
                    },
                    {
                        icons:"wifi.png",
                        icontext:"WiFi available",
                    },
                    {
                        icons:"mutiusers.png",
                        icontext:"3 Passengers",
                    },
                    {
                        icons:"bags.png",
                        icontext:"2 Bags",
                    },
                    {
                        icons:"history.png",
                        icontext:"60 Min Airport Waiting",
                    },
                    {
                        icons:"newspaper.png",
                        icontext:"Newspaper",
                    },
                ],  
            },
            {
                carimge:"car3.png",
                title:"MERCEDES-BENZ V-CLASS (BUSINESS VAN)",
                amount:"£110.00",
                btntext:"Select",
                desc:"No Hidden, Waiting, Airport Parking, Congestion/tolls Charges - & We Offer Free 60 Minute Waiting At Airport And 15 Minutes At Any Other Address.",
                serviceExtra:[
                    {
                        icons:"glass.png",
                        icontext:"Free water",
                    },
                    {
                        icons:"hand-shake.png",
                        icontext:"Meet & Greet",
                    },
                    {
                        icons:"wifi.png",
                        icontext:"WiFi available",
                    },
                    {
                        icons:"mutiusers.png",
                        icontext:"7 Passengers",
                    },
                    {
                        icons:"bags.png",
                        icontext:"6 Bags",
                    },
                    {
                        icons:"history.png",
                        icontext:"60 Min Airport Waiting",
                    },
                    {
                        icons:"newspaper.png",
                        icontext:"Newspaper",
                    },
                ],  
            },
        ],
        carticon:"shopping-cart.png",
        carttext:"Extra options",
        lastCartBlock:[
            {
                desc1: <Space>
                    Child Seat <span className='goldColor' style={{fontWeight:"600"}}>£25.00</span> Baby car seat for children aged 0-36 months
                </Space>,
                labelcounter:"Numbers Cars",
                btntext1:"Select",
                plus:"plus.png",
                minus:"minus.png",
            },
            {
                desc1: <Space>
                    Bouquet of Flowers <span className='goldColor' style={{fontWeight:"600"}}>£85.00</span> A bouquet of seasonal flowers prepared by a local florist    
                </Space>,
                labelcounter:"Numbers Cars",
                btntext1:"Select",
                plus:"plus.png",
                minus:"minus.png",
            },
            {
                desc1: <Space>
                    Champagne <span className='goldColor' style={{fontWeight:"600"}}>£115.00</span> French Champagne Brut 0.75l Bottle
                </Space>,
                labelcounter:"Numbers Cars",
                btntext1:"Select",
                plus:"plus.png",
                minus:"minus.png",
            },
        ]
    }
  return (
    <div style={{padding:"10px",marginTop:"20px"}}>
        {
            data?.carCard?.map((cardcrd,ccd)=>
                <Row gutter={[24,24]} key={ccd} style={{marginBottom:"50px"}}>
                    <Col xs={24} sm={24} md={24} lg={8}>
                        <Image 
                            src={'/luxride/'+cardcrd?.carimge} 
                            alt='epic-ride-london'
                            preview={false}
                            />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={16}>
                        <Row gutter={[24,24]}>
                            <Col xs={24} sm={24} md={24} lg={18}>
                                <Space direction='vertical'>
                                    <Title level={3}>{cardcrd?.title}</Title>
                                    <Title level={1} className="goldColor">{cardcrd?.amount}</Title>
                                </Space>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={4}>
                                <Space>
                                    <Button className='selectBtn'>{cardcrd?.btntext}</Button>
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Text style={{textAlign:"center",display:"block"}}>
                                    {
                                        cardcrd?.desc
                                    }
                                </Text>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Space style={{width:"100%",flexWrap:"wrap"}} size={30}>
                            {
                                cardcrd?.serviceExtra?.map((serviceext,sxt)=>
                                <Space size={10} key={sxt}>
                                    <span>
                                        <Image 
                                            src={'/luxride/icons/'+serviceext?.icons} 
                                            alt='epic-ride-london'
                                            preview={false}
                                            />
                                    </span>
                                    <Text strong>{serviceext?.icontext}</Text>
                                </Space>
                                )
                            }
                        </Space>
                    </Col>
                </Row>
            )
        }

        <Divider />

        <Row gutter={[32,24]}>
            <Col span={24}>
                <Space size={20}>
                    <span className='cartIcon'>
                        <Image 
                            src={'/luxride/icons/'+data?.carticon} 
                            alt='epic-ride-london'
                            preview={false}
                            />
                    </span>
                    <Title level={3} style={{marginBottom:"0px"}}>{data?.carttext}</Title>
                </Space>
            </Col>
            <Col span={24}>
                {
                    data?.lastCartBlock?.map((lastcart,lc)=>
                        <Row key={lc} style={{border:"1px solid #C99B20",marginBottom:"10px"}}>
                            <Col md={16} lg={18}>
                                <Space  style=
                                        {{  
                                            height:"100%",display:"flex",alignItems:"center",paddingLeft:"10px",paddingRight:"10px"}}>
                                    <Text>
                                        {
                                            lastcart?.desc1
                                        }
                                    </Text>
                                </Space>
                            </Col>
                            <Col md={8} lg={6}>
                                <Space size={20} direction='vertical' style=
                                    {{
                                    borderLeft:"1px solid #C99B20",
                                        width:"100%",height:"100%",padding:"10px"}}>
                                    <Space size={10}  style={{width:"100%",justifyContent:"space-between"}}>
                                        <Space size={0} direction='vertical'>
                                            <Text>{lastcart?.labelcounter}</Text>
                                            <Input  value={datacounter} onChange={(e)=>e.target.value} style={{border:"none"}} />                                    
                                        </Space>
                                        <Space direction='vertical'>
                                            <Button onClick={CounterMinus}>
                                                <Image 
                                                    src={'/luxride/icons/'+lastcart?.plus}
                                                    alt='epic-ride-london'
                                                    preview={false}
                                                    />
                                            </Button>
                                            <Button onClick={CounterPlus}>
                                                <Image 
                                                    src={'/luxride/icons/'+lastcart?.minus}
                                                    alt='epic-ride-london'
                                                    preview={false}
                                                    />
                                            </Button>
                                        </Space>
                                    </Space>
                                    <Button className='selectBtn' style={{width:"100%"}}>{lastcart?.btntext1}</Button>
                                </Space>
                            </Col>
                        </Row>
                    
                    )
                }
            </Col>
        </Row>
    </div>
  )
}

export default LuxrideCarDetailsBar