import React, { useState } from 'react'
import { Typography, Image } from 'antd'
import './luxridesocial.css'
const { Text } = Typography;

const LuxrideSocial = () => {
    const [ isshow, setIsShow ] = useState(false)
    const [ chatbtn, setChatBtn ] = useState(true)
    const [ crossbtn, setCrossBtn ] = useState(false)

  return (
    <div >
        <div className='socialIcon'>
            <div className='' >
                <div className='hoveronImage'>
                    {
                        chatbtn &&
                        <Image 
                            src='/luxride/icons/chat.png' 
                            className='rotateimg' 
                            onClick={()=> {setIsShow(true);setChatBtn(false);setCrossBtn(true)}}
                            alt='epic-ride-london'
                            preview={false}
                            />
                    }
                    {
                        crossbtn &&
                        <Image 
                            src='/luxride/icons/crossbtn.png' 
                            className='rotateimg rotateimgcros' 
                            onClick={()=>{setChatBtn(true);setCrossBtn(false);setIsShow(false)}}
                            alt='epic-ride-london'
                            preview={false}
                            />
                    }
                </div>
                
                    <div className={isshow? 'showsocialIcon active':'showsocialIcon'}>
                        <div className='whatsapp'>
                            <a href='https://api.whatsapp.com/send?phone=447405747293' rel='help' target='blank'>
                                <Image 
                                    src='/luxride/icons/whatsapp.png' 
                                    alt='epic-ride-london'
                                    preview={false}
                                    />
                            </a>
                            <div className='textshowonhover '>
                                <Text className='text-white'>Whatsapp</Text>
                            </div>
                        </div>
                        <div className='messenger'>
                            <Image 
                                src='/luxride/icons/messenger.png' 
                                alt='epic-ride-london'
                                preview={false}
                                />
                            <div className='textshowonhover'>
                                <Text className='text-white'>Facebook Messenger</Text>
                            </div>
                        </div>
                    </div>
                    
                
            </div>
            
        </div>
    </div>
  )
}

export default LuxrideSocial;


