import React, { useState } from 'react'
import { Row, Col, Space, Input, Form, Checkbox, Typography, Select, Image, Button } from 'antd'
import { useSelector } from 'react-redux'
import { CitiesInput } from '../../ui-elements'
import { CheckoutCall } from '../../shared'
const { Title} = Typography
export const Checkout = () => {
    const {epicRide}=useSelector(state=>state)
    const [form] = Form.useForm()
    const [loading, setLoading]= useState(false)
    const save= async ()=>{
        setLoading(true)
        let data=form.getFieldsValue()
        // let object={
        //     Title: "Epic Ride",
        //     Status: "Pending",
        //     ServiceType: epicRide?.serviceType===1?'Distance':'Hourly',
        //     TransferType:epicRide?.traveMode===1?'One Way':'Two Way',
        //     PickupDateTime: epicRide?.pickupDate+" "+ epicRide?.pickupTime,
        //     OrderTotalAmount: "£"+ epicRide?.OrderTotalAmount,
        //     Taxes: "£0.00 (0%)",
        //     toPay: "(deposit 100%) £"+ epicRide?.toPay,
        //     Distance: epicRide?.Distance || ' - ',
        //     Duration: epicRide?.Duration,
        //     routeLocations:epicRide?.pickupLocations+ " - "+ epicRide?.dropOffLocation,
        //     VehicleName: epicRide?.VehicleName,
        //     BagCount: epicRide?.BagCount,
        //     PassengersCount: epicRide?.PassengersCount,
        //     ...data
        //    }
        const result= await CheckoutCall({...data, amount: (parseFloat(epicRide?.vehicle?.vehicleAmount) + epicRide?.extrasAmount).toFixed(2)})
        setLoading(false)
        window.location.href='/'
       // window.location.href='/'
    }
  return (
    <div>
        <Row>
            <Col span={24}>
                <Title level={3}>Checkout</Title>
            </Col>
            <Col xs={20} sm={20} md={24} lg={24}>
                <Form
                    layout="vertical"
                    onFinish={save} 
                    form={form}
                    initialValues={{
                        remember: true,
                    }}
                >
                    <Row gutter={[12,16]}>
                        <Col sm={12} xs={24}>
                            <CitiesInput
                                label='Email'
                                name='email'
                                placeholder= 'e.g mail@gmail.com'
                                value= {form.getFieldValue('email')}
                                required 
                                message="please enter email"
                                size= 'large'
                                />
                        </Col>
                        <Col sm={12} xs={24}>
                            <CitiesInput
                                label='Card Number'
                                name='card_number'
                                placeholder= '0000 0000 0000 0000'
                                value= {form.getFieldValue('card_number')}
                                required 
                                message="please enter card number"
                                size= 'large'
                                />
                        </Col>
                        <Col sm={8} xs={24}>
                            <CitiesInput
                                type='number'
                                label='Expiry Month'
                                name='exp_month'
                                placeholder= '12'
                                value= {form.getFieldValue('exp_month')}
                                required 
                                message="please enter expiry month"
                                size= 'large'
                                />
                        </Col>
                        <Col sm={8} xs={24}>
                            <CitiesInput
                                type='number'
                                label='Expiry Year'
                                name='exp_year'
                                placeholder= '24'
                                value= {form.getFieldValue('exp_year')}
                                required 
                                message="please enter expiry year"
                                size= 'large'
                                />
                        </Col>
                        <Col sm={8} xs={24}>
                            <CitiesInput
                                type='number'
                                label='CVC'
                                name='cvc'
                                placeholder= '123'
                                value= {form.getFieldValue('cvc')}
                                required 
                                message="please enter cvc"
                                size= 'large'
                                />
                        </Col>
                        <Col span={24}>
                            <CitiesInput
                                label='Name on card'
                                name='card_name'
                                placeholder= 'John Doe'
                                value= {form.getFieldValue('card_name')}
                                required 
                                message="please enter name"
                                size= 'large'
                                />
                        </Col>
                        <Col span={24} className='center'>
                            <Button
                                type='primary'
                                size='large'
                                htmlType='submit'
                                loading={loading}
                                >
                                PAY NOW
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    </div>
  )
}