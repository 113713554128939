import React from 'react'
import { Row, Col, Typography,Space, Button } from 'antd'
import './index.css'
import { TestimonialCards } from './TestimonialCards'
import { NavLink } from 'react-router-dom'
const { Title} = Typography

const EpicTestimonial = () => {
  return (
    <div className='justify-center cover-bg'>
        <div className='overlayimg'></div>
        <div className='width-93'>
            <Row gutter={[32,32]}>
                <Col span={24}>
                    <Space className='space-between-end w-100'>
                      <Space  direction='vertical'>
                          <Title level={4} className='my-0 brand-color'>TESTIMONIALS</Title>
                          <Title level={1} className='my-0 text-white'>
                              WHAT PEOPLE SAY!
                          </Title>
                          <Title level={5} className='text-white my-0'>
                              Don't miss to read our satisfied customers feedback.
                          </Title>
                      </Space>
                      <Space>
                        <NavLink to='/booking'>
                          <Button type='primary' className='text-uppercase'>Book now</Button>
                        </NavLink>
                        <NavLink to='/contact-us'>
                          <Button type='primary' className='text-uppercase btn-blue'>contact us</Button>
                        </NavLink>
                      </Space>
                    </Space>
                </Col>
                <Col span={24}>
                    <TestimonialCards />
                </Col>
            </Row>
        </div>
    </div>
  )
}

export {EpicTestimonial}