import React from 'react'
import LuxrideSteps from '../../components/luxridesteps/LuxrideSteps'
import { BreadCrumb } from '../../ui-elements'
import BookingSteps from './bookingSteps'
import { useSelector } from 'react-redux'
const Booking = () => {
  console.log(useSelector(state=>state?.epicRide))
  return (
    <div>
        <BreadCrumb 
          title='Book a Ride' 
          description='' 
          pageName='Book a Ride' 
          breadcrumb={[{to:'/',name:'Home'}]}
          />
        <div className='justify-center'>
          <div style={{width:'93%'}}>
            <BookingSteps/>
          </div>
        </div>
    </div>
  )
}

export default Booking