import { Button,InputNumber} from "antd"
import { MinusOutlined, PlusOutlined } from "@ant-design/icons"
import "./index.css"
import { useDispatch, useSelector } from "react-redux"
import { actionsApi } from "../../shared"

export const QuantityCounter=({name, quantity, available_stock, passAndBags})=>{
    const dispatch= useDispatch()
    const {epicRide}= useSelector(state => state)
    const upDown=(countUp)=>{
        if(passAndBags)
            dispatch(actionsApi?.updateEpicRideVehicle({
                [name]: countUp?(quantity+1):(quantity-1)
            }))
        else
            dispatch(actionsApi?.updateExtras({
                ...epicRide?.extras,
                [name]:{
                    ...epicRide?.extras[name],
                    quantity: countUp?(quantity+1):(quantity-1)
                }
            }))
    }
    return(
        <div className='quantity-counter'>
            <Button 
                size="large"
                className='prevrad'
                disabled={quantity<1}
                onClick={()=>{upDown(false)}}
                type="primary"
                >
                <MinusOutlined className='up'/>
            </Button>
            <InputNumber 
                controls={false} 
                style={{width:'70px', border:'none'}} 
                value={quantity} 
                size="large"
                onChange={(value)=>{
                    // if(value>=1 && value<=item?.product?.available_stock)
                    //     handleChange(value, index)
                    // else 
                    // handleChange(item?.product?.available_stock, index)
                }}
                />
            <Button 
                className='nextrad'
                size="large"
                disabled={quantity>=available_stock}
                onClick={()=>{upDown(true)}}
                type="primary"
                >
                <PlusOutlined className='up'/>
            </Button>
        </div>
    )
}