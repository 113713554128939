import {Row, Col, Typography, Space, Image} from "antd"
import { useState } from "react"
import './index.css'
const {Title}=Typography
const Choose=()=>{

    const [currentButton,setCurrentButton]= useState(1)
    const state= [
        {
            title:'Our mission',
            description:"Our mission is to provide best ridesharing services to ensure customer satisfaction.  Customer’s propitiation is our main priority."

        },
        {
            title:'Our vision',
            description:"Our vision is to give luxury and secure ridesharing services to everyone."
        },
        {
            title:'Our history',
            description:"Through best luxury chauffeur services we have given a lot of comfort to our customers."
        }
    ]
    
    return (
            <div 
                className="justify-center py-5"
                data-aos="fade-right"
                data-aos-once="true"
                data-aos-delay="300"
                data-aos-duration="500"
                >
                <Row gutter={[64,32]} style={{width:'93%'}} className='my-5'>
                    <Col xl={{span:10}} lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}} className='align-center'>
                        <Space direction='vertical' size={40}>
                            <div>
                                <Title level={3} className='brand-color'>Epic Chauffeurs benefits</Title>
                                <Title level={1} className='my-0'>
                                    Why you should choose Epic Chauffeurs?
                                </Title>
                            </div>
                            <Row gutter={[8,0]}>
                                {
                                    state?.map((button,b)=>
                                    <Col sm={{span:8}} xs={{span:24}} key={b}>
                                        <div className="Repla-button py-3" onClick={()=>{setCurrentButton(b)}}>
                                            <div className="one brand-bg animation" style={b===currentButton?{height:'100%'}:{}}></div>
                                            <Title level={5} className='my-0 two' style={b===currentButton?{color:'white'}:{}}>{button?.title}</Title>
                                        </div>
                                    </Col>
                                    )
                                }
                            </Row>
                            <p>
                                {
                                    state[currentButton]?.description
                                }
                            </p>
                        </Space>
                    </Col>
                    <Col xl={{span:14}} lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}} className='align-center'>
                        <Space direction='vertical' size={30}>    
                            <div>
                                <Title level={2} className='brand-color'>
                                    {
                                        state[currentButton]?.title
                                    }
                                </Title>
                                <p>
                                    {
                                        state[currentButton]?.description
                                    }
                                </p>
                            </div>
                            <Row gutter={[12,24]}>
                                <Col xl={{span:12}} lg={{span:24}} md={{span:12}} sm={{span:24}} className='aling-center'>
                                    <Image 
                                        src='/luxride/eclass.jpeg' 
                                        alt='epic-ride-london'
                                        preview={false}
                                        />
                                </Col>
                                <Col xl={{span:12}} lg={{span:24}} md={{span:12}} sm={{span:24}} className='align-center'>
                                    <Space direction="vertical">
                                        <Space className="align-center">
                                            <Image 
                                                src='/luxride/tick.png'
                                                alt='epic-ride-london'
                                                preview={false}
                                                />
                                            <p className="my-0">Making this first true generator</p>
                                        </Space>
                                        <Space>
                                            <Image 
                                                src='/luxride/tick.png' 
                                                alt='epic-ride-london'
                                                preview={false}
                                                />
                                            <p className="my-0">Many desktop publish packages</p>
                                        </Space>
                                        <Space>
                                            <Image 
                                                src='/luxride/tick.png' 
                                                alt='epic-ride-london'
                                                preview={false}
                                                />
                                            <p className="my-0">If you are going to passage</p>
                                        </Space>
                                        <Space>
                                            <Image 
                                                src='/luxride/tick.png' 
                                                alt='epic-ride-london'
                                                preview={false}
                                                />
                                            <p className="my-0">It has roots in a piece</p>
                                        </Space>
                                        <Space>
                                            <Image 
                                                src='/luxride/tick.png' 
                                                alt='epic-ride-london'
                                                preview={false}
                                                />
                                            <p className="my-0">It has roots in a piece</p>
                                        </Space>
                                    </Space>
                                </Col>
                            </Row>
                        </Space>
                    </Col>
                </Row>
            </div>
    )
}
export default Choose