import React from 'react'
import { Row, Col, Image, Space, Typography } from 'antd'

import { BreadCrumb } from '../../ui-elements'
import {LuxrideLastestnews} from '../../components'
import { CarsGroup, BookingInstructions} from '../../components'
import LuxrideFleet from '../../components/luxridefleet/LuxrideFleet'
const {Title}= Typography
const Fleets = () => {
  return (
    <div className='dflex'>
        <BreadCrumb title='Epic Fleets' pageName='Fleets' breadcrumb={[{to:'/',name:'Home'}]}/>
        <LuxrideFleet 
            Data={{
                title:'FLEET',
                description:'BEST OUR FLEET',
                description2:"All of our vehicles are come with phone chargers, umbrella's and complimentry water."
            }}
        />
        <BookingInstructions/>
        {/* <LuxrideLastestnews/> */}
        <CarsGroup/>
    </div>
  )
}

export default Fleets