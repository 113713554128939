import React from 'react'
import { Row, Col, Space, Typography, Image } from 'antd'
import moment from "moment"
import './luxridequote.css'
import {
    YoutubeFilled,
    FacebookFilled,
    TwitterOutlined,
    InstagramOutlined,
    LinkedinFilled,
    RightOutlined,
    CheckOutlined
} from '@ant-design/icons'


const { Title, Text } = Typography;

const LuxridequoteFooter = () => {

    const data = {
        contactdetail:[
            {
                iconimg:'location-f.png',
                title:'Location',
                text: 'Regus 450 Bath Road, Longford UB7 0EB'
            },
            {
                iconimg:'call-f.png',
                title:'Lane Line',
                text: '+44 203 9300286'
            },
            {
                iconimg:'clock-f.png',
                title:'Time',
                text: 'Mon - Sun (24/7)'
            },
            {
                iconimg:'mail-f.png',
                title:'Mail',
                text: 'Info@epicridelondon.com'
            },
        ],
        listdetail:[
            {
                title:'QUICK LINKS',
                innerlist:[
                    {
                        to:'/aboutus',
                        icon:<RightOutlined/>,
                        text:'About us'
                    },
                    {
                        to:'/get-a-quote',
                        icon:<RightOutlined/>,
                        text:'Get a Quote'
                    },
                    {
                        to:'/faqs',
                        icon:<RightOutlined/>,
                        text:"FAQs"
                    },
                ]
            },
            {
                title:'COMPANY',
                innerlist:[
                    {
                        to:'/',
                        icon:<CheckOutlined/>,
                        text:'Home'
                    },
                    {
                        to:'/about-us',
                        icon:<CheckOutlined/>,
                        text:'About Us'
                    },
                    {
                        to:'/contact-us',
                        icon:<CheckOutlined/>,
                        text:'Contact Us'
                    },
                    {
                        to:'/services',
                        icon:<RightOutlined/>,
                        text:'Services'
                    },
                    {
                        to:'/fleets',
                        icon:<RightOutlined/>,
                        text:'Our fleets'
                    },
                ]
            }
        ]


    }

  return (
    <div className='navbar'
        style={{backgroundImage:` url(${'/luxride/map-location-2.png'})`}}
        >
        <div className='justify-center py-5 width-100'
        >
            <Row style={{width:'93%'}} gutter={[32,32]}>
                {
                    data?.contactdetail?.map((dat,dt)=>
                    <Col 
                        lg={{span:6}} md={{span:12}} sm={{span:24}} xs={{span:24}} 
                        key={dt} 
                        className='justify-center'
                        data-aos="fade-down"
                        data-aos-delay={300*(dt+1)}
                        data-aos-duration={500}
                        >
                        <Space style={{width:"100%"}} size={20}>
                            <Image 
                                src={'/luxride/icons/'+ dat?.iconimg} 
                                preview={false}  
                                alt='epic-ride-london'
                                />
                            <Space direction='vertical'>
                                <Title level={4} className='my-0 text-white'>
                                    {
                                        dat?.title
                                    }
                                </Title>
                                <Text strong className='text-white my-0'>
                                    {
                                        dat?.text
                                    }
                                </Text>
                            </Space>
                        </Space>
                    </Col>
                    )
                }
            </Row>
        </div>
        <div className='justify-center width-100'>
            <Row gutter={[32,32]} style={{width:'93%'}}>    
                {
                    data?.listdetail?.map((listdet, ldt)=>
                    <Col 
                        lg={5} md={{span:8}} sm={{span:8}} xs={24} 
                        key={ldt}
                        data-aos="zoom-in"
                        data-aos-duration={500}
                        data-aos-delay={300}
                        >
                        <Space direction='vertical' size={15}>
                            <Title level={3} className='brand-color' style={{marginBottom:0}}>{listdet?.title}</Title>
                            <ul className='ul-f m-0'>
                                {
                                    listdet?.innerlist?.map((innerlst,nl)=>
                                        <li key={nl}>
                                            <Space style={{alignItems:"center"}} size={10}>
                                                <span className='brand-color' style={{fontSize:"15px"}}>{innerlst?.icon}</span>
                                                <a 
                                                    href={innerlst?.to} 
                                                    target='_blank' 
                                                    rel="noreferrer"
                                                    className='text-white'
                                                    >
                                                    {innerlst?.text}
                                                </a>
                                            </Space>
                                        </li>
                                    )
                                }
                            </ul>
                        </Space>
                    </Col>
                    )
                }
                <Col 
                    lg={5} md={{span:8}} sm={{span:8}} xs={24}
                    data-aos="zoom-in"
                    data-aos-duration={500}
                    data-aos-delay={300}
                    >
                    <Space direction='vertical' size={15}>
                        <Title level={3} className='brand-color' style={{marginBottom:"0px"}}>
                           We Accept
                        </Title>
                        <Space style={{flexWrap:'wrap'}} szie={20}>
                            <Image 
                                src='/luxride/icons/visa.png' 
                                alt='epic-ride-london'
                                preview={false}
                                style={{width:'55px'}}
                                />
                            <Image 
                                src='/luxride/icons/american-express.png' 
                                alt='epic-ride-london'
                                preview={false}
                                style={{width:'55px'}}
                                />
                            <Image 
                                src='/luxride/icons/paypal.png' 
                                alt='epic-ride-london'
                                preview={false}
                                style={{width:'55px'}}
                                />
                            <Image 
                                src='/luxride/icons/master.png' 
                                alt='epic-ride-london'
                                preview={false}
                                style={{width:'55px'}}
                                />
                            <Image 
                                src='/luxride/icons/unionpay.png' 
                                alt='epic-ride-london'
                                preview={false} 
                                style={{width:'55px'}}
                                />
                            <Image 
                                src='/luxride/icons/diners-club.png' 
                                alt='epic-ride-london'
                                preview={false}
                                style={{width:'55px'}}
                                />
                            <Image 
                                src='/luxride/icons/jcb.png' 
                                alt='epic-ride-london'
                                preview={false}
                                style={{width:'55px'}}
                                />
                        </Space>
                    </Space>
                </Col>
                <Col 
                    lg={9} sm={{span:24}} xs={{span:24}}
                    data-aos="zoom-in"
                    data-aos-duration={500}
                    data-aos-delay={300}
                    >
                    <Space direction='vertical' size={15} style={{width:'100%'}}>
                        <iframe 
                            title="epic-chauffeurs" 
                            style={{width:"100%",height:"215px",border:'none'}} 
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2484.684724291326!2d-0.4858629846926759!3d51.48230112035971!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876719aa710120f%3A0x37bd7ba7e559dfd6!2sRegus%20-%20Heathrow%2C%20Bath%20Road!5e0!3m2!1sen!2s!4v1673726970740!5m2!1sen!2s"  
                            allowFullScreen
                            loading="lazy"
                            >
                        </iframe>
                        {/* <Space size={10} direction='vertical'>
                            <Space>
                                <Text strong className='text-white'>PHO No:</Text>
                                <Text className='text-white'>0102010101</Text>
                            </Space>
                            <Space>
                                <Text strong className='text-white'>Company No:</Text>
                                <Text className='text-white'>10877360</Text>
                            </Space>
                            <Space>
                                <Text strong className='text-white'>Company VAT No:</Text>
                                <Text className='text-white'>421426139</Text>
                            </Space>
                        </Space> */}
                    </Space>
                </Col>
            </Row>
        </div>
        <div 
            className='justify-center pb-5 width-100'
            // data-aos="fade-up"
            // data-aos-duration={500}
            // data-aos-delay={500}
            >
            <Row 
                gutter={[32,24]} 
                style={{width:'93%'}}
                >
                <Col 
                    xs={24} sm={24} md={8} lg={8} 
                    className='align-center'
                    // data-aos="fade-up"
                    // data-aos-duration={500}
                    // data-aos-delay={500}
                    >
                    <Space className='lefts'>
                        <Text className='my-0'>
                            <span className='text-white'>© {moment().format('YYYY')} </span> 
                            <a href="/" className='text-primary'> Epic Ride London</a>
                        </Text>
                    </Space>
                </Col>
                <Col 
                    xs={24} sm={24} md={8} lg={8} 
                    className='justify-center'
                    // data-aos="fade-up"
                    // data-aos-duration={500}
                    // data-aos-delay={1000}
                    >
                    <Space size={10}>
                        <a href="https://www.facebook.com/people/Epic-Ride-London/61550257006200/" className='text-primary' style={{fontSize:"25px"}}>
                            <img src="/assets/icons/facebook.png" className='w-soc' alt="" />
                        </a>
                        <a href="https://www.reddit.com/user/EpicRideLondon/" className='text-primary' style={{fontSize:"25px"}}>
                            <img src="/assets/icons/reddit.png" className='w-soc' alt="" />
                        </a>
                        <a href="https://www.quora.com/profile/Epic-Ride-London" className='text-primary' style={{fontSize:"25px"}}>
                            <img src="/assets/icons/quora.png" className='w-soc' alt="" />
                        </a>
                        <a href="https://www.instagram.com/epicridelondon/" className='text-primary' style={{fontSize:"25px"}}>
                            <img src="/assets/icons/instagram.png" className='w-soc' alt="" />
                        </a>
                        <a href="https://www.pinterest.com/EpicRideLondon/" className='text-primary' style={{fontSize:"25px"}}>
                            <img src="/assets/icons/pinterest.png" className='w-soc' alt="" />
                        </a>
                    </Space>
                </Col>
                <Col 
                    xs={24} sm={24} md={8} lg={8} 
                    className='align-center'
                    // data-aos="fade-up"
                    // data-aos-duration={500}
                    // data-aos-delay={1500}
                    >
                    <Space className='rights'>
                        <Text className='text-white my-0'>
                            All Rights Reserved.
                        </Text>
                    </Space>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default LuxridequoteFooter