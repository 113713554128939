import React, { useState } from "react"
import { BreadCrumb, MultipleImageUpload, SectionTop } from "../../ui-elements"
import {Form, Row, Col, Button, Typography, message} from "antd"
import {CitiesInput, CitiesCheckbox, CitiesRadioGroup, SingleImageFile, FileUpload} from "../../ui-elements"
import { RegisterDriver } from "../../shared/apis/DriverRegisration"
const {Title, Text}= Typography
const DriverRegistration=()=>{
    const [fileList, setFileList] = useState([])
    const [loading, setLoading]= useState(false)
    const [form] = Form.useForm()
    const register= async ()=>{
        setLoading(true)
        let data=form.getFieldsValue()
        let formData = new FormData()
        formData.append(`fullName`, data?.fullName)
        formData.append(`email`, data?.email)
        formData.append(`phone`, data?.phone)
        formData.append(`NiNumber`, data?.NiNumber)
        formData.append(`StreetAddress`, data?.StreetAddress)
        formData.append(`addressLinetwo`, data?.addressLinetwo)
        formData.append(`city`, data?.city)
        formData.append(`state`, data?.state)
        formData.append(`postalCode`, data?.zipCode || 44013)
        formData.append(`country`, data?.country)
        formData.append(`sortCode`, data?.sortCode)
        formData.append(`accountNumber`, data?.accountNumber)
        formData.append(`comment`, data?.comment)
        formData.append(`profilePhoto`, data?.profilePhoto?.fileList[0]?.originFileObj)
        for(let i=0;i<fileList?.length;i++)
            formData.append(`carPicture[]`, fileList[i]?.originFileObj)
        formData.append(`driverPCOlicense`, data?.driverPCOlicense?.fileList[0]?.originFileObj)
        formData.append(`driverPCObadge`, data?.driverPCObadge?.fileList[0]?.originFileObj)    
        for(let i=0;i< data?.drivingLicense?.fileList?.length;i++)
            formData.append(`drivingLicense[]`, data?.drivingLicense?.fileList[i]?.originFileObj)
        for(let i=0;i<data?.carinsurance?.fileList?.length;i++)
            formData.append(`carinsurance[]`, data?.carinsurance?.fileList[i]?.originFileObj) 
        for(let i=0;i<data?.logBookPicture?.fileList?.length;i++)
            formData.append(`logBookPicture[]`, data?.logBookPicture?.fileList[i]?.originFileObj)
        for(let i=0;i<data?.motCertificate?.fileList?.length;i++)
            formData.append(`motCertificate[]`, data?.motCertificate?.fileList[i]?.originFileObj)
        for(let i=0;i<data?.VehiclePCOlicense?.fileList?.length;i++)
            formData.append(`VehiclePCOlicense[]`, data?.VehiclePCOlicense?.fileList[i]?.originFileObj)
        
        formData.append(`agree`, data?.agree || true)
        formData.append(`countryStatus`, data?.countryStatus)
        await RegisterDriver(formData)
        setLoading(false)
        message.success('Registered successfully');
    }
    return (
        <>
            <BreadCrumb 
                title='Driver Registration' 
                pageName='Driver Registration' 
                breadcrumb={[{to:'/',name:'Home'}]}
                />
            <div className="flex-col-center py-5"> 
                <SectionTop 
                    description='want to become Chauffeur?' 
                    title='Register yourself'
                    />
                <div className="width-93">
                    <Form 
                        layout="vertical"
                        onFinish={register}
                        onFinishFailed={
                            ()=>{
                                let data=form.getFieldsValue()
                                console.log(data)
                            }
                        }
                        form={form}
                        >
                        <Row gutter={[16,32]}>
                            <Col span={24}>
                                <Row gutter={[16,16]}>
                                    <Col span={24}>
                                        <Title level={2} className='brand-color-blue my-0'>Personal Info</Title>
                                    </Col>
                                    <Col span={24}>
                                        <SingleImageFile
                                            name='profilePhoto'
                                            label='Profile Photo'
                                            required
                                            message='please upload profile photo'
                                            />
                                    </Col>
                                    <Col lg={{span:6}} sm={{span:12}} xs={{span:24}}>
                                        <CitiesInput 
                                            name='fullName'
                                            label='Full Name'
                                            required
                                            message='please enter name'
                                            value={form.getFieldValue('fullName') || ''}
                                            placeholder='e.g John Doe'
                                            size='large'
                                            />
                                    </Col>
                                    <Col lg={{span:6}} sm={{span:12}} xs={{span:24}}>
                                        <CitiesInput 
                                            name='email'
                                            label='Email'
                                            required
                                            message='please enter email'
                                            value={form.getFieldValue('email') || ''}
                                            placeholder='e.g John2000@gmail.com'
                                            size='large'
                                            />
                                    </Col>
                                    <Col lg={{span:6}} sm={{span:12}} xs={{span:24}}>
                                        <CitiesInput 
                                            name='phone'
                                            label='Phone'
                                            required
                                            message='please enter phone #'
                                            value={form.getFieldValue('phone') || ''}
                                            placeholder='e.g +44 207 1128072'
                                            size='large'
                                            />
                                    </Col>
                                    <Col lg={{span:6}} sm={{span:12}} xs={{span:24}}>
                                        <CitiesInput 
                                            name='NiNumber'
                                            label='NI Number'
                                            required
                                            message='please enter ni number'
                                            value={form.getFieldValue('NiNumber') || ''}
                                            placeholder='e.g 23456'
                                            size='large'
                                            />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Row gutter={[16,16]}>
                                    <Col span={24}>
                                        <Title level={2} className='brand-color-blue my-0'>Address</Title>
                                    </Col>
                                    <Col md={{span:8}} sm={{span:12}} xs={{span:24}}>
                                        <CitiesInput 
                                            name='StreetAddress'
                                            label='Street Address'
                                            required
                                            message='please enter street address'
                                            value={form.getFieldValue('StreetAddress') || ''}
                                            placeholder='e.g street #35'
                                            size='large'
                                            />
                                    </Col>
                                    <Col md={{span:8}} sm={{span:12}} xs={{span:24}}>
                                        <CitiesInput 
                                            name='addressLinetwo'
                                            label='Address Line 2'
                                            required
                                            message='please enter address line 2'
                                            value={form.getFieldValue('addressLinetwo') || ''}
                                            placeholder='e.g street #35 w34'
                                            size='large'
                                            />
                                    </Col>
                                    <Col md={{span:8}} sm={{span:12}} xs={{span:24}}>
                                        <CitiesInput 
                                            name='city'
                                            label='City'
                                            required
                                            message='please enter city'
                                            value={form.getFieldValue('city') || ''}
                                            placeholder='e.g London'
                                            size='large'
                                            />
                                    </Col>
                                    <Col md={{span:8}} sm={{span:12}} xs={{span:24}}>
                                        <CitiesInput 
                                            name='state'
                                            label='State / Province / Region '
                                            required
                                            message='please enter state'
                                            value={form.getFieldValue('state') || ''}
                                            placeholder='e.g London'
                                            size='large'
                                            />
                                    </Col>
                                    <Col md={{span:8}} sm={{span:12}} xs={{span:24}}>
                                        <CitiesInput 
                                            name='postalCode'
                                            label='ZIP / Postal Code '
                                            required
                                            message='please enter postal code'
                                            value={form.getFieldValue('postalCode') || ''}
                                            placeholder='e.g SW1H'
                                            size='large'
                                            />
                                    </Col>
                                    <Col md={{span:8}} sm={{span:12}} xs={{span:24}}>
                                        <CitiesInput 
                                            name='country'
                                            label='Country'
                                            required
                                            message='please enter country'
                                            value={form.getFieldValue('country') || ''}
                                            placeholder='e.g UK'
                                            size='large'
                                            />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Row gutter={[16,16]}>
                                    <Col span={24}>
                                        <Title level={2} className='brand-color-blue my-0'>Bank Details</Title>
                                    </Col>
                                    <Col md={{span:12}} sm={{span:12}} xs={{span:24}}>
                                        <CitiesInput 
                                            name='sortCode'
                                            label='Sort Code'
                                            required
                                            message='please enter sort code'
                                            value={form.getFieldValue('sortCode') || ''}
                                            placeholder='e.g 123456'
                                            size='large'
                                            />
                                    </Col>
                                    <Col md={{span:12}} sm={{span:12}} xs={{span:24}}>
                                        <CitiesInput 
                                            name='accountNumber'
                                            label='Account Number'
                                            required
                                            message='please enter account number'
                                            value={form.getFieldValue('accountNumber') || ''}
                                            placeholder='e.g 5555 6666 6666 6666'
                                            size='large'
                                            />
                                    </Col>
                                    <Col span={24}>
                                        <CitiesInput
                                            textArea 
                                            name='comment'
                                            label='Comment'
                                            required
                                            message='please enter comment'
                                            value={form.getFieldValue('comment') || ''}
                                            placeholder='e.g '
                                            size='large'
                                            />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Row gutter={[16,16]}>
                                    <Col span={24}>
                                        <Title level={2} className='brand-color-blue my-0'>Documents</Title>
                                    </Col>
                                    <Col span={24}>
                                        <MultipleImageUpload
                                            name='carPicture'
                                            label='5 to 6 Picture of the car'
                                            required
                                            message='please upload 5 to 6 pictures of the car'
                                            fileList={fileList}
                                            setFileList={setFileList}
                                            />
                                    </Col>
                                    <Col lg={{span:4}} md={{span:6}} sm={{span:12}} xs={{span:24}}>
                                        <FileUpload
                                            name='driverPCOlicense'
                                            label='Driver PCO license'
                                            required
                                            message='please upload detail'
                                            />
                                    </Col>
                                    <Col lg={{span:4}} md={{span:6}} sm={{span:12}} xs={{span:24}}>
                                        <FileUpload
                                            name='driverPCObadge'
                                            label='Driver PCO badge'
                                            required
                                            message='please upload detail'
                                            />
                                    </Col>
                                    
                                    <Col md={{span:6}} sm={{span:12}} xs={{span:24}}>
                                        <FileUpload
                                            name='drivingLicense'
                                            label='Driving license (Both side)'
                                            required
                                            message='please upload detail'
                                            multiple
                                            />
                                    </Col>
                                    <Col lg={{span:4}} md={{span:6}} sm={{span:12}} xs={{span:24}}>
                                        <FileUpload
                                            name='carinsurance'
                                            label='Car insurance '
                                            required
                                            message='please upload details'
                                            multiple
                                            />
                                    </Col>
                                    <Col md={{span:6}} sm={{span:12}} xs={{span:24}}>
                                        <FileUpload
                                            name='logBookPicture'
                                            label='Logbook '
                                            required
                                            message='please upload pages'
                                            multiple
                                            />
                                    </Col>
                                    <Col lg={{span:4}} md={{span:6}} sm={{span:12}} xs={{span:24}}>
                                        <FileUpload
                                            name='motCertificate'
                                            label='Mot Certificate '
                                            required
                                            message='please upload details'
                                            multiple
                                            />
                                    </Col>
                                    <Col lg={{span:4}} md={{span:6}} sm={{span:12}} xs={{span:24}}>
                                        <FileUpload
                                            name='VehiclePCOlicense'
                                            label='Vehicle PCO license'
                                            required
                                            message='please upload details'
                                            multiple
                                            />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Row gutter={[16,16]}>
                                    <Col md={{span:24}} sm={{span:24}} xs={{span:24}}>
                                        <CitiesRadioGroup 
                                            name='countryStatus'
                                            label='Are you currently available in the "United Kingdom"'
                                            checked={form.getFieldValue('countryStatus')}
                                            options={[
                                                {value:'Yes', name:'Yes'},
                                                {value:'No', name:'No'},
                                            ]}
                                            />
                                    </Col>
                                    <Col md={{span:24}} sm={{span:24}} xs={{span:24}}>
                                        <CitiesCheckbox 
                                            name='agree'
                                            label='Terms & Conditions'
                                            checked={form.getFieldValue('agree')}
                                            text='Do you agree to our terms and policy?'
                                            size='small'
                                            />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24} className='center'>
                                <div style={{width:'280px'}}>
                                    <Button 
                                        type='primary' 
                                        htmlType='submit' 
                                        size='large' 
                                        loading={loading}
                                        block 
                                    >
                                        <Text strong className="text-white">Register</Text>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>    
            </div>
        </>
    )
}
export default DriverRegistration