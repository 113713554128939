import React, { useState } from 'react'
import { Row, Col, Typography, Image } from 'antd'
import './luxridesoutham.css'
const { Title, Text } = Typography;

const LuxrideSouthampExcl = () => {
    const [hovers, setHovers] = useState(false)
    const [hoverstwo, setHoverstwo] = useState(true)
    const [hoversthree, setHoversThree] = useState(false)
    const hoverstop = false
    const [hoverfour, setHoverFour] = useState(true)
    const [hoverfive, setHoverFive] = useState(false)
    const [hoversix, setHoverSix] = useState(false)
    const [activeclass, setActiveClass] = useState(true)
    const [activeclassb, setActiveClassB] = useState(true)
    const [activeclassc, setActiveClassC] = useState(true)


    const hoverontop = () =>{
        setActiveClassB(false)
        
    }

    const hoverofftop = () =>{
        setActiveClassB(true)
    }

    const hoveron = () =>{
        setHovers(true)      
        setHoverstwo(false)
        setActiveClass(false)
    }

    const hoveroff = () =>{
        setHovers(false)
        setHoverstwo(true)
        setActiveClass(true)
    }

    const hoverontwo = () =>{  
        setActiveClass(true)
    }

    const hoveronthree = () =>{  
        setHoversThree(true)
        setHoverstwo(false)
        setActiveClass(false)
    }
    const hoveroffthree = () =>{  
        setHoversThree(false)
        setHoverstwo(true)
        setActiveClass(true)
    }


    const hoveronfour = () =>{  
        setHoverFour(true)
        setActiveClassC(true)
    }
   
    const hoveronfive = () =>{ 
        setHoverFive(true) 
        setHoverFour(false)
        setActiveClassC(false)
    }
    const hoverofffive = () =>{  
        setHoverFive(false) 
        setHoverFour(true)
        setActiveClassC(true)
    }

    const hoveronsix = () =>{ 
        setHoverSix(true) 
        setHoverFour(false)
        setActiveClassC(false)
    }
    const hoveroffsix = () =>{  
        setHoverSix(false) 
        setHoverFour(true)
        setActiveClassC(true)
    }
    

  return (
    <div className='dflex' style={{marginTop:"30px"}}>
        <div className='width93'>
            <Row gutter={[5,5]}>
                <Col xs={24} sm={24} md={12} lg={12} className='col-css'>
                    <div className='ex-show ex-1'>
                        <Image 
                            src='/luxride/exc-1.jpg' 
                            alt='epic-ride-london'
                            preview={false}
                            className='imgex' 
                            />
                        {
                            activeclassb && 
                            <div className={hoverstop? 'textover-ex':'textover-ex activshow'}>
                                <Title level={1} className='text-white'>Southampton Transfers</Title>
                                <Text className='text-white'>
                                    Epic Ride London is a popular chauffeur company for ground transportation management delivering Southampton services
                                </Text>
                                <div style={{marginTop:"30px"}}>
                                    <button className='btnnew'>Read More</button>
                                </div>
                            </div>
                        }
                    </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} className='col-css' onMouseOver={hoverontop} onMouseOut={hoverofftop}>
                    <div className='ex-show ex-1'>
                        <Image 
                            src='/luxride/exc-2.jpg' 
                            alt='epic-ride-london'
                            preview={false}
                            className='imgex' 
                            />
                        <div className='textover-ex'>
                            <Title level={1} className='text-white'>Airport Transfers</Title>
                            <Text className='text-white'>
                                Going to London? We’re here to make your journey more exciting and comfortable by saving your money and effort.  
                            </Text>
                            <div style={{marginTop:"30px"}}>
                                <button className='btnnew'>Read More</button>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <div className='wrapper'>
                <div className='col'>
                    <div className='ex-show ex-2' onMouseOver={hoveron} onMouseOut={hoveroff}>
                        <Image 
                            src='/luxride/exc-1-1.jpg'  
                            alt='epic-ride-london'
                            preview={false}
                            className='imgex imhov' 
                            />
                        <div className='textover-ex-1'>
                            {
                                hovers &&
                                <div>                            
                                    <Title level={1} className='text-white'>VIP Executive Transfers</Title>
                                    <Text className='text-white'>
                                        Our executive VIP chauffeurs cover all the VIP transfers. They possess the certifications, licensing, and permits necessary for the trips and are trained to provide the best customer experience. Now, you don’t have to worry about your VIP transfers as we are here to take care of the business.
                                    </Text>
                                    <div style={{marginTop:"30px"}}>
                                        <button className='btnnew'>Read More</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className={activeclass? 'col col-active': 'col'} onMouseOver={hoverontwo}>
                    <div className='ex-show ex-2' >
                        <Image 
                            src='/luxride/exc-1-2.jpg' 
                            alt='epic-ride-london'
                            preview={false}
                            className='imgex imhov' 
                            />                        
                        <div>   
                            {
                                hoverstwo &&                              
                                <div className='textover-ex-1 showe'>
                                    <Title level={1} className='text-white'>Wedding Transfer Service</Title>
                                    <Text className='text-white'>
                                        Everyone looks up to making perfect arrangements for their wedding. So, we give you the best of our vehicles and drivers to assure that you get the service that matches the value of the occasion. You can choose from our fleet and we will be there at your service.
                                    </Text>
                                    <div style={{marginTop:"30px"}}>
                                        <button className='btnnew'>Read More</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className='col' onMouseOver={hoveronthree} onMouseOut={hoveroffthree}>
                    <div className='ex-show ex-2'>
                        <Image 
                            src='/luxride/exc-1-3.jpg' 
                            alt='epic-ride-london'
                            preview={false}
                            className='imgex imhov' 
                            />
                        <div className='textover-ex-1'>
                            {
                            hoversthree &&
                                <div>
                                    <Title level={1} className='text-white'>Private Aviation</Title>
                                        <Text className='text-white'>
                                            A private aviation chauffeur service from a reputed chauffeur company gives you the flexibility and security while travelling. 
                                        </Text>
                                        <div style={{marginTop:"30px"}}>
                                            <button className='btnnew'>Read More</button>
                                        </div>
                                </div>
                            }
                        </div>
                        
                    </div>
                </div>
            </div>


            <div className='wrapper'>
                <div className={activeclassc? 'col col-active cs-hover': 'col cs-hover'} onMouseOver={hoveronfour}>
                    <div className='ex-show ex-2' >
                        <Image 
                            src='/luxride/exc-2-1.jpg' 
                            alt='epic-ride-london'
                            preview={false}
                            className='imgex imhov' 
                            />
                        <div>
                            {
                                hoverfour && 
                                <div className='textover-ex-1 showe'>                                
                                    <Title level={1} className='text-white'>Corporate Transfers</Title>
                                    <Text className='text-white'>
                                        A professional business meeting demands an executive journey. Getting to business meetings requires both safety and punctuality.
                                    </Text>
                                    <div style={{marginTop:"30px"}}>
                                        <button className='btnnew'>Read More</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className='col'>
                    <div className='ex-show ex-2' onMouseOver={hoveronfive} onMouseOut={hoverofffive}>
                        <Image 
                            src='/luxride/exc-2-2.jpg' 
                            alt='epic-ride-london'
                            preview={false}
                            className='imgex imhov' 
                            />
                        <div  className='textover-ex-1 '>
                            {
                                hoverfive &&
                                <div>                            
                                    <Title level={1} className='text-white'>Special Events Transfers</Title>
                                    <Text className='text-white'>
                                        London is popular for organizing world-class events and Pegasus Chauffeur is proud to offer personal event chauffeurs 
                                    </Text>
                                    <div style={{marginTop:"30px"}}>
                                        <button className='btnnew'>Read More</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                
                <div className='col' onMouseOver={hoveronsix} onMouseOut={hoveroffsix}>
                    <div className='ex-show ex-2'>
                        <Image 
                            src='/luxride/exc-2-3.jpg' 
                            alt='epic-ride-london'
                            preview={false}
                            className='imgex imhov' 
                            />
                        <div className='textover-ex-1'>
                            {
                                hoversix &&
                                <div>
                                    <Title level={1} className='text-white'>London Sightseeing Transfers</Title>
                                    <Text className='text-white'>
                                        Epic Ride London is a popular chauffeur company for ground transportation management delivering sightseeing services 
                                    </Text>
                                    <div style={{marginTop:"30px"}}>
                                        <button className='btnnew'>Read More</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default LuxrideSouthampExcl