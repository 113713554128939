import React from 'react'
import { Col, Row, Typography, Space, Image } from 'antd'

const { Title } = Typography

export const PrivateAviationServices = () => {
  return (
    <div className='py-5 justify-center'>
        <div className='width-93'>
            <Row gutter={[32,32]}>
                <Col lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}}>
                    <Space direction='vertical' size={30}>
                        <div>
                            <Title 
                                level={4} 
                                className='brand-color'
                                data-aos="fade-down"
                                data-aos-duration="800" 
                                data-aos-once="true"
                                data-aos-delay="300"
                                >
                                EXECUTIVE CHAUFFEUR SERVICE COMPANY
                            </Title>
                            <Title 
                                level={1} 
                                className='my-0'
                                data-aos="fade-up-right"
                                data-aos-duration="800" 
                                data-aos-once="true"
                                data-aos-delay="300"
                                >
                                Private Aviation Services:
                            </Title>
                        </div>
                        <Space 
                            direction='vertical'
                            data-aos="fade-right"
                            data-aos-duration="800" 
                            data-aos-once="true"
                            data-aos-delay="300"
                            >
                            <p>
                                Experience the ultimate in luxury and convenience with our private aviation services. We specialize in providing unparalleled air travel solutions for discerning individuals, business executives, and jetsetters who value privacy, comfort, and flexibility.
                            </p>
                            <p>
                                Our private aviation services offer an exclusive and personalized flying experience. Skip the hassles of commercial airports and long security lines as we provide a seamless and efficient journey from start to finish. Enjoy the privilege of traveling on your schedule, with the freedom to choose your departure and arrival locations.
                            </p>
                            <p>
                                Our fleet of state-of-the-art private aircraft is meticulously maintained to the highest standards of safety and comfort. Each aircraft offers spacious cabins, plush seating, and cutting-edge amenities, ensuring a luxurious and enjoyable flight experience.
                            </p>
                            <p>
                                Our team of dedicated professionals is committed to delivering exceptional service. From expert pilots to attentive cabin crew, every detail is taken care of to provide a truly first-class experience.
                            </p>
                            <p>
                                Whether it's for business travel, special occasions, or leisure trips, our private aviation services offer the utmost privacy, convenience, and style. Elevate your travel experience and discover the world of private aviation with us.
                            </p>
                        </Space>
                    </Space>
                </Col>
                <Col 
                    lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}} 
                    data-aos="fade-left"
                    data-aos-duration="800" 
                    data-aos-once="true"
                    data-aos-delay="300"
                    >
                    <div style={{height:"100%", width:'100%'}} className='brand-bg-blue p-3 py-5 center'>
                        <Image 
                            src='/assets/fleets/e class/11.jpg' 
                            alt='epic-ride-london' 
                            className="img" 
                            width={'100%'} 
                            preview={false}
                            data-aos="zoom-in"
                            data-aos-duration="800" 
                            data-aos-once="true"
                            data-aos-delay="300"
                            />
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}