import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router'
import { BreadCrumb } from '../../ui-elements'
import MercedesService from '../../components/mercedesservice/MercedesService'
import { dataclass } from '../../components/mercedesservice/data'
const Fleet = () => {
    let { fleet } = useParams();
    const [state, setState]= useState(null)
    useEffect(()=>{
        if(fleet==='v-class')
            setState({...dataclass[0]?.vClass})
        else if(fleet==='bentley')
            setState({...dataclass[0]?.bentley})
        else if(fleet==='rolls-royce')
            setState({...dataclass[0]?.rollsRoyce})
        else if(fleet==='range-rover')
            setState({...dataclass[0]?.rangerRover})
        else if(fleet==='e-class')
            setState({...dataclass[0]?.eClass})
        else if(fleet==='s-class')
            setState({...dataclass[0]?.sClass})
        else 
        setState(null)
    },[fleet])
  return (
    <div className='flex-col-center'>
        <BreadCrumb 
            title={state?.heading || '404 not found'} 
            description='' 
            pageName={state?.heading || '404 not found'} 
            breadcrumb={
                [
                    {to:'/',name:'Home'},
                    {to:'/fleets', name:'fleets'}
                ]
            }
            />
        <div style={{width:'93%'}}>
            <MercedesService />
        </div>
    </div>
  )
}

export default Fleet