import './index.css'
import { Typography, Image} from 'antd'
const { Text}= Typography
const CarsGroup=()=>{
    return (
        <>
        <div 
            className='py-5 justify-center'
            >
                <div className="width-93 flex-col-center">
                    <Image 
                        src='/luxride/cars-group.png'
                        alt='epic-ride-london'
                        data-aos="fade-right"
                        data-aos-duration={500}
                        data-aos-delay={300}
                        data-aos-once={true}
                        preview={false}
                        />
                    <Text 
                        strong style={{textAlign:'center'}} 
                        className='pt-5'
                        data-aos="fade-left"
                        data-aos-duration={500}
                        data-aos-delay={300}
                        data-aos-once={true}
                        >
                        <a href='/fleets/s-class'>Mercedes S Class</a>, <a href='/fleets/e-class'>E Class</a>, <a href='/fleets/v-class'>V Class</a>, <a href='/fleets/bentley'>Bentley</a>, <a href='/fleets/range-rover'>Range Rover</a>, and <a href='/fleets/rolls-royce'>Rolls Royce</a> are just a few of the luxurious vehicles we provide, so we can make sure you get the correct chauffeur and luxury car to suit your demands. Before a journey begins, all of our vehicles are properly examined to ensure they match our high standards throughout and are prepared to carry any essentials, such as bottled water and any other particular requirements.
                    </Text>
                </div>
        </div>
        </>
    )
}
export {CarsGroup}