import { BreadCrumb } from '../../ui-elements/index';
import ContactUsForm from './contactus form'
const ContactUs=()=>
    {
        return    (
            <>
              <BreadCrumb title='Contact Us' description='We Can Help You Succeed' pageName='Contact Us' breadcrumb={[{to:'/',name:'Home'}]}/>
              <div className="flex-col-center"> 
                <ContactUsForm/>
                <iframe 
                  title="epic-chauffeurs" 
                  style={{width:"100%",height:"70vh",border:'none'}} 
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2484.684724291326!2d-0.4858629846926759!3d51.48230112035971!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876719aa710120f%3A0x37bd7ba7e559dfd6!2sRegus%20-%20Heathrow%2C%20Bath%20Road!5e0!3m2!1sen!2s!4v1673726970740!5m2!1sen!2s"  
                  allowFullScreen
                  loading="lazy">
                </iframe>
              </div>
          </>
        )
    }
export default ContactUs;
