import LuxrideRegion from '../../components/luxrideregion'
import AboutEpic from '../../components/aboutEpic'
import { BreadCrumb } from '../../ui-elements'
import { BookingInstructions, EpicTestimonial, Intro } from '../../components'
import LuxrideFleet from '../../components/luxridefleet/LuxrideFleet'
import BusinessPartner from '../../components/businessPartner'

const LuxrideAboutUs = () => {
  return (
    <div>
        <BreadCrumb 
          title='About Epic Ride London' 
          pageName='About us' 
          breadcrumb={[{to:'/',name:'Home'}]}
          />
        <Intro/>
        <LuxrideRegion/>
        <BookingInstructions/>
        <AboutEpic/>
        <EpicTestimonial/>
        <LuxrideFleet/>
        <BusinessPartner/>
    </div>
  )
}

export default LuxrideAboutUs