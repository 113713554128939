import React, { useState } from 'react'
import { useNavigate } from "react-router-dom"
import { useDispatch } from 'react-redux';
import {Row, Col, Space, Typography } from 'antd'
import Tab1 from './Tab1';
import './luxridetabform.css'
import './index.css'
import { useEffect } from 'react';
import { actionsApi } from '../../shared';
const {Text}= Typography

const LuxrideTabsForm = ({lightGreyBg}) => {
  const dispatch= useDispatch()
  const navigate = useNavigate()
  const tabs=[
    'Distance',
    'Hourly',
    'Get quote'
  ]
  const [currentTab, setCurrentTab]= useState(0)
  useEffect(()=>{
    dispatch(actionsApi?.updateServiceType(currentTab+1))
    if(currentTab===2)
    navigate("/get-a-quote")
  }, [currentTab])
  return (
    <>
    <Space direction='vertical' style={{width:'100%'}}>
        <Row gutter={[8,8]}>
          {
            tabs?.map((tab,t)=>
              <Col span={8}  key={t} onClick={()=>setCurrentTab(t)}>
                <div className={(currentTab===t?'lux-tab-active':lightGreyBg?'lux-tab-light':'lux-tab')+' center py-3'}>
                  <Text strong style={{color:currentTab===t?'white':'black'}}>
                    {
                      tab
                    }
                  </Text>
                </div>
              </Col>
            )
          }
        </Row>
        <Row>
          <Tab1 lightGreyBg={lightGreyBg}/>
        </Row>
    </Space>
    </>
  )
}

export default LuxrideTabsForm