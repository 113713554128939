import React from 'react'
import {Space, Image, Typography} from 'antd'
import { BreadCrumb } from '../../ui-elements'
import './index.css'
function CommingSoon() {
  return (
    <div className='flex-col-center'>
      <BreadCrumb title='Coming Soon' description='Page not found' pageName='Coming Soon' breadcrumb={[{to:'/',name:'Home'}]}/>
      <div style={{width:'93%'}} className='py-5'>   
          <div  className='flex-col-center'>
                <Typography.Title level={1} className='my-0 text-center brand-color coming-soon'>Coming <br></br> Soon</Typography.Title>
                <Typography.Title level={2} className='text-center'>See u soon!!!</Typography.Title>
          </div>
      </div>
    </div>
  )
}

export default CommingSoon