import React, { useState} from 'react'
import { useNavigate } from "react-router-dom";
import { Form, DatePicker, Row, Col, TimePicker, AutoComplete, Typography, Space, Button, Select } from 'antd';
import ReplaButton from '../buttons';
import { PlusOutlined, MinusOutlined, LoadingOutlined} from '@ant-design/icons';
import { getLocations, distanceMatrix } from './apiCalls';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { actionsApi } from '../../shared';
const {Text}= Typography

const Tab2 = ({lightGreyBg}) => {
  console.log(useSelector(state=>state))
  const navigate = useNavigate();
  const dispather= useDispatch();
  const [options, setOptions]=useState({
    pickupLocations:[''],
    dropOffLocation:[]
  })
  const [state, setState]= useState({
    pickupLocations:[{
        value:'',
    }],
    dropOffLocation:'',
    pickupDate:moment(),
    pickupTime:moment(),
    travelMode:1,
    totalDistance:'15 km',
    totalTime:'1h 3m',
    totalAmount:'5.00',
    remainingAmount:'4.00'
  })
  const [loading, setLoading]= useState(false)
  const addLocation=(key)=>{
    state[key]=[...state[key],'']
    setState({...state})
    //
    options[key]=[...options[key],'']
    setOptions({...options})
  }
  const removeLocation=(key, index)=>{
    state[key]=state[key]?.filter((_, i)=> i!==index)
    setState({...state})   
    //
    options[key]=options[key]?.filter((_, i)=> i!==index)
    setOptions({...options})   
  }
  const check=()=>{
    console.log(document.getElementsByClassName('form-height')[0].offsetHeight)
  }
  const nextStep=async ()=>{
    setLoading(true)
    let coordinates=state?.pickupLocations?.map(location=>{
        let index= -1
        for(let i=0;i< options?.pickupLocations?.length;i++)
        {
            index= options?.pickupLocations[i]?.findIndex(_=> _?.value===location)
            if(index>-1)
              {

                return options?.pickupLocations[i][index]?.coordinates
                break
              }
        }
    })
    coordinates=[
        ...coordinates, 
        options?.dropOffLocation[options?.dropOffLocation?.findIndex(_=>_.value===state?.dropOffLocation)]?.coordinates
    ] 
    let result = await distanceMatrix(coordinates?.join(";"))
   
    console.log("result:", result)
    // console.log("result:", state)
    // console.log("result:", tempArr)
    let distance=0
    for(let i=0;i<(result?.distances?.length-1);i++)
    {
        distance+=result?.distances[i][i+1]
    }
    let duration=0
    for(let i=0;i<(result?.durations?.length-1);i++)
    {
        duration+=result?.durations[i][i+1]
    }
    let tempArr={
        pickupDate: moment(state?.pickupDate).format("MMMM Do YYYY"),
        pickupTime: moment(state?.pickupTime).format("HH:mm"),
        pickupLocations: state?.pickupLocations[0],
        wayPoints: state?.pickupLocations?.filter((_,i)=>i>0)?.join(" - "),
        dropOffLocation: state?.dropOffLocation,
        totalDistance: (distance * 0.000621371).toFixed(2),
        totalTime: (moment.utc(duration*1000).format('HH:mm'))
    }
    dispather(actionsApi?.updateEpicRide(tempArr))
    setLoading(false)
    navigate("/booking")
  }
  const handleChange=(key, index, value)=>{
        if(key=== "dropOffLocation")
            state[key]=value
        else
        state[key]=state[key]?.map((_,i)=>{
            if(i===index)
            return value
            return _
        })
        setState({...state})
  }
  const handleState=(key, value)=>{
    setState({
        ...state,
        [key]:value
    })
  }
  console.log(state,":",options)
  const handleOptions=(key, index, value)=>{
    if(key=== "dropOffLocation")
    options[key]= value
    else
        options[key]=options[key]?.map((_,i)=>{
            if(i===index)
            return value
            return _
        })
    setOptions({...options})
  }

  return (
    <div style={{width:'100%'}} >
        <Space direction='vertical' style={{width:'100%'}}>
            <Form
                layout='vertical'
                className={(lightGreyBg?'light-grey-bg':'bg-white')+' form-height px-3 py-4'}
                onClick={check}
            >
                <Row gutter={[12,12]}>
                    <Col sm={{span:6}} xs={{span:24}}>
                        <Form.Item
                            label={<Text strong>Pickup date</Text>}
                        >
                            <DatePicker 
                                style={{width:'100%'}}
                                size='large'
                                value={state?.pickupDate?moment(state?.pickupDate):null}
                                onChange={(value)=>{handleState('pickupDate', value)}}
                                />
                        </Form.Item>
                    </Col>
                    <Col sm={{span:6}} xs={{span:24}}>
                        <Form.Item
                            label={<Text strong>Pickup time</Text>}
                        >
                            <TimePicker 
                                style={{width:'100%'}}
                                size='large'
                                format={'HH:mm'}
                                value={state?.pickupTime?moment(state?.pickupTime):null}
                                onChange={(value)=>{handleState('pickupTime', value)}}
                                />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={<Text strong>Travel mode</Text>}
                        >
                            <Select
                                value={state?.travelMode}
                                size='large'
                                onChange={(value)=>{handleState('travelMode', value)}}
                            >
                                <Select.Option value={1}>One Way</Select.Option>
                                <Select.Option value={2}>two Way</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Space>
                            <Text strong>Pickup Locations</Text>
                            <Button type='primary' size='small' shape='circle' onClick={()=>{addLocation('pickupLocations')}}>
                                <PlusOutlined className='up' style={{fontSize:'12px'}}/>
                            </Button>
                        </Space>
                    </Col>
                    {
                        state?.pickupLocations?.map((location, index)=>
                            <Col sm={{span:12}} xs={{span:24}}>
                            <Form.Item
                                label={
                                    <Space>
                                        <Text strong>Pickup location {index+1}</Text>
                                        {
                                            index > 0 &&
                                            <Button type='primary' size='small' shape='circle' onClick={()=>{removeLocation('pickupLocations', index)}}>
                                                <MinusOutlined className='up' style={{fontSize:'12px'}}/>
                                            </Button>
                                        }
                                    </Space>
                                }
                            >
                                <AutoComplete
                                    options={options?.pickupLocations[index]}
                                    value={location || ''}
                                    onSelect={(value)=>{
                                        handleChange("pickupLocations", index, value) 
                                    }}
                                    onChange={
                                        async (value)=>{
                                            handleChange("pickupLocations", index, value)
                                            let result=await getLocations(value)
                                            handleOptions("pickupLocations", index, [...result])
                                        }
                                    }
                                    size='large'
                                />
                            </Form.Item>
                            </Col>
                        )
                    }
                    <Col sm={{span:12}} xs={{span:24}}>
                        <Form.Item
                            label={
                                <Text strong>Drop-off location</Text>
                            }
                            >
                            <AutoComplete
                                options={options?.dropOffLocation}
                                value={state?.dropOffLocation || ''}
                                onSelect={(value)=>{
                                    handleChange("dropOffLocation", -1, value) 
                                }}
                                onChange={
                                    async (value)=>{
                                        handleChange("dropOffLocation", -1, value)
                                    let result=await getLocations(value)
                                        handleOptions("dropOffLocation", -1, [...result])
                                    }
                                }
                                size='large'
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <div style={{width:'230px'}}>
                <ReplaButton title='Calculate & Pay' btn onClick={nextStep}/>
            </div>
        </Space>
    </div>
  )
}

export default Tab2