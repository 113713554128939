import './index.css'
import { Space, Typography, Row, Col, Button} from 'antd'
const {Title, Text}= Typography
const BookingInstructions=()=>{
    const state=[
        {
            title:"SELECT PLACE & DATE",
            description:"Use our online booking system to select meeting place, date & time."
        },
        {
            title:"CHOOSE YOUR CAR",
            description:"Select the best luxury vehicle for your comfort."
        },
        {
            title:"PAYMENT & RECIEVE",
            description:"Make online payment(we accept all type of credit & debit cards)."
        },
        {
            title:"ENJOY YOUR TRIP",
            description:"You will get your invoice, driver details and ride details in your email."
        }
    ]
    return (
        <>
        <div 
            style={{backgroundImage:`linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(${'/luxride/bg-steps.jpg'})`,width:'100%'}}
            className='py-5 justify-center booking-instructions'
            >
                <div className="width-93 py-5 flex-col-center">
                    <div>
                        <Title level={3} className='brand-color mb-1 text-center'>Steps</Title>
                        <Title level={1} className='mt-0 text-center text-white'>HOW IT WORKS</Title>
                        <Title level={5} className='mt-0 text-center text-white'>Make It Happens In 4 Steps</Title>
                    </div>
                </div>
        </div>
        <div 
            className='justify-center booking-instructions-next-div'
            >
                <div className="width-93 flex-col-center">
                    <Row className='steps-div' gutter={[0,16]}>
                        {
                            state?.map((data, index)=>
                            <Col 
                                lg={{span:6}} md={{span:12}} sm={{span:24}} xs={{span:24}}  
                                key={index}
                                data-aos-delay={300*(index+1)}
                                data-aos-duration={500}
                                data-aos="fade-right"
                                data-aos-once="true"
                                >
                                <div  className={(index===3?'brand-bg-blue bg-lines':'light-bg-blueish')+ " py-3 px-3"}>
                                    <Space 
                                        direction='vertical' 
                                        className='width-100 py-3 flex-col-center'
                                        >
                                        <div className='center'>
                                            <div
                                                className={'center step-number '+(index===3 && 'brand-bg')}
                                                >
                                                <Title level={2} className={(index===3 ? 'my-0 text-black':'my-0 text-white')}>
                                                    {
                                                        '0'+(index+1)
                                                    }
                                                </Title>
                                            </div>
                                        </div>
                                        <Title level={3} className={(index===3?'text-white':'')+' width-100 text-center'}>
                                            {
                                                data?.title
                                            }
                                        </Title>
                                        <p className={(index===3?'text-white':'')+' width-100 text-center'}>
                                            {
                                                data?.description
                                            }
                                        </p>
                                    </Space>
                                </div>
                            </Col>
                            )
                        }
                    </Row>
                </div>
        </div>
        </>
    )
}
export {BookingInstructions}