import React from 'react'
import LuxrideFaqs from '../../components/luxridefaqs';
import { Row, Col, Typography } from 'antd'
import { BreadCrumb } from '../../ui-elements'
const { Title, Text } = Typography;


const FAQs = () => {
  return (
    <div>
        <BreadCrumb title='FAQs' description='We Can Help You Succeed' pageName='FAQs' breadcrumb={[{to:'/',name:'Home'}]}/>
        <LuxrideFaqs/>
    </div>
  )
}

export default FAQs