import React,{ useState } from 'react'
import { Col, Row, Space, Typography, Carousel, Image } from 'antd';
import './index.css';
const {Title,Text} = Typography;


const LuxrideTestimonial = () => {
    const [dotPosition] = useState('left');
    const datatest = [
        {
            icontest:"quote.png",
            title:"Jhonathane Miller",
            job:"Web Developer",
            desc:`I really can recommend this theme,
            beacuse it's coded very well and it's 
            really easy to build your own website! I really can recommend this theme,
            beacuse it's coded very well and it's 
            really easy to build your own website!`
        },
        {
            icontest:"quote.png",
            title:"Jhonathane Miller",
            job:"Web Developer",
            desc:`I really can recommend this theme,
            beacuse it's coded very well and it's 
            really easy to build your own website! I really can recommend this theme,
            beacuse it's coded very well and it's 
            really easy to build your own website!`
        },
        {
            icontest:"quote.png",
            title:"Jhonathane Miller",
            job:"Web Developer",
            desc:`I really can recommend this theme,
            beacuse it's coded very well and it's 
            really easy to build your own website! I really can recommend this theme,
            beacuse it's coded very well and it's 
            really easy to build your own website!`
        },
        {
            icontest:"quote.png",
            title:"Jhonathane Miller",
            job:"Web Developer",
            desc:`I really can recommend this theme,
            beacuse it's coded very well and it's 
            really easy to build your own website! I really can recommend this theme,
            beacuse it's coded very well and it's 
            really easy to build your own website!`
        },
    ]

  return (
    <div className='dflex py-5 testimonials-section'
     style={{backgroundImage:`url(${'/luxride/map-location.png'})`}}
    >
        <div className='width93 py-5'>
            <Row gutter={[32,64]} style={{alignItems:"center"}}>
                <Col 
                    xs={24} sm={24} md={12} lg={12}
                    data-aos="fade-right" 
                    data-aos-duration="500" 
                    data-aos-once="true"
                    data-aos-delay={300}
                    >
                    <Carousel autoplay dotPosition={dotPosition} className='testimonialSlider'>
                        {
                            datatest?.map((datat,dt)=>
                            <div key={dt}>
                                <Space direction='vertical' size={40} style={{paddingLeft:"30px"}} className="paddRight">
                                    <Space size={25}>
                                        <div className='quoteDiv'>
                                            <Image 
                                                src={'/luxride/'+datat?.icontest} 
                                                style={{width:'30px'}}
                                                alt='epic-ride-london'
                                                preview={false}
                                                />
                                        </div>
                                        <Space size={2} direction='vertical' style={{width:"100%"}}>
                                            <Title level={4} className='text-white' style={{marginBottom:"0px"}}>
                                                {
                                                    datat?.title
                                                }
                                            </Title>
                                            <Text className='font-12 text-white'>{datat?.job}</Text>
                                        </Space>
                                    </Space>
                                    <Text className='text-white fontDesctest'>
                                        {
                                            datat?.desc
                                        }
                                    </Text>
                                </Space>
                            </div>
                            )
                        }
                    </Carousel>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <div
                        data-aos="fade-up" 
                        data-aos-duration="500" 
                        data-aos-once="true"
                        data-aos-delay={300}
                        className='ailgnend'
                    >
                        <Image 
                            src='/luxride/mercedes.png' 
                            alt='epic-ride-london'
                            preview={false}
                            />
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default LuxrideTestimonial