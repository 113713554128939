import React from 'react'
import Slider from 'react-slick'
import { SectionTop } from '../../ui-elements'
import './index.css'

const BusinessPartner = () => {
    const brand = [
        {
            brandimg: '/Shell-Logo.png',
        },
        {
            brandimg: '/Emirates-Logo.png',
        },
        {
            brandimg: '/big-yellow.png',
        },
        {
            brandimg: '/London_City_Airport_logo.png',
        },
        {
            brandimg: '/purple-heathrow-logo.png',
        },
        {
            brandimg: '/NHS_logo_PNG2.png',
        },
        {
            brandimg:'/2560px-MAG_London_Stansted_Airport_logo.png'
        }
    ]

    var businesspartner = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay:true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 786,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          }
        ]
      }


  return (
    <div className='justify-center py-5'>
        <div className='width-93'>
            <SectionTop 
              title="let's work" 
              description="Our business partners"
              description2="We are always here to help you"
              />
            <div>
                <Slider {...businesspartner} style={{align:'items-center'}}>
                    {
                        brand?.map((brand, b)=>
                            <div 
                                key={'brand-logo--'+b}
                                className='px-3 brandimg' >
                                <img 
                                  src={"luxride"+brand?.brandimg} 
                                  alt="epic-ride-london"
                                  />
                            </div>
                        )
                    }
                </Slider>
            </div>
        </div>
    </div>
  )
}

export default BusinessPartner