import React from 'react'
import { Col, Row, Typography, Space, Image } from 'antd'

const { Title } = Typography;

export const LongDistanceChauffeurServices = () => {
  return (
    <div className='py-5 justify-center'>
        <div className='width-93'>
            <Row gutter={[32,32]}>
                <Col lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}}>
                    <Space direction='vertical' size={30}>
                        <div>
                            <Title 
                                level={4} 
                                className='brand-color'
                                data-aos="fade-down"
                                data-aos-duration="800" 
                                data-aos-once="true"
                                data-aos-delay="300"
                                >
                                EXECUTIVE CHAUFFEUR SERVICE COMPANY
                            </Title>
                            <Title 
                                level={1} 
                                className='my-0'
                                data-aos="fade-up-right"
                                data-aos-duration="800" 
                                data-aos-once="true"
                                data-aos-delay="300"
                                >
                                Long-Distance Chauffeur Services:
                            </Title>
                        </div>
                        <Space 
                            direction='vertical'
                            data-aos="fade-right"
                            data-aos-duration="800" 
                            data-aos-once="true"
                            data-aos-delay="300"
                            >
                            <p>
                                Embarking on a long-distance journey can be both exciting and demanding. Our long-distance chauffeur services are tailored to provide you with a comfortable and luxurious travel experience, whether you're traveling for business or pleasure.
                            </p>
                            <p>
                                Our professional chauffeurs are experienced in long-haul travel, ensuring your safety and comfort throughout the entire journey. They are well-versed in the best routes, traffic patterns, and rest stops, allowing you to relax and enjoy the trip without the stress of navigation.
                            </p>
                            <p>
                                With our fleet of meticulously maintained vehicles, including spacious sedans and SUVs, we prioritize your comfort during long journeys. Each vehicle is equipped with modern amenities, ensuring a pleasant and enjoyable ride, no matter the distance.
                            </p>
                            <p>
                                We understand the importance of punctuality for long-distance travel. Our chauffeurs are committed to timeliness, ensuring that you reach your destination on schedule, allowing you to make the most of your time.
                            </p>
                            <p>
                                Experience the convenience and luxury of our long-distance chauffeur services. Sit back, unwind, and let us handle the road, while you enjoy a smooth and hassle-free journey to your destination.
                            </p>
                        </Space>
                    </Space>
                </Col>
                <Col 
                    lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}} 
                    data-aos="fade-left"
                    data-aos-duration="800" 
                    data-aos-once="true"
                    data-aos-delay="300"
                    >
                    <div style={{height:"100%", width:'100%'}} className='brand-bg-blue p-3 py-5 center'>
                        <Image 
                            src='/luxride/Long-Distance Chauffeur Services.jpg' 
                            alt='epic-ride-london' 
                            className="img" 
                            width={'100%'} 
                            preview={false}
                            data-aos="zoom-in"
                            data-aos-duration="800" 
                            data-aos-once="true"
                            data-aos-delay="300"
                            />
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}