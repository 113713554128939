const getLocations=(location)=>{
    var requestOptions = {
      method:'get'
    }
    return (
      fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${location}.json?access_token=pk.eyJ1IjoiYWJkdWxoYW5hbjE5OTgiLCJhIjoiY2xjaHpvMXprMDI0ZTNxbW1zaXhwczUycCJ9.15PM9FLDUYf9vGrz9uZ0xg`,requestOptions)
      .then(response=>response.json())
      .then(result=>{
         console.log(result)
         if(result)
         {
            console.log([...result?.features?.map(location=>({value:location?.place_name}))])
            return [...result?.features?.map(location=>(
                    {
                        value:location?.place_name, 
                        coordinates: location?.geometry?.coordinates
                    }
                ))
            ]
         }
         else return []
      })
      .catch((error)=>{return []})
    )
  }
const distanceMatrix=(coordinates)=>{
  var requestOptions = {
    method:'get'
  }
  return (
    fetch(`https://api.mapbox.com/directions-matrix/v1/mapbox/driving/${coordinates}?annotations=distance,duration&access_token=pk.eyJ1IjoiYWJkdWxoYW5hbjE5OTgiLCJhIjoiY2xjaHpvMXprMDI0ZTNxbW1zaXhwczUycCJ9.15PM9FLDUYf9vGrz9uZ0xg`,requestOptions)
    .then(response=>response.json())
    .then(result=>{
       if(result)
       {
          return result
       }
       else 
       return 0
    })
    .catch((error)=>{return 0})
  )
}
export  {getLocations, distanceMatrix}