import React from 'react'
import { Row, Col, Space, Image, Typography } from 'antd';
import "./index.css"
const { Title, Text } = Typography;

const LuxrideFourSec = () => {
  const data=[
    {
        title:'Our Fleet',
        description:'We have big number of Luxury cars Available all the time for our valued customer.',
        img:'/luxride/car.png'
    },
    {
        title:'Professional Chauffeur',
        description:'We have empowered a number of professional chauffeurs to get a reliable source of income.',
        img:'/luxride/chauffeur.png'
    },
    {
        title:'Pre-Booking',
        description:'Schedule your ride by your own choice to have a comfortable and memorable journey.',
        img:'/luxride/booking-1.png'
    },
    {
        title:'Online Payment',
        description:'Different payment methods are available for your comfort either you can pay using card.',
        img:'/luxride/payment.png'
    },
  ]
  return (
    <div className='py-5 justify-center four-section'
    style={{backgroundImage:`linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(${'/luxride/car-top.png'})`}}
    >
        <div style={{width:'93%'}} className='py-5'>
            <Row gutter={[32,32]} justify='center'>
                {
                    data?.map((obj, o)=>
                    <Col 
                        xs={24} sm={24} md={12} lg={12} xl={6} 
                        key={o}
                        data-aos-delay={300*(o+1)}
                        data-aos="fade-up-right"
                        data-aos-duration="500" 
                        data-aos-once="true"
                        >
                        <Space direction='vertical' size={20} style={{padding:"20px",textAlign:"center"}}>
                            <Image 
                                src={obj?.img} 
                                alt='epic-ride-london'
                                style={{width:'64px'}}
                                />
                            <Space direction='vertical'>
                                <Title level={4} className='brand-color'>
                                    {
                                        obj?.title
                                    }
                                </Title>
                                <Text className='text-white' strong>
                                    {
                                        obj?.description
                                    }
                                </Text>
                            </Space>
                        </Space>
                    </Col>
                    )
                }
            </Row>
        </div>
    </div>
  )
}

export default LuxrideFourSec