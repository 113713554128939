import { useEffect, useState } from "react";
import {Row, Col, Steps, Space, Typography, Button} from "antd"
import LuxrideSidebar from "../../components/luxridesteps/LuxrideSidebar";
import ListedCars from "./listedCars";
import LuxrideLastForm from "../../components/luxridesteps/LuxrideLastForm";
import LuxrideTabsForm from "../../components/luxridetabsform";
import "./index.css"
import MapContainer from "../map/mapContainer"
import { Checkout } from "../../components/luxridesteps";
import { useSelector } from "react-redux";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
const {Text}= Typography

const BookingSteps=()=>{
    const {epicRide}= useSelector(state=>state)
    const [current, setCurrent] = useState(1);
    useEffect(()=>{
        window.scrollTo({
            top: 153,
            behavior: 'smooth',
        })
    },[current])
   
    const next = () => {
        setCurrent(current + 1)
    }
    const prev = () => {
        setCurrent(current - 1)
    }
    const steps = [
        {
          title: 'Enters Ride Details',
          content: 
          <Row gutter={[16,32]} style={{width:'100%',margin:0}}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div>
                        <LuxrideTabsForm lightGreyBg={true}/>
                    </div>
                </Col>
                <Col xs={20} sm={20} md={12} lg={12} xl={12}>
                    <MapContainer height='auto'/>
                </Col>
            </Row>
            ,
        },
        {
          title: 'Choose a Vehicle',
          content: 
          <Row gutter={[24,24]} style={{width:'100%',margin:0}} >
            <Col xs={24} sm={24} md={24} lg={7} xl={5}>
                <LuxrideSidebar />
            </Col>
            <Col xs={24} sm={24} md={24} lg={17} xl={19}>
                <ListedCars />
            </Col>
          </Row>
          ,
        },
        {
          title: 'Enter Contact Details',
          content: 
            <Row gutter={24} style={{width:'100%', margin:0}}>
                <Col xs={24} sm={24} md={24} lg={7} xl={5} className="orderClass">
                    <LuxrideSidebar />
                </Col>
                <Col xs={24} sm={24} md={24} lg={17} xl={19}>
                    <LuxrideLastForm next={next}/>
                </Col>
            </Row>
          ,
        },
        {
            title: 'Checkout',
            content: 
                <Row gutter={24} style={{width:'100%', margin:0}}>
                    <Col xs={24} sm={24} md={24} lg={7} xl={5} className="orderClass">
                        <LuxrideSidebar />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={17} xl={19}>
                        <Checkout />
                    </Col>
                </Row>
        },
      ]
    const items = steps.map((item,i) => ({
        key: item?.title,
        title: <Text strong>{item?.title}</Text>,
    }));
    return (
        <Space 
            direction='vertical' 
            size={50} 
            className="py-5" 
            style={{width:'100%'}}
            id='details-box'
            >
            <Steps 
                progressDot
                current={current} 
                items={items} 
                labelPlacement="vertical" 
                />
            <div style={{width:'100%'}}>
                {
                    steps[current]?.content
                }
            </div>
            <div className="justify-end" style={{width:'100%'}}>
                <Space>
                    {
                        current>0 &&
                        <Button 
                            size="large" 
                            shape="round"
                            icon={<LeftOutlined className='up'/>}
                            onClick={prev}
                            >
                            {
                                current===1?'CHOOSE RIDE DETAILS': 
                                current===2?'CHOOSE A VEHICLE': 
                                'BACK'
                            }
                        </Button>
                    }
                    {
                        current<2 &&
                        <Button 
                            type="primary" 
                            size="large" 
                            shape="round"
                            onClick={next}
                            disabled={current===1 && !epicRide?.vehicle?.vechicleID}
                            >
                            <Space>
                                {
                                    !current?'CHOOSE A VEHICLE':
                                    current===1?'ENTER CONTACT DETAILS':
                                    current===2?'BOOK NOW':'NEXT'
                                }
                                <RightOutlined className='up'/>
                            </Space>
                        </Button>
                    }
                </Space>
            </div>
        </Space>
    )
}
export default BookingSteps