import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Alert } from '../../../../ui-elements';
import { domainUrl } from '../../../constants/apiconstans/domanUrl';
export const loadDashboard = createAsyncThunk('epicRide',
    async (data, { dispatch, getState }) => {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        //dispatch(gettingDashboard())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${userToken}`);
        myHeaders.append("Content-Type", "application/json")
        var requestOptions = {
            method: 'get',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(domainUrl + `/index`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {

                }
                else {
                    // dispatch(dashboardClear())
                    throw 'error'
                }
            })
            .catch(error => { Alert("error", "error while loading Dashboard") })
    }
)
const EpicRideSlice = createSlice({
    name: 'epicRide',
    initialState: {
        serviceType: 1,
        pickupDate: null,
        pickupTime: null,
        travelMode: 1,
        Distance: '',
        Duration: '',
        OrderTotalAmount: '',
        toPay: '',
        VehicleName: '',
        BagCount: 0,
        PassengersCount: 0,
        vechicleID: null,
        vehicleAmount: 0,
        vehicle:{
            vechicleID: null,
            VehicleName:'',
            vehicleAmount: 0,
            BagCount: 0,
            TotalBagCount:0,
            PassengersCount: 0,
            TotalPassengersCount:0,
        },
        wayTo: {
            pickupLocation: '',
            wayPoints: [],
            dropOffLocation: '',
            hours: 0
        },
        return: {
            pickupLocation: '',
            wayPoints: [],
            dropOffLocation: '',
            hours:0
        },
        options: {
            wayTo:{
                pickupLocations:[''],
                dropOffLocation:''
            },
            return: {
                pickupLocations:[''],
                dropOffLocation:''
            }
        },
        extras: {
            childSeat: {
                price: 25.00,
                quantity: 0,
                maxQuantity: 2
            },
            bouquet: {
                price: 85.00,
                quantity: 0,
                maxQuantity: 10,
            },
            champagne: {
                price: 115.00,
                quantity: 0,
                maxQuantity: 4,
            }
        },
        extrasAmount: 0,
    },
    reducers: {
        updateEpicRide: (state, action) => {
            // state.Duration=action?.payload?.Duration
            state.pickupDate = action?.payload?.pickupDate;
            state.pickupTime = action?.payload?.pickupTime;
            state.travelMode= action?.payload.travelMode;
            state.wayTo = action?.payload?.wayTo;
            state.return = action?.payload.travelMode===1?state?.return :action?.payload?.return;
            state.Distance = action?.payload?.totalDistance;
            state.Duration = action?.payload?.totalTime;
            state.options = action?.payload?.options
        },
        updateEpicRideVehicle: (state, action) => {
            state.vehicle={
                ...state?.vehicle,
                ...action?.payload
            }
        },
        updateServiceType: (state, action) => {
            state.serviceType = action?.payload
        },
        updateExtras: (state, action) => {
            state.extras = action?.payload
            let extras = action?.payload
            let extrasAmount = 0
            for (let key in extras)
                extrasAmount += extras[key]?.price * extras[key]?.quantity
            state.extrasAmount = extrasAmount

        }
    },
})
export const { updateEpicRide, updateEpicRideVehicle, updateServiceType, updateExtras } = EpicRideSlice.actions;
export default EpicRideSlice.reducer;