import React, { useState } from 'react'
import { Row, Col, Typography, Image, Space } from 'antd'
import './luxridesoutham.css'

const { Title, Text } = Typography;

const LuxrideSouthPremium = () => {

    const [hovers, setHovers] = useState(false)
    const [hoverstwo, setHoverstwo] = useState(true)
    const [hoversthree, setHoversThree] = useState(false)
    const [hoverfour, setHoverFour] = useState(true)
    const [hoverfive, setHoverFive] = useState(false)
    const [hoversix, setHoverSix] = useState(false)
    const [activeclass, setActiveClass] = useState(true)
    const [activeclassc, setActiveClassC] = useState(true)

    const hoveron = () =>{
        setHovers(true)      
        setHoverstwo(false)
        setActiveClass(false)
    }

    const hoveroff = () =>{
        setHovers(false)
        setHoverstwo(true)
        setActiveClass(true)
    }

    const hoverontwo = () =>{  
        setActiveClass(true)
    }

    const hoveronthree = () =>{  
        setHoversThree(true)
        setHoverstwo(false)
        setActiveClass(false)
    }
    const hoveroffthree = () =>{  
        setHoversThree(false)
        setHoverstwo(true)
        setActiveClass(true)
    }


    const hoveronfour = () =>{  
        setHoverFour(true)
        setActiveClassC(true)
    }
   
    const hoveronfive = () =>{ 
        setHoverFive(true) 
        setHoverFour(false)
        setActiveClassC(false)
    }
    const hoverofffive = () =>{  
        setHoverFive(false) 
        setHoverFour(true)
        setActiveClassC(true)
    }

    const hoveronsix = () =>{ 
        setHoverSix(true) 
        setHoverFour(false)
        setActiveClassC(false)
    }
    const hoveroffsix = () =>{  
        setHoverSix(false) 
        setHoverFour(true)
        setActiveClassC(true)
    }


  return (
    <div className='dflex' style={{padding:"30px",marginTop:"30px"}}>
        <div className='width93'>
            <Row>
                <Col span={24}>
                    <Space direction='vertical' style={{textAlign:"center",width:"100%",marginBottom:"30px"}}>
                        <Title level={2} className='text-white'>Premium Transfer Services Especially for Southampton</Title>
                    </Space>
                </Col>
            </Row>
            <div className='wrapper'>
                <div className='col'>
                    <div className='ex-show ex-2' onMouseOver={hoveron} onMouseOut={hoveroff}>
                        <Image 
                            src='/luxride/prem-1.jpg' 
                            className='imgex imhov' 
                            alt='epic-ride-london'
                            preview={false}
                            />
                        <div className='textover-ex-1'>
                            {
                                hovers &&
                                <div>                            
                                    <Title level={1} className='text-white'>Southampton to Heathrow - Airport Service</Title>
                                    <Text className='text-white'>
                                        Travelling from Southampton to Heathrow can be a tough ask. Particularly, if you are travelling to or from the airport with some luggage, the time is short and it is hard to pick the right transfers. Going via train can take about two to two and a half hour. However, with our luxury transfer service, you can cover the distance in an hour and ten to fifteen minutes.
                                    </Text>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className={activeclass? 'col col-active': 'col'} onMouseOver={hoverontwo}>
                    <div className='ex-show ex-2' >
                        <Image 
                            src='/luxride/prem-2.jpg' 
                            alt='epic-ride-london'
                            preview={false}
                            className='imgex imhov' 
                            />                        
                        <div>   
                            {
                                hoverstwo &&                              
                                <div className='textover-ex-1 showe'>
                                    <Title level={1} className='text-white'>Southampton to Gatwick Transfers</Title>
                                    <Text className='text-white'>
                                        We provide you top notch cars and make your journey comfortable with our luxury Mercedes. The E and S class Mercedes are perfect if you are travelling solo and have some luggage or if you are a few people with minimal baggage. However, for bigger families, you can consider travelling in our Mercedes V class. It is comfortable and can provide you enough space to fit all your baggage.
                                    </Text>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className='col' onMouseOver={hoveronthree} onMouseOut={hoveroffthree}>
                    <div className='ex-show ex-2'>
                        <Image 
                            src='/luxride/exc-1-1.jpg' 
                            alt='epic-ride-london'
                            preview={false}
                            className='imgex imhov' 
                            />
                        <div className='textover-ex-1'>
                            {
                            hoversthree &&
                                <div>
                                    <Title level={1} className='text-white'>Southampton to Stansted Airport</Title>
                                    <Text className='text-white'>
                                        The journey from Southampton to Stansted is about a hundred and twenty to a hundred and twenty-five miles. So, we put all our efforts to make it comfortable for you. A top notch professional chauffeur will do all that can be done to make your journey comfortable. It takes you about three hours and a lot of hassle if you go via train but with us you can reach there in about two and a half hours. 
                                    </Text>
                                </div>
                            }
                        </div>
                        
                    </div>
                </div>
            </div>


            <div className='wrapper'>
                <div className={activeclassc? 'col col-active cs-hover': 'col cs-hover'} onMouseOver={hoveronfour}>
                    <div className='ex-show ex-2' >
                        <Image 
                            src='/luxride/exc-1.jpg' 
                            alt='epic-ride-london'
                            preview={false}
                            className='imgex imhov' 
                            />
                        <div>
                            {
                                hoverfour && 
                                <div className='textover-ex-1 showe'>                                
                                    <Title level={1} className='text-white'>Southampton to Luton – Airport Travel</Title>
                                    <Text className='text-white'>
                                        The average bus duration form Southampton to Luton is nearly six hours whereas the train takes between two and a half to three hours. With us, the journey only takes under two hours. Our chauffeur will receive you at the pickup point, greet you with a smile, assist you with your baggage, and will take you to your destination as if you never left your comfortable couch.
                                    </Text>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className='col'>
                    <div className='ex-show ex-2' onMouseOver={hoveronfive} onMouseOut={hoverofffive}>
                        <Image 
                            src='/luxride/prem-3.jpg' 
                            alt='epic-ride-london'
                            preview={false}
                            className='imgex imhov' 
                            />
                        <div  className='textover-ex-1 '>
                            {
                                hoverfive &&
                                <div>                            
                                    <Title level={1} className='text-white'>Executive, Business and VIP Movements From and To Airports and Hotels</Title>
                                    <Text className='text-white'>
                                        Executive transfers can be a nightmare for many managers. It is hard to provide the luxury and comfort that VIPs seek and we have got it all covered for you. With years of experience dealing with VIP movements, we are certain to exceed your expectations when it comes to VIP transfers in Southampton. We take care of the meet and greet, receiving your guests from the airport and transferring them to the hotel and vice versa.
                                    </Text>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                
                <div className='col' onMouseOver={hoveronsix} onMouseOut={hoveroffsix}>
                    <div className='ex-show ex-2'>
                        <Image 
                            src='/luxride/prem-4.jpg' 
                            alt='epic-ride-london'
                            preview={false}
                            className='imgex imhov' 
                            />
                        <div className='textover-ex-1'>
                            {
                                hoversix &&
                                <div>
                                    <Title level={1} className='text-white'>Wedding and Events Coverage</Title>
                                    <Text className='text-white'>
                                        Whether you want to hire a chauffeur for prom in Southampton or you are invited as a guest at another event, we are here to help you. We believe that a good ride can create a great impact and we are here to help you capture the attendees.
                                    </Text>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default LuxrideSouthPremium