import React from 'react'
import { Col, Row, Typography, Space, Image } from 'antd'

const { Title } = Typography

export const WeddingTransferServices = () => {
  return (
    <div className='py-5 justify-center'>
        <div className='width-93'>
            <Row gutter={[32,32]}>
                <Col lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}}>
                    <Space direction='vertical' size={30}>
                        <div>
                            <Title 
                                level={4} 
                                className='brand-color'
                                data-aos="fade-down"
                                data-aos-duration="800" 
                                data-aos-once="true"
                                data-aos-delay="300"
                                >
                                EXECUTIVE CHAUFFEUR SERVICE COMPANY
                            </Title>
                            <Title 
                                level={1} 
                                className='my-0'
                                data-aos="fade-up-right"
                                data-aos-duration="800" 
                                data-aos-once="true"
                                data-aos-delay="300"
                                >
                                Wedding Transfer Services:
                            </Title>
                        </div>
                        <Space 
                            direction='vertical'
                            data-aos="fade-right"
                            data-aos-duration="800" 
                            data-aos-once="true"
                            data-aos-delay="300"
                            >
                            <p>
                                On your special day, let us take care of your transportation needs with our dedicated wedding transfer services. We understand that every detail matters when it comes to your wedding, including arriving in style and making a grand entrance.
                            </p>
                            <p>
                                Our professional chauffeurs are experienced in providing impeccable service for weddings. They will ensure that you and your wedding party are transported with utmost care and elegance, creating a seamless and memorable experience.
                            </p>
                            <p>
                                We offer a fleet of luxurious vehicles that are perfect for your wedding day. From elegant limousines to spacious SUVs, each vehicle is meticulously maintained and decorated to match the ambiance of your wedding. Our vehicles are equipped with amenities to ensure your comfort and enjoyment throughout the journey.
                            </p>
                            <p>
                                With our wedding transfer services, you can relax and focus on your special day, knowing that your transportation needs are in capable hands. Let us add a touch of sophistication and elegance to your wedding, making it an unforgettable event from start to finish.
                            </p>
                        </Space>
                    </Space>
                </Col>
                <Col 
                    lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}} 
                    data-aos="fade-left"
                    data-aos-duration="800" 
                    data-aos-once="true"
                    data-aos-delay="300"
                    >
                    <div style={{height:"100%", width:'100%'}} className='brand-bg-blue p-3 py-5 center'>
                        <Image 
                            src='/luxride/Wedding Transfer Services.jpeg' 
                            alt='epic-ride-london' 
                            className="img" 
                            width={'100%'} 
                            preview={false}
                            data-aos="zoom-in"
                            data-aos-duration="800" 
                            data-aos-once="true"
                            data-aos-delay="300"
                            />
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}