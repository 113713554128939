import { BreadCrumb } from '../../ui-elements';
import ReplaButton from '../../components/buttons';
import { Result } from 'antd';
export default function ConfirmPayment() {
  return (
    <div className='flex-col-center stripe'>
        <BreadCrumb 
            title='Success' 
            description='' 
            pageName='Success' 
            breadcrumb={
                [
                    {to:'/',name:'Home'}
                ]
            }
            />
        <div style={{width:'93%'}} className='py-5'>
            <Result
                status="success"
                title="Payment succeeded!"
                subTitle="we will mail you shortly."
                extra={[
                <div style={{width:'300px'}}>
                    <ReplaButton title='Home' to='/'/>
                </div>
                ]}
            />
        </div>
    </div>
  );
};