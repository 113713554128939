import {useParams } from 'react-router-dom';
import { BreadCrumb } from "../../ui-elements"
import { ServiceComponent2, CarsGroup, LuxrideLastestnews} from "../../components"
import { 
    AirportTransferServices, 
    EventTransferServices,
    ExecutiveTransferServices,
    LongDistanceChauffeurServices,
    LondonSightseeingServices,
    PrivateAviationServices,
    SouthamptonExecutiveTransferServices,
    WeddingTransferServices
} from "../../components/ServicesComponents"
const Services=()=>{
    const {service}=useParams()
    return (
        <div>
            <BreadCrumb 
                title='Service' 
                pageName='Epic Services' 
                breadcrumb={[{to:'/',name:'Home'}]}
                />
            {
                service==='airport-transfer-services'?
                    <AirportTransferServices/>:
                service==='event-transfer-services'?
                    <EventTransferServices/>:   
                service==='executive-transfer-services'?
                    <ExecutiveTransferServices/>:   
                service==='long-distance-chauffeur-services'?
                    <LongDistanceChauffeurServices/>:   
                service==='london-sightseeing-services'?
                    <LondonSightseeingServices/>:   
                service==='private-aviation-services'?
                    <PrivateAviationServices/>: 
                service==='southampton-executive-transfer-services'?
                    <SouthamptonExecutiveTransferServices/>:   
                <WeddingTransferServices/>
            }
            <ServiceComponent2/>
            <LuxrideLastestnews/>
            <CarsGroup/>
        </div>
    )
}
export default Services