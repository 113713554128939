import React from 'react'
import { Row, Col, Space, Typography, Image } from 'antd'
const { Title, Text } = Typography;

const LuxrideSouthmain = () => {
  return (
    <div className='dflex'>
        <div className='width93'>
            <Row gutter={[64,24]} style={{alignItems:"center"}}>
                <Col xs={24} sm={24} md={20} lg={12}>
                    <Space direction='vertical' size={20}>
                      <Title level={2} className='text-white'>We Provide The Luxury You Deserve</Title>
                      <Text className='text-white'>
                        <span className='text-primary'>Epic Chauffeurs</span> aims at delivering top notch <span className='text-primary'>VIP transfer services</span>. With our highly trained, experienced chauffeurs and luxury vehicles, we cover <span className='text-primary'>proms</span>, <span className='text-primary'>parties</span>, <span className='text-primary'>weddings</span>, <span className='text-primary'>airport transfers</span>, <span className='text-primary'>business journeys</span>, and <span className='text-primary'>VIP movements</span>.
                      </Text>
                      <Text className='text-white'>
                        Pegasus Chauffeurs is where premium transfers meet the affordability. Unlike other luxury movement services, we keep our pricing reasonable to assure that everyone gets to taste the lavishness and comfort of an exceptional <span className='text-primary'>transfer service in Southampton</span> and its surroundings.
                      </Text>
                      <Text className='text-white'>
                        Our highly <span className='text-primary'>professional drivers</span> with <span className='text-primary'>licenses</span> and <span className='text-primary'>insurances</span> in place, focusing on VIP protocol and luxury and complementary additions to the service, you will definitely have a memorable journey.
                      </Text>
                    </Space>
                </Col>
                <Col xs={24} sm={24} md={20} lg={12}>
                    <Image 
                      src='/luxride/11.jpg' 
                      alt='epic-ride-london'
                      preview={false}
                      style={{borderRadius:"12px"}} 
                      />
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default LuxrideSouthmain