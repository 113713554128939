import React from 'react'
import { Row, Col, Typography, Space, Image} from 'antd'
import Slider from 'react-slick'
import './index.css'

const { Title, Text } = Typography;
const TrustPilot = () => {

    const reviews = [
        {
            title:"Best on the market",
            description:'Was on time and got me to where I needed to be quickly and carefully. I was asked if I was okay with the temperature of the car',
            reveiwBy:'Worldtravler',
            date:'2 days ago',
            rating:'stars-4.5.svg'
        },
        {
            title:"Such a great experience",
            description:'Such a great experience, with chocolates, water. Very friendly and polite driver. Friendly, comfortable Bentley!!!!',
            reveiwBy:'John Grey',
            date:'week ago',
            rating:'stars-5.svg'
        },
        {
            title:"Comfortable luxury minivan",
            description:'We were delighted and very relieved to be able to book this transfer from Heathrow all the way to Edinburgh at short notice after our train was cancelled',
            reveiwBy:'kate mahon',
            date:'1 month ago',
            rating:'stars-5.svg'
        },
        {
            title:"Absolutely amazing service",
            description:'Easy to book online, kept informed all the way through. Driver arrived 15 minutes early. Picked up from London Heathrow and driven home to Chorley',
            reveiwBy:'Carle Babb',
            date:'2 months ago',
            rating:'stars-4.5.svg'
        },
        {
            title:"Best on the market",
            description:'Got a transfer from LHR to Manchester directly with epic. Price was reasonable for a luxury transfer. Our driver met us in the airport and was really helpful.',
            reveiwBy:'Aimee faley',
            date:'2 month ago',
            rating:'stars-5.svg'
        },
    ]

    var businesspartner = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        autoplay:true,
        responsive: [
          {
            breakpoint: 1150,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true
            }
          },
          {
            breakpoint: 976,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true
            }
          },
          {
            breakpoint: 786,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true
            }
          },
          {
            breakpoint: 619,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true
            }
          }
        ]
      }


  return (
    <div className='justify-center py-5 light-bg'>
        <div style={{width:'93%'}}>
            <div 
                className={'py-3 flex-col-center'}
                data-aos="fade-down"
                data-aos-delay={300}
                data-aos-duration={500}
                >
                    <Image 
                        src='/luxride/trust-pilot.png' 
                        style={{width:'150px'}}
                        alt='epic-ride-london'
                        preview={false}
                        />
                    <Title level={3}>
                        Customer Reviews
                    </Title>
            </div>    
            <Row 
                gutter={[16,16]}
                >
                <Col 
                    xl={{span:6}} lg={{span:8}} md={{span:10}} sm={{span:24}} xs={{span:24}}
                    data-aos="fade-right"
                    data-aos-delay={300}
                    data-aos-duration={500}
                    >
                    <div>
                        <Space direction='vertical' className='bg-white px-3 py-4 w-100 flex-col-center'>
                            <Title level={3} className='mb-1'>
                                Excellent
                            </Title>
                            <div className='center'>
                                <img 
                                    src={"/luxride/stars-5.svg"}
                                    alt='epic-ride-london'
                                    style={{width:'130px'}}
                                    />
                            </div>
                            <p 
                                className='w-100 mb-0' 
                                >
                                Based on <Text strong> 456 reviews</Text>
                            </p>
                            <div className='center'>
                                <Image 
                                    src='/luxride/trust-pilot.png' 
                                    style={{width:'100px'}}
                                    alt='epic-ride-london'
                                    preview={false}
                                    />
                            </div>
                        </Space>
                    </div>
                </Col>
                <Col 
                    xl={{span:18}} lg={{span:16}} md={{span:14}} sm={{span:24}} xs={{span:24}} 
                    className='align-center'
                    data-aos="fade-left"
                    data-aos-delay={300}
                    data-aos-duration={500}
                    >  
                    <div className='width-100'>
                        <Slider {...businesspartner}>
                            {
                                reviews?.map((review, index)=>
                                    <div 
                                        className={index===reviews?.length-1?'':'pe-3'}
                                        key={'trustPilot-review'+index}
                                        >
                                        <Space direction='vertical' className='bg-white px-3 py-4 w-100'>
                                            <div className='space-between'>
                                                <img 
                                                    src={"/luxride/"+ review?.rating}
                                                    alt='epic-ride-london'
                                                    style={{width:'130px'}}
                                                    />
                                                <Text strong>
                                                    {
                                                        review?.date
                                                    }
                                                </Text>
                                            </div>
                                            <Title level={5} className='mb-1'>
                                                {
                                                    review?.title
                                                }
                                            </Title>
                                            <Text 
                                                className='w-100' 
                                                style={{textAlign:'justify'}}
                                                ellipsis={{
                                                        rows: 2,
                                                        tooltip: review?.description
                                                        }
                                                }
                                                >
                                                {
                                                    review?.description
                                                }
                                            </Text>
                                            <Text strong className='mb-0'>
                                                {
                                                    review?.reveiwBy
                                                }
                                            </Text>
                                        </Space>
                                    </div>
                                )
                            }
                        </Slider>
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default TrustPilot