import React from 'react'
import { Row, Col, Space, Typography } from 'antd';
import Typewriter from 'typewriter-effect';


const { Title, Text } = Typography;

const LuxrideSouthamworkout = () => {
  return (
    <div className='dflex' style={{paddingTop:"30px"}}>
        <div className='width93'>
            <Row gutter={[32,32]}>
                <Col span={24}>
                    <Space size={0} direction='vertical' style={{width:"100%",textAlign:"center"}}>
                        <Title level={2} className='text-white'>What makes us stand out?</Title>
                        <Text className='text-white'>
                            We believe in doing extra for our passengers and we treat them like our family. The focus is all on providing professional and top notch Southampton transfer services. We do our best to make your journey a valuable lifetime experience.
                        </Text>
                    </Space>
                </Col>
                <Col span={24}>
                    <Space style={{width:"100%",justifyContent:"center"}}>
                        <Title level={2} className='text-primary'>
                            <Typewriter
                                options={{
                                    strings: [
                                        'Customer Care Service Available 24/7',
                                        'Professional services customized to suite your needs',
                                        'Select the chauffeur you required', 
                                        'Multiple bookings at a time'],
                                    autoStart: true,
                                    loop: true,   
                                    delay: 75,                                 
                                    }}
                                />
                        </Title>
                    </Space>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default LuxrideSouthamworkout