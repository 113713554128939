import React from 'react'
import { Row, Col, Typography, Space } from 'antd'
import './luxridesoutham.css'

const { Title, Text } = Typography;

const LuxrideSouthquote = () => {
  return (
    <div className='dflex bg-quote-south'>
        <div className='width93'>
            <Row>
                <Col span={24}>
                    <Space direction='vertical' size={0}>
                        <Title level={1} className='text-white'>Special Quotes Are On! Get Yours Now</Title>
                        <Text className='text-white'>The executive business and VIP movements are on with special discounts!</Text>
                    </Space>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default LuxrideSouthquote