import React from 'react'
import { Col, Row, Typography, Space, Image } from 'antd'

const { Title } = Typography;

export const ServiceComponent1 = () => {
  return (
    <div className='py-5 justify-center'>
        <div className='width-93'>
            <Row gutter={[32,32]}>
                <Col lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}}>
                    <Space direction='vertical' size={30}>
                        <div>
                            <Title level={3} className='brand-color'>EXECUTIVE CHAUFFEUR SERIVE COMPANY</Title>
                            <Title level={1} className='my-0'>
                                CORPORATE CHAUFFEUR CAR HIRE SERVICES
                            </Title>
                        </div>
                        <Space direction='vertical'>
                            <p>Making travel arrangements for pleasure or business, whether it involves a last-minute booking or a request for advance notice, can be highly stressful. With the help of Epic Chauffeur, you can be guaranteed to travel in comfort and to arrive at your destination on time and in style after a comfortable and hassle-free ride.</p>
                            <p>
                                Various high-end executive vehicles and SUVs are available from <a href='/'>Epic Ride London</a>. Every car in our fleet has air conditioning and a soft, beautiful leather inside to give our passengers the most comfortable and opulent ride possible.
                            </p>
                            <p>
                                To provide the highest level of comfort, performance, and dependability, every vehicle in our fleet is immaculately furnished, and each one is routinely valeted, serviced, and maintained by our personnel in accordance with the manufacturers’ requirements.
                            </p>
                        </Space>
                    </Space>
                </Col>
                <Col lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}} >
                    <div style={{height:"100%", width:'100%'}} className='brand-bg-blue p-3 py-5 center'>
                        <Image 
                            src='/luxride/carporate-1.jpg' 
                            alt='epic ride london' 
                            className="img" 
                            width={'100%'} 
                            preview={false}
                            />
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}