import LuxrideTabsForm from '../luxridetabsform'
import {Row,Col,Typography, Space, Image} from  'antd'
import  "./index.css"
import ReplaButton from '../buttons';
const {Title, Text}=Typography;
const HomeTopSection =()=>
{
    return(
        <div>
            <div  className='home-slider'>
                    <div 
                        className='slider-bg' 
                        style={
                            {
                                backgroundImage:`linear-gradient(rgba(1,1,1,0.8),rgba(1,1,1,0.8)),url(/luxride/slider-bg-1.jpg)`
                            }
                        }
                        >
                    </div>
                    <div className='slider-text'>
                        Epic
                    </div>
                    <div className='center' style={{position:'relative',width:'100%',height:'100%'}}>
                        <Row style={{width:'93%'}} gutter={[8,32]}>
                            <Col xl={{span:12}}  sm={{span:24}} xs={{span:24}} className='align-center'>
                                <Space size={20} direction='vertical justify-center'>
                                    <div className='text-one'>
                                        <Title 
                                            level={2} 
                                            className='brand-color my-0' 
                                            style={{textTransform: 'uppercase'}}
                                            data-aos="fade-down"
                                            data-aos-duration="1000" 
                                            data-aos-once="true"
                                            data-aos-delay="500"
                                            >
                                            Epic Ride London
                                        </Title>
                                        <div
                                            data-aos="fade-up"
                                            data-aos-duration="1000" 
                                            data-aos-once="true"
                                            data-aos-delay="500"
                                            >
                                            <Title level={1} className='text-white home-top-section-title mt-2' style={{textTransform: 'uppercase'}}>
                                                Our luxury solutions for your traveling...
                                            </Title>
                                            <Space size={20} className='google-rating'>
                                                <Image 
                                                    src='/luxride/google.png' 
                                                    alt='epic-ride-london' 
                                                    className='google-logo'
                                                    preview={false}
                                                    />
                                                <Space>
                                                    {
                                                        new Array(5)?.fill(null)?.map((_, index)=>
                                                            <Image 
                                                                key={'raing-'+index}
                                                                src='/luxride/star.png' 
                                                                className='google-rating-stars' 
                                                                alt='epic-ride-london'
                                                                preview={false}
                                                                />
                                                        )
                                                    }
                                                    <Text strong style={{fontSize:'50px', color:'white'}}>(73)</Text>
                                                </Space>
                                            </Space>
                                        </div>
                                    </div>
                                    <div 
                                        style={{width:'300px'}} 
                                        className='text-two'
                                        data-aos="fade-right"
                                        data-aos-duration="1000" 
                                        data-aos-once="true"
                                        data-aos-delay="500"
                                        >
                                        <ReplaButton title='Discover More Epic Chauffers' to='/about-us'/>
                                    </div>
                                </Space>
                            </Col>
                            <Col 
                                xl={{span:12}} lg={{span:0}} md={{span:0}} sm={{span:0}} xs={{span:0}}
                                data-aos="fade-left"
                                data-aos-duration="1000" 
                                data-aos-once="true"
                                data-aos-delay="500"
                                >
                                    <div className='home-page-right-side'>
                                        <LuxrideTabsForm/>
                                    </div>
                            </Col>
                        </Row>
                    </div>
            </div>
        </div>
    )
}

export default HomeTopSection;