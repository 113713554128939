import React from 'react'
import { Row, Col, Typography, Space, Image } from 'antd'
import './luxridesoutham.css'

const { Title, Text } = Typography;

const LuxrideSouthBusiness = () => {
  return (
    <div className='dflex' style={{padding:"30px",marginTop:"30px"}}>
        <div className='width93'>
            <Row gutter={[32,32]} style={{alignItems:"center",justifyContent:"center"}}>
                <Col span={24}>
                    <Space direction='vertical' style={{width:"100%",textAlign:"center"}}>
                        <Title className='text-white'>What Makes Us Best at the Business?</Title>
                    </Space>
                </Col>
                <Col xs={24} sm={24} md={12} lg={10}>
                    <Space style={{width:"100%",textAlign:"center",justifyContent:"center"}}>
                        <Image 
                            src='/luxride/busi-1.jpg' 
                            className='imgbusi' 
                            alt='epic-ride-london'
                            preview={false}
                            />
                    </Space>
                </Col>
                <Col xs={24} sm={24} md={12} lg={10}>
                    <Space direction='vertical' style={{width:"100%"}}>
                        <Title level={3} className='text-primary'>Wherever you like, whenever you like</Title>
                        <Text className='text-white'>
                            Whether you are looking to travel to <span className='text-primary'>airports</span>, <span className='text-primary'>hotels</span>, or <span className='text-primary'>cruise transfers</span>, we cover <span className='Text-primary'>all destinations</span> in <span className='text-primary'>London</span> and its surroundings. You can book your ride online for convenience.
                        </Text>
                    </Space>
                </Col>
                <Col xs={24} sm={24} md={12} lg={10}>
                    <Space direction='vertical' style={{width:"100%"}}>
                        <Title level={3} className='text-primary'>Time is critical</Title>
                        <Text className='text-white'>
                            We understand the <span className='text-primary'>value of time</span> and we strictly assure that our chauffeurs are always on time. It is our promise that the arrivals and departures will be as instructed and even if we have to wait a while we will be there for you.
                        </Text>
                    </Space>
                </Col>
                <Col xs={24} sm={24} md={12} lg={10}>
                    <Space style={{width:"100%",textAlign:"center",justifyContent:"center"}}>
                        <Image 
                            src='/luxride/busi-2.jpg' 
                            className='imgbusi' 
                            alt='epic-ride-london'
                            preview={false}
                            />
                    </Space>
                </Col>
                <Col xs={24} sm={24} md={12} lg={10}>
                    <Space style={{width:"100%",textAlign:"center",justifyContent:"center"}}>
                        <Image 
                            src='/luxride/busi-3.jpg' 
                            className='imgbusi' 
                            alt='epic-ride-london'
                            preview={false}
                            />
                    </Space>
                </Col>
                <Col xs={24} sm={24} md={12} lg={10}>
                    <Space direction='vertical' style={{width:"100%"}}>
                        <Title level={3} className='text-primary'>Greet with a smile</Title>
                        <Text className='text-white'>
                            As you arrive, you will see our chauffeur with a <span className='text-primary'>board having your name</span> on it. We believe in greeting our passengers with a smile and our drivers will meet you inside the booking facilities and airports.
                        </Text>
                    </Space>
                </Col>
                <Col xs={24} sm={24} md={12} lg={10}>
                    <Space direction='vertical' style={{width:"100%"}}>
                        <Title level={3} className='text-primary'>Comfort and Class</Title>
                        <Text className='text-white'>
                            Get yourself a comfy ride and have a classic journey without any hassle with our Southampton transfers.
                        </Text>
                    </Space>
                </Col>
                <Col xs={24} sm={24} md={12} lg={10}>
                    <Space style={{width:"100%",textAlign:"center",justifyContent:"center"}}>
                        <Image 
                            src='/luxride/busi-4.jpg' 
                            className='imgbusi'
                            alt='epic-ride-london'
                            preview={false}
                            />
                    </Space>
                </Col>
                <Col xs={24} sm={24} md={12} lg={10}>
                    <Space style={{width:"100%",textAlign:"center",justifyContent:"center"}}>
                        <Image 
                            src='/luxride/busi-5.jpg' 
                            className='imgbusi' 
                            alt='epic-ride-london'
                            preview={false}
                            />
                    </Space>
                </Col>
                <Col xs={24} sm={24} md={12} lg={10}>
                    <Space direction='vertical' style={{width:"100%"}}>
                        <Title level={3} className='text-primary'>Where quality meets affordability</Title>
                        <Text className='text-white'>
                            We do our best to deliver you the best transfer services in the area and keep <span className='text-primary'>reasonable pricing</span> so that everyone can enjoy a lavish ride.
                        </Text>
                    </Space>
                </Col>
                <Col xs={24} sm={24} md={12} lg={10}>
                    <Space direction='vertical' style={{width:"100%"}}>
                        <Title level={3} className='text-primary'>No delays</Title>
                        <Text className='text-white'>
                            We believe in making our drivers wait than our passengers. Therefore, you don’t have to wait for our drivers. Instead, the <span className='text-primary'>chauffeurs will wait for you.</span>
                        </Text>
                    </Space>
                </Col>
                <Col xs={24} sm={24} md={12} lg={10}>
                    <Space style={{width:"100%",textAlign:"center",justifyContent:"center"}}>
                        <Image 
                            src='/luxride/busi-6.jpg' 
                            className='imgbusi' 
                            alt='epic-ride-london'
                            preview={false}
                            />
                    </Space>
                </Col>
                <Col xs={24} sm={24} md={12} lg={10}>
                    <Space style={{width:"100%",textAlign:"center",justifyContent:"center"}}>
                        <Image 
                            src='/luxride/busi-7.jpg' 
                            className='imgbusi' 
                            alt='epic-ride-london'
                            preview={false}
                            />
                    </Space>
                </Col>
                <Col xs={24} sm={24} md={12} lg={10}>
                    <Space direction='vertical' style={{width:"100%"}}>
                        <Title level={3} className='text-primary'>Exceeding the expectations</Title>
                        <Text className='text-white'>
                            Usually, companies aim for providing value for money. However, we strive hard to exceed the expectations and do our best to over deliver. Whether you talk about airport to <span className='text-primary'>airport services</span>, <span className='text-primary'>door-to-door trips</span>, or <span className='text-primary'>executive journeys</span>, we take pride in going an extra mile.
                        </Text>
                    </Space>
                </Col>
                <Col xs={24} sm={24} md={12} lg={10}>
                    <Space direction='vertical' style={{width:"100%"}}>
                        <Title level={3} className='text-primary'>Cruise transfers</Title>
                        <Text className='text-white'>
                            You can <span className='text-primary'>hire chauffeur</span> for <span className='text-primary'>Southampton cruise transfers</span> and our driver will be right at there to meet you. Not only they assist you with your travels but they also take care of your luggage.
                        </Text>
                    </Space>
                </Col>
                <Col xs={24} sm={24} md={12} lg={10}>
                    <Space style={{width:"100%",textAlign:"center",justifyContent:"center"}}>
                        <Image 
                            src='/luxride/busi-8.jpg' 
                            className='imgbusi' 
                            alt='epic-ride-london'
                            preview={false}
                            />
                    </Space>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default LuxrideSouthBusiness