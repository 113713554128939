import React from 'react'
import { Col, Row, Typography, Space, Image } from 'antd'

const { Title } = Typography;

export const LondonSightseeingServices = () => {
  return (
    <div className='py-5 justify-center'>
        <div className='width-93'>
            <Row gutter={[32,32]}>
                <Col lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}}>
                    <Space direction='vertical' size={30}>
                        <div>
                            <Title 
                                level={4} 
                                className='brand-color'
                                data-aos="fade-down"
                                data-aos-duration="800" 
                                data-aos-once="true"
                                data-aos-delay="300"
                                >
                                EXECUTIVE CHAUFFEUR SERVICE COMPANY
                            </Title>
                            <Title 
                                level={1} 
                                className='my-0'
                                data-aos="fade-up-right"
                                data-aos-duration="800" 
                                data-aos-once="true"
                                data-aos-delay="300"
                                >
                                London Sightseeing Services:
                            </Title>
                        </div>
                        <Space 
                            direction='vertical'
                            data-aos="fade-right"
                            data-aos-duration="800" 
                            data-aos-once="true"
                            data-aos-delay="300"
                            >
                            <p>
                                Explore the iconic city of London in style and comfort with our exceptional sightseeing services. We offer personalized tours that allow you to immerse yourself in the rich history, culture, and vibrant atmosphere of this magnificent city.
                            </p>
                            <p>
                                Our knowledgeable and friendly chauffeurs will guide you through the must-see landmarks, hidden gems, and famous attractions that define London. From the majestic Tower of London to the bustling streets of Covent Garden, we ensure you don't miss out on the city's most iconic sights.
                            </p>
                            <p>
                                Sit back and relax in our luxurious vehicles as you traverse the city's streets. Our fleet consists of comfortable and spacious cars that provide a perfect vantage point to admire the stunning architecture and landmarks along the way.
                            </p>
                            <p>
                                Whether you're a first-time visitor or a London enthusiast, our sightseeing services cater to all. We offer flexible itineraries and can customize the tour based on your preferences and interests, ensuring a memorable and personalized experience.
                            </p>
                            <p>
                                Discover the magic of London with our exceptional sightseeing services. Let us take care of the transportation logistics while you soak up the beauty and history of this remarkable city.
                            </p>
                        </Space>
                    </Space>
                </Col>
                <Col 
                    lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}} 
                    data-aos="fade-left"
                    data-aos-duration="800" 
                    data-aos-once="true"
                    data-aos-delay="300"
                    >
                    <div style={{height:"100%", width:'100%'}} className='brand-bg-blue p-3 py-5 center'>
                        <Image 
                            src='/assets/fleets/e class/8 - B.jpg' 
                            alt='epic-ride-london' 
                            className="img" 
                            width={'100%'} 
                            preview={false}
                            data-aos="zoom-in"
                            data-aos-duration="800" 
                            data-aos-once="true"
                            data-aos-delay="300"
                            />
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}