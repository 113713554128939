import {Modal, Space, Upload, Typography, Form} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useState} from 'react'

export const MultipleImageUpload = ({name, label, required, message, fileList, setFileList}) => {
    const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
    const [previewOpen, setPreviewOpen] = useState(false)
    const [previewImage, setPreviewImage] = useState('')
    const [previewTitle, setPreviewTitle] = useState('')
    const handlePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
      setPreviewOpen(true);
      setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div
          style={{
            marginTop: 8,
          }}
        >
          Upload
        </div>
      </div>
    )
  return (
    <div>
       <Form.Item
        name={name}
        label= {<Typography.Text strong>{label}</Typography.Text>}
        rules={[
          {
          required,
          message,
          },
      ]}
        valuePropName="fileList"
      >
        <Space direction='vertical'>
            <Upload
                name={name}
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onRemove={(file)=>{}}
                onChange={handleChange}
                multiple
            >
                {/* {fileList.length >= 8 ? null : uploadButton} */}
                {
                  uploadButton
                }
            </Upload>
        </Space>
      </Form.Item>
      
      <Modal 
        visible={previewOpen} 
        title={previewTitle} 
        footer={null} 
        onCancel={()=>setPreviewOpen(false)}
        >
        <img
          style={{
            width: '100%'
          }}
          src={previewImage}
          alt='epic-ride-london'
        />
      </Modal>
    </div>
  )
}