import { BreadCrumb } from "../../ui-elements"
import MapContainer from "./mapContainer"
const Map=()=>{
    return (
        <>
         <BreadCrumb 
            title='Checkout' 
            description='' 
            pageName='Checkout' 
            breadcrumb={
                [
                    {to:'/',name:'Home'},
                    {to:'/service', name:'service'}
                ]
            }
            />
            <MapContainer/>  
        </>
    )
}
export default Map