import React from 'react'
import { Col, Row, Space, Typography, Image } from 'antd';
import './luxridesoutham.css'

const {Title} = Typography;

const LuxrideSouthCovid = () => {
  return (
    <div className='dflex '>
        <Image 
          src='/luxride/multi-color.png'  
          alt='epic-ride-london'
          preview={false}
          />
        <div className='width93'>
            <Row gutter={24} className='covid' style={{alignItems:"center"}}>
                <Col xs={24} sm={24} md={24}>
                    <Space style={{width:"100%",justifyContent:"center",marginBottom:"20px"}}>
                        <Title level={2} className='text-white'>COVID - 19 SAFETY UPDATES</Title>
                    </Space>
                </Col>
                <Col xs={24} sm={24} md={14} lg={16}>
                    <Space direction='vertical' style={{width:"100%"}}>
                        <ul className='ul-covid'>
                          <li className='text-white'>
                            Sanitization of vehicles thoroughly.
                          </li>
                          <li className='text-white'>
                            Cleaning before and after each transfer.
                          </li>
                          <li className='text-white'>
                            Chauffeurs are strictly advised to avoid handshakes and handlings and stick to bow greetings.
                          </li>
                          <li className='text-white'>
                            Regular checkup of drivers for temperature and illnesses.
                          </li>
                          <li className='text-white'>
                            Adherence to TFL guidelines and SOPs. Sanitizers offered to clients.
                          </li>
                          <li className='text-white'>
                            AC is kept only on standard airflow with on recirculation as the passengers are on board.
                          </li>
                        </ul>
                    </Space>
                </Col>
                <Col xs={24} sm={24} md={10} lg={8} style={{textAlign:"center"}}>
                    <Image 
                      src='/luxride/covid.png'
                      alt='epic-ride-london'
                      preview={false}
                      style={{width:"300px"}} 
                      />
                </Col>
            </Row>
        </div>
      <Image 
        src='/luxride/multi-color.png'  
        alt='epic-ride-london'
        preview={false}
        />
    </div>
  )
}

export default LuxrideSouthCovid