import { Image } from "antd"

const dataclass = [
    {
        bentley:{
            heading:'Bentley',
            slider:[
                {
                    original: '/assets/fleets/bentley/7.jpg',
                    thumbnail: '/assets/fleets/bentley/7.jpg',
                },
                {
                    original: '/assets/fleets/bentley/1.jpg',
                    thumbnail: '/assets/fleets/bentley/1.jpg',
                },
                {
                    original: '/assets/fleets/bentley/2.jpg',
                    thumbnail: '/assets/fleets/bentley/2.jpg',
                },
                {
                    original: '/assets/fleets/bentley/3.jpg',
                    thumbnail: '/assets/fleets/bentley/3.jpg',
                },
                {
                    original: '/assets/fleets/bentley/4.jpg',
                    thumbnail: '/assets/fleets/bentley/4.jpg',
                },
                {
                    original: '/assets/fleets/bentley/6.jpg',
                    thumbnail: '/assets/fleets/bentley/6.jpg',
                },
                
                {
                    original: '/assets/fleets/bentley/8.jpg',
                    thumbnail: '/assets/fleets/bentley/8.jpg',
                },
                {
                    original: '/assets/fleets/bentley/9.jpg',
                    thumbnail: '/assets/fleets/bentley/9.jpg',
                },
                {
                    original: '/assets/fleets/bentley/10.jpg',
                    thumbnail: '/assets/fleets/bentley/10.jpg',
                },
                {
                    original: '/assets/fleets/bentley/12.jpg',
                    thumbnail: '/assets/fleets/bentley/12.jpg',
                },
                {
                    original: '/assets/fleets/bentley/16.avif',
                    thumbnail: '/assets/fleets/bentley/16.avif',
                },
                {
                    original: '/assets/fleets/bentley/15.jpg',
                    thumbnail: '/assets/fleets/bentley/15.jpg',
                },
                {
                    original: '/assets/fleets/bentley/13.jpg',
                    thumbnail: '/assets/fleets/bentley/13.jpg',
                },
            ],
            details:{
                image: <Image src="/assets/fleets/bentley/13.jpg" alt="epic ride london blue bentley flying spur in london" preview={false}/>,
                title:'Embrace Unparalleled Luxury and Prestige with Bentley',
                firstParagraph:"Introducing Bentley, the epitome of business class luxury and executive-level travel. Renowned for its handcrafted excellence, impeccable design, and unrivaled performance, Bentley represents the pinnacle of sophistication and opulence. With its timeless elegance, cutting-edge technology, and unmatched craftsmanship, a Bentley delivers a driving experience that surpasses expectations.",
                secondParagraph: "Step inside a Bentley, and you enter a world of exquisite craftsmanship and unparalleled comfort. The meticulously handcrafted interiors are a testament to the artistry of Bentley's skilled artisans. The sumptuous leather upholstery, hand-stitched with precision, wraps you in a cocoon of luxury. The carefully selected wood veneers, polished metal accents, and opulent trimmings create an ambiance of refinement and exclusivity. In a Bentley, every detail is designed to provide the utmost comfort and indulgence, ensuring that every journey is a lavish experience."
            }
        },
        eClass:{
            heading:'Mercedes E Class',
            slider:[
                {
                    original: '/assets/fleets/e class/e1.jpg',
                    thumbnail: '/assets/fleets/e class/e1.jpg',
                },
                {
                    original: '/assets/fleets/e class/e2.jpg',
                    thumbnail: '/assets/fleets/e class/e2.jpg',
                },
                {
                    original: '/assets/fleets/e class/e3.jpg',
                    thumbnail: '/assets/fleets/e class/e3.jpg',
                },
                {
                    original: '/assets/fleets/e class/e4.jpg',
                    thumbnail: '/assets/fleets/e class/e4.jpg',
                },
                {
                    original: '/assets/fleets/e class/e5.jpg',
                    thumbnail: '/assets/fleets/e class/e5.jpg',
                },
                {
                    original: '/assets/fleets/e class/e8.jpg',
                    thumbnail: '/assets/fleets/e class/e8.jpg',
                },
                {
                    original: '/assets/fleets/e class/e9.png',
                    thumbnail: '/assets/fleets/e class/e9.png',
                },
                {
                    original: '/assets/fleets/e class/e10.jpg',
                    thumbnail: '/assets/fleets/e class/e10.jpg',
                },
                {
                    original: '/assets/fleets/e class/e11.png',
                    thumbnail: '/assets/fleets/e class/e11.png',
                },
                {
                    original: '/assets/fleets/e class/e12.jpg',
                    thumbnail: '/assets/fleets/e class/e12.jpg',
                },
                {
                    original: '/assets/fleets/e class/e13.jpg',
                    thumbnail: '/assets/fleets/e class/e13.jpg',
                },
               
            ],
            details:{
                image: <Image src="/assets/fleets/e class/12.jpg" alt="epic ride london mercedes e class in london" preview={false}/>,
                title: "Embodiment of elegance, and unparalleled comfort.",
                firstParagraph: "E-Class is an embodiment of elegance, sophistication, and unparalleled comfort. Designed for discerning executives and those who appreciate the finer things in life, this executive-level vehicle redefines what it means to travel in style. With its sleek design, cutting-edge technology, and attention to detail, the Mercedes E-Class is the epitome of business class luxury.",
                secondParagraph: "Stepping inside the Mercedes E-Class is like entering a sanctuary of luxury and comfort. The spacious cabin is meticulously designed with premium materials, exquisite craftsmanship, and ergonomic features. Sink into the plush leather seats, which provide excellent support and allow you to enjoy the journey in complete relaxation. The cabin is designed to minimize noise and vibrations, creating a tranquil environment that is perfect for productive conversations or simply unwinding after a long day.",
                thirdParagraph:"We take pride in providing an exceptional chauffeur service, and our fleet of Mercedes E-Class vehicles ensures that your journey is nothing short of extraordinary.",
            }
        },
        sClass:{
            heading:'Mercedes S Class',
            slider:[
                {
                    original: '/assets/fleets/s class/s1.jpg',
                    thumbnail: '/assets/fleets/s class/s1.jpg',
                },
                {
                    original: '/assets/fleets/s class/s7.avif',
                    thumbnail: '/assets/fleets/s class/s7.avif',
                },
                {
                    original: '/assets/fleets/s class/s2.avif',
                    thumbnail: '/assets/fleets/s class/s2.avif',
                },
                {
                    original: '/assets/fleets/s class/s3.avif',
                    thumbnail: '/assets/fleets/s class/s3.avif',
                },
                {
                    original: '/assets/fleets/s class/s4.avif',
                    thumbnail: '/assets/fleets/s class/s4.avif',
                },
                {
                    original: '/assets/fleets/s class/s5.jpg',
                    thumbnail: '/assets/fleets/s class/s5.jpg',
                },
                {
                    original: '/assets/fleets/s class/s6.jpg',
                    thumbnail: '/assets/fleets/s class/s6.jpg',
                }
            ],
            details:{
                image: <Image src="/assets/fleets/s class/s class black.jpg" alt="epic ride london black mercedes s class in london" preview={false}/>,
                title: 'Introducing the Mercedes S-Class',
                firstParagraph: "The epitome of business class luxury and the ultimate executive-level vehicle. Renowned for its unrivaled elegance, cutting-edge technology, and chauffeur-driven excellence, the Mercedes S-Class sets the benchmark for automotive perfection. With its iconic design, opulent interior, and advanced features, this vehicle offers an extraordinary experience that goes beyond expectations.",
                secondParagraph: "Step inside the Mercedes S-Class, and you enter a world of pure luxury and refinement. The spacious cabin is meticulously crafted using the finest materials, creating an atmosphere of opulence and comfort. The handcrafted leather seats, meticulously stitched with precision, offer unparalleled support and indulgence. Every surface, from the elegant wood trims to the ambient lighting, is carefully selected to create a harmonious and inviting environment. The S-Class provides a sanctuary where you can unwind, relax, or conduct business in utmost tranquility."
            }
        },
        rangerRover:{
            heading:'Range Rover',
            slider:[
                {
                    original: '/assets/fleets/range rover/9.jpg',
                    thumbnail: '/assets/fleets/range rover/9.jpg',
                },
                {
                    original: '/assets/fleets/range rover/7.jpg',
                    thumbnail: '/assets/fleets/range rover/7.jpg',
                },
                {
                    original: '/assets/fleets/range rover/8.jpg',
                    thumbnail: '/assets/fleets/range rover/8.jpg',
                },
                {
                    original: '/assets/fleets/range rover/3.avif',
                    thumbnail: '/assets/fleets/range rover/3.avif',
                },
                {
                    original: '/assets/fleets/range rover/4.avif',
                    thumbnail: '/assets/fleets/range rover/4.avif',
                },
                {
                    original: '/assets/fleets/range rover/5.avif',
                    thumbnail: '/assets/fleets/range rover/5.avif',
                },
                {
                    original: '/assets/fleets/range rover/2.avif',
                    thumbnail: '/assets/fleets/range rover/2.avif',
                },
                {
                    original: '/assets/fleets/range rover/6.avif',
                    thumbnail: '/assets/fleets/range rover/6.avif',
                },
            ],
            details:{
                image: <Image src="/assets/fleets/range rover/3.avif" alt="epic ride london white range rover in london" preview={false}/>,
                title:'Introducing the Range Rover',
                firstParagraph: "The epitome of business class luxury and executive-level travel. Renowned for its commanding presence, unrivaled capability, and unmatched elegance, the Range Rover represents the pinnacle of sophistication and versatility. With its iconic design, opulent interior, and superior performance, this vehicle sets the standard for those who demand excellence in every aspect of their journey.",
                secondParagraph: "Step inside the Range Rover, and you enter a world of unparalleled luxury and comfort. The meticulously crafted cabin showcases the finest materials and exquisite attention to detail. Sink into the sumptuous leather seats, which envelop you in a cocoon of opulence. The generous legroom and expansive panoramic roof create an open and airy feel, while the advanced climate control system ensures the perfect temperature for your comfort. The Range Rover provides a sanctuary of relaxation and refinement, making every journey an indulgent experience."
            }
        },
        rollsRoyce:{
            heading:'Rolls Royce',
            slider:[
                {
                    original: '/assets/fleets/rolls royce/2.avif',
                    thumbnail: '/assets/fleets/rolls royce/2.avif',
                },
                {
                    original: '/assets/fleets/rolls royce/3.avif',
                    thumbnail: '/assets/fleets/rolls royce/3.avif',
                },
                {
                    original: '/assets/fleets/rolls royce/4.avif',
                    thumbnail: '/assets/fleets/rolls royce/4.avif',
                },
                {
                    original: '/assets/fleets/rolls royce/5.avif',
                    thumbnail: '/assets/fleets/rolls royce/5.avif',
                },
                {
                    original: '/assets/fleets/rolls royce/6.avif',
                    thumbnail: '/assets/fleets/rolls royce/6.avif',
                },
                {
                    original: '/assets/fleets/rolls royce/7.avif',
                    thumbnail: '/assets/fleets/rolls royce/7.avif',
                },
                {
                    original: '/assets/fleets/rolls royce/1.avif',
                    thumbnail: '/assets/fleets/rolls royce/1.avif',
                },
                {
                    original: '/assets/fleets/rolls royce/8.jpg',
                    thumbnail: '/assets/fleets/rolls royce/8.jpg',
                },
                {
                    original: '/assets/fleets/rolls royce/9.jpg',
                    thumbnail: '/assets/fleets/rolls royce/9.jpg',
                },
                
            ],
            details:{
                image: <Image src="/assets/fleets/rolls royce/1.avif" alt="epic ride london rolls-royce ghost interior" preview={false}/>,
                title:'Rolls-Royce  symbol of elegance',
                firstParagraph: "The ultimate symbol of opulence, craftsmanship, and elegance. When it comes to business class and executive-level travel, Rolls-Royce sets the standard for uncompromising luxury and unmatched prestige. With its iconic design, handcrafted perfection, and exquisite attention to detail, a Rolls-Royce represents the epitome of success and sophistication.",
                secondParagraph: "Step inside a Rolls-Royce, and you enter a world of unmatched luxury and craftsmanship. Every detail of the interior is meticulously handcrafted using the finest materials, ensuring an atmosphere of unparalleled opulence. The sumptuous leather seats, expertly stitched and meticulously tailored, provide unmatched comfort and support. The use of exotic wood veneers, polished metal accents, and soft ambient lighting creates an ambiance of sophistication and refinement. In a Rolls-Royce, every journey becomes a personalized experience of indulgence and relaxation."
            }
        },
        vClass:{
            heading:'Mercedes V Class',
            slider:[
                {
                    original: '/luxride/vclass/3.jpeg',
                    thumbnail: '/luxride/vclass/3.jpeg',
                },
                {
                    original: '/luxride/vclass/5.jpeg',
                    thumbnail: '/luxride/vclass/5.jpeg',
                },
                {
                    original: '/luxride/vclass/6.jpeg',
                    thumbnail: '/luxride/vclass/6.jpeg',
                },
                {
                    original: '/luxride/vclass/7.jpeg',
                    thumbnail: '/luxride/vclass/7.jpeg',
                },
                {
                    original: '/luxride/vclass/8.jpeg',
                    thumbnail: '/luxride/vclass/8.jpeg',
                },
                {
                    original: '/luxride/vclass/9.jpeg',
                    thumbnail: '/luxride/vclass/9.jpeg',
                },
                {
                    original: '/luxride/vclass/10.jpeg',
                    thumbnail: '/luxride/vclass/10.jpeg',
                },
                {
                    original: '/luxride/vclass/11.jpeg',
                    thumbnail: '/luxride/vclass/11.jpeg',
                },
                {
                    original: '/luxride/vclass/12.jpeg',
                    thumbnail: '/luxride/vclass/12.jpeg',
                },
                {
                    original: '/luxride/vclass/1.jpeg',
                    thumbnail: '/luxride/vclass/1.jpeg',
                },
                {
                    original: '/luxride/vclass/2.jpeg',
                    thumbnail: '/luxride/vclass/2.jpeg',
                },
            ],
            details:{
                image: <Image src="/assets/fleets/v class/Long-Distance Chauffeur Services.jpg" alt="epic ride london Long-Distance Chauffeur Services mercedes v-class" preview={false}/>,
                title: "Step inside the Mercedes V-Class",
                firstParagraph: "Introducing the Mercedes V-Class, a vehicle that embodies the perfect blend of business class elegance, executive-level comfort, and chauffeur-driven luxury. This remarkable vehicle sets the standard for those who seek a spacious and versatile option without compromising on style or sophistication. With its impeccable design, state-of-the-art features, and exceptional attention to detail, the Mercedes V-Class redefines what it means to travel in business class.",
                secondParagraph: "You'll be greeted by a world of opulence and refinement. The exceptionally spacious cabin offers ample room for both passengers and luggage, making it an ideal choice for executive travel, family outings, or transporting clients in utmost comfort. The premium materials and meticulous craftsmanship throughout the interior create an ambiance of exclusivity, ensuring that every journey is a first-class experience. The ergonomically designed seats, finished with sumptuous leather, provide unparalleled support and relaxation, allowing you to unwind or remain productive during your travels."
            }
        }
    }
]
export {dataclass}