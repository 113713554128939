import React from 'react'
import { Col, Row, Typography, Space, Image } from 'antd'

const { Title } = Typography

export const SouthamptonExecutiveTransferServices = () => {
  return (
    <div className='py-5 justify-center'>
        <div className='width-93'>
            <Row gutter={[32,32]}>
                <Col lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}}>
                    <Space direction='vertical' size={30}>
                        <div>
                            <Title 
                                level={4} 
                                className='brand-color'
                                data-aos="fade-down"
                                data-aos-duration="800" 
                                data-aos-once="true"
                                data-aos-delay="300"
                                >
                                EXECUTIVE CHAUFFEUR SERVICE COMPANY
                            </Title>
                            <Title 
                                level={1} 
                                className='my-0'
                                data-aos="fade-up-right"
                                data-aos-duration="800" 
                                data-aos-once="true"
                                data-aos-delay="300"
                                >
                                Southampton Executive Transfer Services:
                            </Title>
                        </div>
                        <Space 
                            direction='vertical'
                            data-aos="fade-right"
                            data-aos-duration="800" 
                            data-aos-once="true"
                            data-aos-delay="300"
                            >
                            <p>
                                Arriving in Southampton for business or pleasure? Our executive transfer services ensure a seamless and comfortable journey to or from Southampton, whether you're traveling alone or with a group.
                            </p>
                            <p>
                                Our professional chauffeurs are highly skilled in navigating the Southampton area, guaranteeing prompt and efficient transfers to your desired destination. With their extensive knowledge of the local routes and traffic patterns, you can trust us to get you to your destination on time.
                            </p>
                            <p>
                                We offer a fleet of luxurious vehicles to cater to your specific needs and preferences. From sleek sedans to spacious SUVs, each vehicle is meticulously maintained and equipped with modern amenities, ensuring a sophisticated and comfortable travel experience.
                            </p>
                            <p>
                                Whether you're heading to a meeting, embarking on a cruise, or exploring the city's attractions, our executive transfer services provide convenience and style. Sit back, relax, and let us handle your transportation needs, allowing you to focus on your business or enjoy your journey to Southampton.
                            </p>
                            <p>
                                Experience the epitome of executive travel with our Southampton transfer services. Trust us to deliver exceptional service and ensure a memorable and stress-free journey every time.
                            </p>
                        </Space>
                    </Space>
                </Col>
                <Col 
                    lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}} 
                    data-aos="fade-left"
                    data-aos-duration="800" 
                    data-aos-once="true"
                    data-aos-delay="300"
                    >
                    <div style={{height:"100%", width:'100%'}} className='brand-bg-blue p-3 py-5 center'>
                        <Image 
                            src='/luxride/Southampton Executive Transfer Services.jpg' 
                            alt='epic-ride-london' 
                            className="img" 
                            width={'100%'} 
                            preview={false}
                            data-aos="zoom-in"
                            data-aos-duration="800" 
                            data-aos-once="true"
                            data-aos-delay="300"
                            />
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}