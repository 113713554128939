import { domainUrl} from "../constants/apiconstans/domanUrl"
import { Alert } from "../../ui-elements"
export const CheckoutCall = (formData)=>{
    var myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")
    var requestOptions = {
        headers: myHeaders,
        method: 'POST',
        redirect: 'follow',
        body: JSON.stringify(formData)
    }
   return (
        fetch(domainUrl + `/payment`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result)
                return 1
            else
                throw 'error while make payment'
        })
        .catch(error => {
            Alert("error", error) 
            return 0
        })
   )
}