import React from 'react'
import LuxrideQuote from '../../components/luxridequote/LuxrideQuote'
import { BreadCrumb } from '../../ui-elements'

const LuxrideQuotePage = () => {
  return (
    <div className='dflex'>
        <BreadCrumb title='Get a Quote Now' description='Just fill out all the details and our industry expert representatives will find out great options for you.' pageName='Get a Quote' breadcrumb={[{to:'/',name:'Home'}]}/>
        <div className='width93'>
            <LuxrideQuote />
        </div>
    </div>
  )
}

export default LuxrideQuotePage