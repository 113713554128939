import React from 'react'
import {Space, Image, Typography} from 'antd'
import { BreadCrumb } from '../../ui-elements'
import './index.css'
function NotFound404() {
  return (
    <div className='flex-col-center'>
      <BreadCrumb title='Page not found' description='Page not found' pageName='404 not found' breadcrumb={[{to:'/',name:'Home'}]}/>
      <div style={{width:'93%'}} className='py-5'>   
          <div  className='flex-col-center'>
            <Space direction='vertical' style={{position:'relative'}}>
                <Typography.Title level={1} className='my-0 text-center brand-color error-404'>404</Typography.Title>
                <Space direction='vertical' class="error-page-shape zoom-fade">
                    <Image 
                      src={'luxride/error-page-shape-2.png'} 
                      alt='epic-ride-london'
                      preview={false}
                      />
                </Space>
            </Space>
            <Typography.Title level={2} className='text-center'>Sorry we can't find that page!</Typography.Title>
            <Typography.Text className='text-center'>The page you are looking for was never existed.</Typography.Text>
          </div>
      </div>
    </div>
  )
}

export default NotFound404