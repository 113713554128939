import React from 'react'
import { Row, Col, Space, Typography, Input, Form, DatePicker, TimePicker, Select } from 'antd'
import ReplaButton from '../buttons';
import './luxridequote.css'

const { Option } = Select;
const { Text } = Typography;
const { TextArea } = Input;

const LuxrideQuote = () => {
  return (
    <div  className='dflex my-5 py-5'>
        <div className='width93'>
        <Space direction='vertical' style={{width:'100%'}}>
            <Form
                layout='vertical'
                className='get-locations-form px-3 py-4'
            >
                <Row gutter={[12,12]}>
                    <Col xs={24} sm={24} md={12} lg={8}>
                        <Form.Item
                            name="fullname" 
                            label={<Text strong>Full Name</Text>}
                            rules={[
                            {
                                required: true,
                                message: 'Please enter your full name',
                            },
                            ]}
                        >
                            <Input style={{width:'100%'}} className='sizeInput' />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                        <Form.Item
                            name="email" 
                            label={<Text strong>E-mail</Text>}
                            rules={[
                            {
                                required: true,
                                message: 'Please enter your email',
                            },
                            ]}
                        >
                            <Input style={{width:'100%'}} className='sizeInput' />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                        <Form.Item
                            name="phone" 
                            label={<Text strong>Phone</Text>}
                            rules={[
                            {
                                required: true,
                                message: 'Please enter your phone no',
                            },
                            ]}
                        >
                            <Input style={{width:'100%'}} className='sizeInput' />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                            name="pickuplocation" 
                            label={<Text strong>Pickup Location</Text>}
                            rules={[
                            {
                                required: true,
                                message: 'Please enter your pickup location',
                            },
                            ]}
                        >
                            <Input style={{width:"100%"}} className='sizeInput' />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                            name="dropofflocation" 
                            label={<Text strong>Drop-Off Location</Text>}
                            rules={[
                            {
                                required: true,
                                message: 'Please enter your drop-off location',
                            },
                            ]}
                        >
                            <Input style={{width:"100%"}} className='sizeInput' />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                            name="pickupDate" 
                            label={<Text strong>Pickup date</Text>}
                            rules={[
                            {
                                required: true,
                                message: 'Please enter your pickup date',
                            },
                            ]}
                        >
                            <DatePicker style={{width:'100%'}} className='sizeInput' />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                            name="pickupTime" 
                            label={<Text strong>Pickup Time</Text>}
                            rules={[
                            {
                                required: true,
                                message: 'Please enter your pickup time',
                            },
                            ]}
                        >
                            <TimePicker style={{width:'100%'}} className='sizeInput' />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                            name="choosevehicle" 
                            label={<Text strong>Choose a Vehicle</Text>}
                            rules={[
                            {
                                required: true,
                                message: 'choose a vehicle',
                            },
                            ]}
                        >
                            <Select placeholder='Choose a vehicle' className='sizeSelect'>
                                <Option value="1">MERCEDES-BENZ E-CLASS (BUSINESS CLASS)</Option>
                                <Option value="2">MERCEDES-BENZ S-CLASS (LUXURY CLASS)</Option>
                                <Option value="3">MERCEDES-BENZ V-CLASS (BUSINESS VAN)</Option>
                                <Option value="4">LUXURY SUV (Range Rover)</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6}>
                        <Form.Item
                            name="passenger" 
                            label={<Text strong>Passenger</Text>}
                            rules={[
                            {
                                required: true,
                                message: 'choose a passenger',
                            },
                            ]}
                        >
                            <Select placeholder='Number of a passenger' className='sizeSelect'>
                                <Option value="1">1</Option>
                                <Option value="2">2</Option>
                                <Option value="3">3</Option>
                                <Option value="4">4</Option>
                                <Option value="5">5</Option>
                                <Option value="6">6</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6}>
                        <Form.Item
                            name="luggage" 
                            label={<Text strong>Luggage</Text>}
                            rules={[
                            {
                                required: true,
                                message: 'choose a luggage',
                            },
                            ]}
                        >
                            <Select placeholder='Amount of luggage' className='sizeSelect'>
                                <Option value="1">1</Option>
                                <Option value="2">2</Option>
                                <Option value="3">3</Option>
                                <Option value="4">4</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item
                            name="message" 
                            label={<Text strong>Message</Text>}
                            rules={[
                            {
                                required: true,
                                message: 'choose a message',
                            },
                            ]}
                        >
                            <TextArea rows={4} placeholder='message' />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <div style={{width:'100%'}}>
                            <ReplaButton title='Submit' to='/'/>
                        </div>
                    </Col>
                </Row>
            </Form>
            
        </Space>
        </div>
    </div>
  )
}

export default LuxrideQuote