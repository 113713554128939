import React from 'react'
import 'antd/dist/antd.less'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Navbar from '../components/navbar'
import LuxrideHomePage from '../pages/luxridehomepage/LuxrideHomePage'
import NotFound404 from '../pages/notFound404'
import CommingSoon from '../pages/commingSoon'

import Booking from '../pages/booking'
import LuxrideFooter from '../components/luxridefooter/LuxrideFooter'
import LuxrideAboutUs from '../pages/luxrideaboutus/LuxrideAboutUs'
import LuxrideSocial from '../components/luxridesocial/LuxrideSocial'
import ContactUs from '../pages/contactUs'
import LuxrideServicePage from '../pages/luxrideservice/LuxrideServicePage'
import FAQs from '../pages/faqs'

import Fleets from '../pages/fleets'
import Fleet from '../pages/fleets/fleets'
import ConfirmPayment from '../pages/stripe/confirmPayment'
import LuxrideQuotePage from '../pages/luxridequotepage/LuxrideQuotePage'
import LuxrideSouthampPage from '../pages/luxridesouthamppage/LuxrideSouthampPage'
import LuxridequoteFooter from '../components/luxridequote/LuxridequoteFooter'
import Map from '../pages/map'
import TrustPilot from '../components/trustPilot'
import DriverRegistration from '../pages/driverRegistration'
import TermsCondition from '../pages/termsandcondition'
import Service from '../pages/luxrideservice/service'
import Services from '../pages/Services'
function Routing(){    
    return (
        <Router>
            <Navbar/>
            <Routes>
                <Route path="/" element={<LuxrideHomePage />}/>
                <Route path="/booking" element={<Booking />}/>
                <Route path="/about-us" element={<LuxrideAboutUs />}/>
                <Route path="/faqs" element={<FAQs />}/>
                <Route path="/driver-registration" element={<DriverRegistration />}/>
                <Route path='/terms-&-conditions' element={<TermsCondition />} />
                <Route path="/contact-us" element={<ContactUs />}/>
                <Route path="/services" element={<LuxrideServicePage />}/>
                <Route path="services">
                    <Route path=":service" element={<Services />} />
                </Route> 
                <Route path='fleets' element={<Fleets/>}/>
                <Route path="fleets">
                    <Route path=":fleet" element={<Fleet />} />
                </Route> 
                {/* <Route path="/stripe" element={<Stripe />}/> */}
                <Route path='/confirm-payment' element={<ConfirmPayment/>}/>
                {/* <Route path="/mercedesservice" element={<LuxrideMercedesService/>} /> */}
                <Route path='/get-a-quote' element={<LuxrideQuotePage />} />
                <Route path='/southampton' element={<LuxrideSouthampPage />} />
                <Route path='/map' element={<Map />} />
                <Route path='/blogs' element={<CommingSoon />} />
                <Route path='/coming-soon' element={<CommingSoon />} />
                <Route path='*' element={<NotFound404/>}/>
            </Routes>
            <TrustPilot/>
            <LuxrideSocial />
            <LuxridequoteFooter />
        </Router>    
    )
}

export default Routing;