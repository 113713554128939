import React from 'react'
import { Col, Row, Space, Typography, Image } from 'antd';
import './luxridesoutham.css'
import Slider from 'react-slick';

const {Title,Text} = Typography;

const LuxrideSouthTest = () => {


    var settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };


    const datatest = [
        {
            icontest:"test-1.jpg",
            title:"Remigiusz Olejniczak",
            desc:`Booked a car for my guest at the last minute and they were able to accommodate me perfectly. Would recommend.`
        },
        {
            icontest:"test-2.jpg",
            title:"James Farris",
            desc:`We were so happy to have a transfer pre-arranged when we arrived in London after our overnight trans Atlantic flight. Our driver was prompt, courteous and knowledgeable. He delivered us to our accommodation in a smooth and efficient manor.`
        },
        {
            icontest:"test-3.jpg",
            title:"Christopher Norrington",
            desc:`The company is very reliable and trustworthy with team of excellent Chauffeurs, readily adjustable with timings. My best wishes for their further success in providing a continued excellent services.`
        },
    ]

  return (
    <div className='dflex test-bg'>
        <div className='width93'>
            <Row gutter={[32,32]} style={{alignItems:"center" ,justifyContent:"center"}}>
                <Col span={[32,32]}>
                    <Space style={{width:"100%",textAlign:"center",justifyContent:"center"}}>
                        <Title level={2} className="text-white">Experience of Our Valued Clients</Title>
                    </Space>
                </Col>
                <Col xs={24} sm={24} md={20} lg={18}>
                    <Slider { ...settings } autoplay >
                        {
                            datatest?.map((datat,dt)=>
                            <div key={dt}>
                                <Space direction='vertical' size={40} style={{width:"100%",justifyContent:"center",textAlign:"center"}}>
                                        <div className='textimgcss'>
                                            <Image 
                                              src={'/luxride/'+datat?.icontest} 
                                              alt='epic-ride-london'
                                              preview={false}
                                              />
                                        </div>
                                        <Space size={20} direction='vertical' style={{width:"100%"}}>
                                            <Text className='text-white fontDesctest'>
                                                {
                                                    datat?.desc
                                                }
                                            </Text>
                                            <Title level={4} className='text-primary' style={{marginBottom:"0px"}}>
                                                {
                                                    datat?.title
                                                }
                                            </Title>
                                        </Space>
                                </Space>
                            </div>
                            )
                        }
                    </Slider>
                    
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default LuxrideSouthTest