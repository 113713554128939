import React, { useState } from 'react'
import {SectionTop} from '../../ui-elements/index';
import {Row, Col, Form, Input, Typography, Button} from 'antd'
import "./index.css"
import { ContactUs } from '../../shared';
const {Text, Title}= Typography
const ContactUsFrom=()=>
{
    const [form] = Form.useForm()
    const contacts=[
        {img:'map.png',title:'Visit Us Daily:',description:'Regus 450 Bath Road, Longford UB7 0EB'},
        {img:'phone.png',title:'Phone Us:',description:'+44 203 9300286'},
        {img:'whatsapp2.png',title:'Whatsapp:',description:'+44 7405 747293'},
        {img:'mail.png',title:'Mail Us:',description:'Info@epicridelondon.com'},
    ]
    const [loading, setLoading]= useState(false)
    const submit= async ()=>{
        setLoading(true)
        let data= form.getFieldsValue()
        await ContactUs({...data})
        setLoading(false)
    }
        return (
            <div className="flex-col-center py-5 w-100">
            <SectionTop title='OUR CONTACTS' description='We’re Here to Help You'/>
            <Row gutter={[64,0]}  style={{width:'93%'}}>
                <Col lg={{span:10}} sm={{span:24}} xs={{span:24}} 
                    className="contact-us-left flex-col-center-center py-5"
                    style={{backgroundImage:`url(${'/luxride/london.png'})`}}
                    data-aos="fade-right"
                    data-aos-duration={500}
                    data-aos-delay={300}
                    data-aos-once={true}
                    >
                    {
                        contacts?.map((contact, index)=>
                            <div 
                                key={'contact-detail'+index}
                                className={index===3?"align-center p-3  w-100 ":"align-center p-3 border-bottom w-100 mb-3"}
                                >
                                <img 
                                    src={'/luxride/'+contact?.img}
                                    alt='epic-ride-london'
                                    width='40px' 
                                    height='auto' 
                                    className='me-4'
                                    />
                                <div>
                                    <Title level={4} className='text-white m-0 mb-1'>{contact?.title}</Title>
                                    <Text strong className='m-0 text-white'>{contact?.description}</Text>
                                </div>
                            </div>
                        )
                    }
                </Col>
                <Col 
                    lg={{span:14}} sm={{span:24}} xs={{span:24}} 
                    className='py-5 light-bg'
                    data-aos="fade-left"
                    data-aos-duration={500}
                    data-aos-delay={300}
                    data-aos-once={true}
                    >
                    <Form 
                        layout="vertical"
                        onFinish={submit} 
                        form={form}
                        >
                        <Row gutter={[16,16]}>
                            <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                <Form.Item
                                    name="name" 
                                    label={<Text strong>Name</Text>}
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your name',
                                    },
                                    ]}
                                    >
                                    <Input 
                                        size='large'
                                        placeholder="Please enter your name" 
                                        value={form?.getFieldValue('name') || ''} 
                                        />
                                </Form.Item>
                            </Col>
                            <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                <Form.Item
                                    name="email"
                                    label={<Text strong>Email</Text>}
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your email',
                                    },
                                    ]}
                                >
                                <Input 
                                    type='email'
                                    size='large'
                                    placeholder="Please enter your email" 
                                    value={form?.getFieldValue('email') || ''} 
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="message"
                                    label={<Text strong>Message</Text>}
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your message',
                                    },
                                    ]}
                                >
                                <Input.TextArea
                                    rows={7}
                                    size='large'
                                    placeholder="Please enter your message" 
                                    value={form?.getFieldValue('message') || ''} 
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className='justify-end'>
                            <Button 
                                type='primary' 
                                htmlType='submit' 
                                size='large'
                                loading={loading}
                                >
                                Submit
                            </Button>
                        </Row>
                    </Form>
                </Col>
            </Row>
         </div>
        )
}
export default ContactUsFrom