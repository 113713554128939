import { domainUrl} from "../../constants/apiconstans/domanUrl"
// import { Alert } from "../../../components"
export const RegisterDriver = (formData)=>{
    var requestOptions = {
        method: 'POST',
        redirect: 'follow',
        body: formData
    }
   return (
        fetch(domainUrl + `/RegisterDriver`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result)
                return 1
            else
                throw 'error while adding item to cart'
        })
        .catch(error => {
            //Alert("error", error) 
            return 0
        })
   )
}