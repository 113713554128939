import React from 'react'
import { Col, Row, Typography, Space, Image, Avatar } from 'antd'
import LeftCard from './LeftCard';
import "./index.css"
const { Title, Text } = Typography;

export const Intro = () => {
    
  return (
    <div className='py-5 justify-center'>
        <div className='width-93'>
            <Row gutter={[32,32]} style={{justifyContent:"center"}}>
                <Col lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}}>
                    <Space direction='vertical' size={30} className='pt-3'>
                        <div>
                            <Title 
                                level={3} 
                                className='brand-color'
                                data-aos="fade-down"
                                data-aos-duration="500" 
                                data-aos-once="true"
                                data-aos-delay="300"
                                >
                                ABOUT US
                            </Title>
                            <Title 
                                level={1} 
                                className='my-0'
                                data-aos="fade-up"
                                data-aos-duration="500" 
                                data-aos-once="true"
                                data-aos-delay="300"
                                >
                                ABOUT Epic Ride London
                            </Title>
                        </div>
                        <Space direction='vertical'>
                            <p
                                data-aos="fade-right"
                                data-aos-duration="500" 
                                data-aos-once="true"
                                data-aos-delay="300"
                                >
                                Epic Ride London is a UK ground transportation management chauffeur company in London focused on providing you with the chauffeur service in London and the very best in the British chauffeuring experience.
                            </p>
                            <p
                                data-aos="fade-right"
                                data-aos-duration="500" 
                                data-aos-once="true"
                                data-aos-delay="500"
                                >
                                Our key strengths are our single-minded obsession with quality control which we achieve primarily with an industry-specific team of transportation experts, the best hand-picked chauffeurs within your area, multi-functional/faceted logistics software and premium vehicles specific to the job hand.
                            </p>
                        </Space>
                        <Space size={10}>
                            <Avatar
                                src="/luxride/ceo.jpg" 
                                alt="founder ceo epic ride london" 
                                size={60}
                                />
                            <Space direction='vertical' size={0}>
                                <Title level={3} className='my-0'>Adil Aziz</Title>
                                <Text strong>Founder Epic Ride London</Text>
                            </Space>
                        </Space>
                        <Image 
                            src="/assets/car pics/20.jpg" 
                            alt="epic ride london private aviation services" 
                            preview={false}
                            />
                        <div
                            data-aos="zoom-in"
                            data-aos-duration="500" 
                            data-aos-once="true"
                            data-aos-delay="300"
                            >
                            <p>
                                The Bespoke Chauffeur and Concierge Service based in London, UK that is truly global.
                            </p>
                            <p>
                                With our services tailored only around your individual needs.
                            </p>
                            <p>
                                Professional, discreet, transparent and confidential.
                            </p>
                            <p>
                                Reserve your very own chauffeur service here and let EpicChauffeur take care of the rest. It’s that simple.
                            </p>
                        </div>
                    </Space>
                </Col>
                <Col lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}} >
                    <Space direction='vertical' size={0}>
                        <div style={{height:"100%", width:'100%'}} className='center'>
                            <Image 
                                src='/luxride/carporate-1.jpg' 
                                alt='executive transfer services epic ride london'
                                className="img" 
                                width={'100%'} 
                                data-aos="fade-left"
                                data-aos-duration="500" 
                                data-aos-once="true"
                                data-aos-delay="300"
                                />
                        </div>
                        <LeftCard />
                    </Space>
                </Col>
            </Row>
        </div>
    </div>
  )
}