import React from 'react'
import { Col, Row, Space, Typography, Image } from 'antd'
import './index.css'
const {Title} = Typography;


const LuxrideRegion = () => {

  return (
    <div className='justify-center py-5 light-bg'>
        <div className='py-5' style={{width:'93%'}}>
            <Row gutter={[32, 32]}>
                <Col lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}} className='align-center'>
                    <Space size={10} className="mb-mobl" style={{width:"100%",justifyContent:"center",flexWrap:"nowrap"}}>
                        <Image 
                            src='/assets/fleets/s class/2.jpg' 
                            className='imgleft'
                            data-aos="fade-up" 
                            data-aos-duration="500" 
                            data-aos-delay="300" 
                            data-aos-once="true"
                            alt='epic-ride-london'
                            preview={false}
                            />
                        <Space size={10} direction='vertical'>
                            <Image 
                                src='/luxride/9.jpg' 
                                className='imgR' 
                                data-aos="zoom-in" 
                                data-aos-duration="500" 
                                data-aos-delay="300" 
                                data-aos-once="true"
                                alt='epic-ride-london'
                                preview={false}
                                />
                            <Image 
                                src='/assets/fleets/s class/4.jpg' 
                                className='imgR'
                                data-aos="fade-down" 
                                data-aos-duration="500" 
                                data-aos-delay="300" 
                                data-aos-once="true"
                                alt='epic-ride-london'
                                preview={false}
                                />
                        </Space>
                    </Space>
                </Col>
                <Col lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}} className='align-center'>
                    <Space direction='vertical' size={30}>
                        <div>
                            <Title 
                                level={3} 
                                className='brand-color'
                                data-aos="fade-down"
                                data-aos-duration="500" 
                                data-aos-once="true"
                                data-aos-delay="300"
                                >
                                Epic Ride London
                            </Title>
                            <Title 
                                level={1} 
                                className='my-0'
                                data-aos="fade-up"
                                data-aos-duration="500" 
                                data-aos-once="true"
                                data-aos-delay="300"
                                >
                                We are your trusted Chauffeurs company
                            </Title>
                        </div>
                        <div
                            data-aos="fade-up"
                            data-aos-duration="500" 
                            data-aos-once="true"
                            data-aos-delay="300"
                            >
                            <Title level={4} className='brand-color'>
                                From the region, for the region
                            </Title>
                            <p>With our dedicated team of professional chauffeurs and a fleet of luxurious vehicles, we ensure that you experience the utmost comfort and convenience throughout your journey. Our chauffeurs are highly trained, experienced, and familiar with the best routes to ensure timely arrivals and departures.</p>
                            <p>We have a number of business ridesharing fleet all the time for our customers. Our fleet will provide you an ample and snug seats where you let out all your hassle and get relaxed. It provides a best business model ridesharing service. It is fantastic and highly recommended. We offer a series of luxury fleet in London. With our luxury fleets you travel long distances comfortably. Our fleets are a symbol of grace and modern luxury. It is super perfect for any sort of business travels and corporate events.</p>
                        </div>
                        <Row 
                            gutter={[12,24]}
                            data-aos="zoom-in"
                            data-aos-duration="500" 
                            data-aos-once="true"
                            data-aos-delay="300"
                            >
                            <Col sm={{span:12}} xs={{span:24}}>
                                <Space>
                                    <img 
                                        src='/luxride/checked.png' 
                                        width={'20px'} 
                                        alt='epic-ride-london'
                                        />
                                    <Title level={5} className='my-0'>Luxury Fleet.</Title>
                                </Space>
                            </Col>
                            <Col sm={{span:12}} xs={{span:24}}>
                                <Space>
                                    <img 
                                        src='/luxride/checked.png' 
                                        width={'20px'} 
                                        alt='epic-ride-london'
                                        />
                                    <Title level={5} className='my-0'>Professional Chauffeur.</Title>
                                </Space>
                            </Col>
                            <Col sm={{span:12}} xs={{span:24}}> 
                                <Space>
                                    <img 
                                        src='/luxride/checked.png' 
                                        width={'20px'} 
                                        alt='epic-ride-london'
                                        />
                                    <Title level={5} className='my-0'>Symbol of grace and modern luxury.</Title>
                                </Space>
                            </Col>
                            <Col sm={{span:12}} xs={{span:24}}>
                                <Space>
                                    <img 
                                        src='/luxride/checked.png'
                                        width={'20px'} 
                                        alt='epic-ride-london'
                                        />
                                    <Title level={5} className='my-0'>Pre-Booking & Online Payment.</Title>
                                </Space>
                            </Col>
                        </Row>
                    </Space>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default LuxrideRegion