import React from 'react'
import { Col, Row, Typography, Space, Image } from 'antd'

const { Title } = Typography;

export const EventTransferServices = () => {
  return (
    <div className='py-5 justify-center'>
        <div className='width-93'>
            <Row gutter={[32,32]}>
                <Col lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}}>
                    <Space direction='vertical' size={30}>
                        <div>
                            <Title 
                                level={4} 
                                className='brand-color'
                                data-aos="fade-down"
                                data-aos-duration="800" 
                                data-aos-once="true"
                                data-aos-delay="300"
                                >
                                EXECUTIVE CHAUFFEUR SERVICE COMPANY
                            </Title>
                            <Title 
                                level={1} 
                                className='my-0'
                                data-aos="fade-up-right"
                                data-aos-duration="800" 
                                data-aos-once="true"
                                data-aos-delay="300"
                                >
                                Event Transfer Services:
                            </Title>
                        </div>
                        <Space 
                            direction='vertical'
                            data-aos="fade-right"
                            data-aos-duration="800" 
                            data-aos-once="true"
                            data-aos-delay="300"
                            >
                            <p>
                                When it comes to attending special events, we understand that arriving in style and on time is of utmost importance. Our event transfer services are tailored to meet your specific requirements, ensuring a seamless and luxurious transportation experience.
                            </p>
                            <p>
                                From corporate gatherings to social galas, our professional chauffeurs are dedicated to providing you with exceptional service. We pride ourselves on punctuality, reliability, and attention to detail, guaranteeing that you arrive at your event in a timely and elegant manner.
                            </p>
                            <p>
                                Our fleet of vehicles offers a range of options to suit your needs and preferences. Whether you prefer a sleek sedan or a spacious SUV, each vehicle is meticulously maintained and equipped with modern amenities to ensure your comfort throughout the journey.
                            </p>
                            <p>
                                With our event transfer services, you can focus on enjoying the occasion while we take care of your transportation needs. Sit back, relax, and let us handle the logistics, making sure you make a grand entrance and leave a lasting impression. Trust us to elevate your event experience.
                            </p>
                        </Space>
                    </Space>
                </Col>
                <Col 
                    lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}} 
                    data-aos="fade-left"
                    data-aos-duration="800" 
                    data-aos-once="true"
                    data-aos-delay="300"
                    >
                    <div style={{height:"100%", width:'100%'}} className='brand-bg-blue p-3 py-5 center'>
                        <Image 
                            src='/luxride/Event-Transfer-Service.png' 
                            alt='epic-ride-london' 
                            className="img" 
                            width={'100%'} 
                            preview={false}
                            data-aos="zoom-in"
                            data-aos-duration="800" 
                            data-aos-once="true"
                            data-aos-delay="300"
                            />
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}