import { Typography } from "antd";
const {Title, Text} =Typography
const SectionTop=({title, description, description2, left})=>
    {
        return(
            <div className={left?'mb-5 py-3 flex-col':'mb-5 py-3 flex-col-center'}>
                <Title 
                    level={4} 
                    style={{textTransform:'uppercase'}} 
                    className='brand-color mb-1 text-center'
                    >
                    {title}
                </Title>
                {
                    description ?
                    <Title level={1} className='my-0 text-center'>{description}</Title>
                    :''
                }
                {
                    description2 ?
                    <Title level={4}  className='my-0 text-center description-2'>{description2}</Title>
                    : ''
                }
                <div className='mt-1 d-flex' style={{width:"150px"}}>
                    <span style={{width:"90%"}} className="me-2 brand-border"></span>
                    <span style={{width:"10%"}} className="secondary-border"></span>
                </div>
            </div>    
        )
    }
export {SectionTop};