import React from 'react'
import {Space, Typography, Image } from 'antd'
import '../index.css'

const { Title, Text } = Typography

const LeftCard = () => {

    const data = {
        title:"why book with epic ride london",
        subtitle:"why",
        subtext:[
            {
                imgicon:"money.png",
                btext:"all inclusive pricing",
                stext:"Unbeatable pricing."
            },
            {
                imgicon:"support.png",
                btext:"dedicated customer support",
                stext:"Yes 24/7 dedicated support"
            },
            {
                imgicon:"pound-sterling.png",
                btext:"lowest price guarantee",
                stext:"Insured cars and afforgable pricing."
            },
            {
                imgicon:"free.png",
                btext:"free cancellation before 24 hours",
                stext:"100% free online booking system to calculate your ride in few sec."
            },
        ]
    }
  return (
    <div 
        className='brand-bg bg-lines pad'
        data-aos="fade-up"
        data-aos-duration="500" 
        data-aos-once="true"
        data-aos-delay="300"
        >
        <Space className='w-100' direction='vertical'>
            <Space direction='vertical' size={4} className='mb-4'>
                <Title level={4} className='brand-color-blue text-uppercase my-0'>
                    {
                        data?.subtitle
                    }
                </Title>
                <Title level={1} className='text-uppercase my-0'>
                    {
                        data?.title
                    }
                </Title>
            </Space>
            <Space direction='vertical' size={18}>
                {
                    data?.subtext?.map((subt, t)=>
                    <Space size={15} key={t}>
                        <Image 
                            src={'/luxride/icons/'+subt?.imgicon} 
                            alt='epic-ride-london' 
                            width={50}
                            preview={false} 
                            />
                        <Space direction='vertical' size={0}>
                            <Title level={4} className='text-uppercase my-0'>
                                {
                                    subt?.btext
                                }
                            </Title>
                            <Text strong>
                                {
                                    subt?.stext
                                }
                            </Text>
                        </Space>
                    </Space>
                    )
                }
                </Space>
        </Space>
    </div>
  )
}

export default LeftCard