import React from 'react';
import { Col, Row, Space, Typography, Image, Button, Divider } from 'antd';
import './luxridefooter.css';
import {
    EnvironmentOutlined
} from '@ant-design/icons';

const { Title, Text } = Typography;

const LuxrideFooter = () => {
    const data = 
    {
        btnContent:[
            {
                img:'apple.png',
                text1:'Download on the',
                text2: 'Apple Store',
            },
            {
                img:'google-play.png',
                text1:'Get in on',
                text2: 'Google Play',
            },
        ],
        linkfoot:[
            {
                linkhead:"Company",
                linkitems:[
                    {
                        linkitem: 'About us',
                    },
                    {
                        
                        linkitem: 'Our offering',
                    },
                    {
                        
                        linkitem: 'Newsroom',
                    },
                    {
                        
                        linkitem: 'Investors',
                    },
                    {
                        
                        linkitem: 'Blog',
                    },
                    {
                        
                        linkitem: 'Careers',
                    },
                    {
                        
                        linkitem: 'Gift cards',
                    },
                ]
            },
            {
                linkhead:"Top Cities",
                linkitems:[
                    {
                        linkitem: 'New York',
                    },
                    {
                        
                        linkitem: 'London',
                    },
                    {
                        
                        linkitem: 'Berlin',
                    },
                    {
                        
                        linkitem: 'Los Angeles',
                    },
                    {
                        
                        linkitem: 'Paris',
                    },
                ]
            },
            {
                linkhead:"Explore",
                linkitems:[
                    {
                        linkitem: 'Intercity rides',
                    },
                    {
                        
                        linkitem: 'Limousine service',
                    },
                    {
                        
                        linkitem: 'Chauffeur service',
                    },
                    {
                        
                        linkitem: 'Private car service',
                    },
                    {
                        
                        linkitem: 'Ground transportation',
                    },
                    {
                        
                        linkitem: 'Airport transfer',
                    },
                ]
            },
            {
                linkhead:"Classes",
                linkitems:[
                    {
                        linkitem: 'Business',
                    },
                    {
                        
                        linkitem: 'First',
                    },
                    {
                        
                        linkitem: 'XL',
                    },
                    {
                        
                        linkitem: 'Assistant',
                    },
                ]
            },
        ]

    }
  return (
    <div className='footerCss dflex'>
        <div className='width93'>
            <Row gutter={[32,24]} style={{justifyContent:"space-between"}}>
                <Col span={24}>
                    <Row gutter={24} style={{alignItems:"center"}}>
                        <Col xs={20} sm={20} md={20} lg={12}>
                            <Space size={40} style={{alignItems:"center"}} className='spaceWrap gapless'>
                                <Image 
                                    src='/luxride/logo.png' 
                                    style={{width:"150px"}} 
                                    preview={false} 
                                    alt='epic-ride-london'
                                    />
                                <Space>
                                    <Image 
                                        src='/luxride/phone-call.png' 
                                        alt='epic-ride-london'
                                        preview={false}
                                        />
                                    <Text className='text-white'>+41 22 715 7000</Text>
                                </Space>
                            </Space>
                        </Col>
                        <Col xs={22} sm={22} md={20} lg={12}>
                            <Space size={20} className='followDiv'>
                                <ul className='ul-list'>
                                    <li>
                                        <a href="/">
                                            <Image 
                                                src='/luxride/fb.png' 
                                                preview={false} 
                                                alt='epic-ride-london'
                                                />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <Image 
                                                src='/luxride/twitter.png' 
                                                preview={false} 
                                                alt='epic-ride-london'
                                                />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <Image 
                                                src='/luxride/instagram.png' 
                                                preview={false} 
                                                alt='epic-ride-london'
                                                />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <Image 
                                                src='/luxride/linkedin.png' 
                                                preview={false} 
                                                alt='epic-ride-london'
                                                />
                                        </a>
                                    </li>
                                </ul>
                            </Space>
                        </Col>
                    </Row>
                </Col>
                <Divider className='dividerCss' />
                <Col lg={20}>
                    <Row gutter={24}>
                        {
                            data?.linkfoot?.map((linkfo,lkf)=>
                                <Col xs={12} sm={12} md={12} lg={6} key={lkf}>
                                    <Title level={5} className='text-white'>{linkfo?.linkhead}</Title>
                                    <ul className='ul-foot'>
                                        {
                                            linkfo?.linkitems?.map((linkitms,lki)=>
                                                <li key={lki}>
                                                    <a href="/">
                                                        {linkitms?.linkitem}
                                                    </a>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </Col>
                            )
                        }
                    </Row>
                </Col>
                <Col lg={4}>
                    <Space size={20} direction='vertical' style={{width:"100%",justifyContent:"end"}}>
                        <Title level={5} className='text-white'>Download The App</Title>
                        {
                            data?.btnContent?.map((btnc,btc)=>
                            <Button className='appBtn' key={btc}>
                                <Space style={{width:"100%",textAlign:"center"}} size={10} align='center' >                                
                                    <span style={{paddingRight:"10px"}}>
                                        <Image 
                                            src={'/luxride/'+btnc?.img} 
                                            preview={false} 
                                            alt='epic-ride-london'
                                            />
                                    </span>
                                    <div className='horzontalLine'></div>
                                    <Space direction='vertical' size={0} className='btnText '>
                                        <span>{btnc?.text1}</span>
                                        <span>{btnc?.text2}</span>
                                    </Space>
                                </Space>
                            </Button>
                            )
                        }
                    </Space>
                </Col>
                <Divider className='dividerCss' />
                <Col span={24}>
                    <Row>
                        <Col xs={24} sm={24} md={12} lg={14}>
                            <Space size={20} style={{width:"100%"}} className="lastCopy">
                                <Title level={5} className='text-white' style={{marginBottom:"0px"}}>&#169; 2022 Repla</Title>
                                <ul className='ul-last'>
                                    <li>
                                        <a href="/">
                                            Terms 
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            Privacy policy
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            Legal notice
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            Accessibility
                                        </a>
                                    </li>
                                </ul>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={10}>
                            <Space size={15} style={{justifyContent:"end",width:"100%"}}>
                                <Space style={{width:"100%",alignItems:"center"}}>
                                    <EnvironmentOutlined className='text-white' />
                                    <Text className='text-white'>New York</Text>
                                </Space>
                                <Button className='btnLanguage'>
                                    <Space size={10}>
                                        <Image 
                                            src='/luxride/worldwide.png' 
                                            preview={false} 
                                            alt='epic-ride-london'
                                            />
                                        <Text className='text-white'>English</Text>
                                    </Space>
                                </Button>
                            </Space>
                        </Col>                    
                    </Row>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default LuxrideFooter