import { message } from "antd"

const emailJourneyDetails=(data)=>{
    var myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/json")
    var requestOptions = {
      headers: myHeaders,
      method: 'POST',
      body:JSON.stringify(data)
    }
    return (
      fetch('https://epicridelondonbackend.epicridelondon.com/api/SendInvoiceMail',requestOptions)
      .then(response=>response.json())
      .then(result=>{
        console.log(result)
        console.log(data)
        if(result?.success)
          { 
            message.success(result?.message+". please check email")
            return 1
          }
        else
            throw result?.message
      })
      .catch((error)=>{
        message.success(error)
        return 0
      })
    )
  }
  export {emailJourneyDetails}