import React from 'react'
import { Row, Col, Typography } from 'antd'
import './luxridesoutham.css'

const { Title, Text } = Typography;

const LuxrideSouthamex = () => {
  return (
    <div className='dflex bgex'>
        <div className='width93'>
            <Row gutter={[64,24]} style={{alignItems:"center"}}>
                <Col span={24}>
                    <div className='innerDiv-ex'>
                        <Title level={2} className='text-white' style={{marginBottom:"0px"}}>Executive Chauffeur Service at Pegasus Chauffeurs</Title>
                        <Text className='text-white'>
                            Based in London UK, at Pegasus Chauffeurs, our mission is to exceed the expectations of its clients while providing them transfer solutions at highly affordable pricing. To make the transfer services even more convenient, we are introducing online booking and some complementary features for you.
                        </Text>
                        <Text className='text-white'>
                            Our mission is to provide you an amazing memory for life. With us, it won’t be just a regular trip but an experience that you’ll cherish for years to come.
                        </Text>
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default LuxrideSouthamex