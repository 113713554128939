import React, { useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";
import { Form, DatePicker, Row, Col, TimePicker, AutoComplete, Typography, Space, Button, Select } from 'antd';
import { PlusOutlined, MinusOutlined} from '@ant-design/icons';
import { getLocations, distanceMatrix } from './apiCalls';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { actionsApi } from '../../shared';
import { CitiesDateTimePicker } from '../../ui-elements';
const {Text}= Typography

const Tab1 = ({lightGreyBg}) => {
    
    const [form] = Form.useForm()
    const navigate = useNavigate();
    const dispather= useDispatch();
    const {epicRide}= useSelector(state=>state)
    const [options, setOptions]=useState(null)
    const [state, setState]= useState(null)
    const [loading, setLoading]= useState(false)

  useEffect(()=>{
    setState({
        pickupDate: moment(epicRide?.pickupDate? new Date(epicRide?.pickupDate) : new Date()),
        pickupTime: moment(epicRide?.pickupTime? new Date(epicRide?.pickupDate + " "+ epicRide?.pickupTime) : new Date()),
        travelMode: epicRide?.travelMode,
        wayTo: {
            pickupLocations: [epicRide?.wayTo?.pickupLocation, ...epicRide?.wayTo?.wayPoints],
            wayPoints: [],
            dropOffLocation: epicRide?.wayTo?.dropOffLocation,
            hours: epicRide?.wayTo?.hours
        },
        return: {
            pickupLocations: [epicRide?.return?.pickupLocation, ...epicRide?.return?.wayPoints],
            wayPoints: [],
            dropOffLocation: epicRide?.return?.dropOffLocation,
            hours: epicRide?.wayTo?.hours
        }
    })
    setOptions(epicRide?.options)
  }, [])
  const addLocation=(way, key)=>{

    state[way][key]=[...state[way][key],'']
    setState({...state})

    options[way][key]=[...options[way][key],'']
    setOptions({...options})
  }
  const removeLocation=(way, key, index)=>{
    state[way][key]=state[way][key]?.filter((_, i)=> i!==index)
    setState({...state})   
    //
    options[way][key]=options[way][key]?.filter((_, i)=> i!==index)
    setOptions({...options})   
  }
 
  const nextStep=async ()=>{
    setLoading(true)
    let returnCoordinates=[]
    let coordinates=state?.wayTo?.pickupLocations?.map(location=>{
        let index= -1
        for(let i=0;i< options?.wayTo?.pickupLocations?.length;i++)
        {
            index= options?.wayTo?.pickupLocations[i]?.findIndex(_=> _?.value===location)
            if(index>-1)
              {
                return options?.wayTo?.pickupLocations[i][index]?.coordinates
                break
              }
        }
    })
    coordinates=[
        ...coordinates, 
        options?.wayTo?.dropOffLocation[options?.wayTo?.dropOffLocation?.findIndex(_=>_.value===state?.wayTo?.dropOffLocation)]?.coordinates
    ] 
    if(state?.travelMode===2)
    {
        returnCoordinates=state?.return?.pickupLocations?.map(location=>{
            let index= -1
            for(let i=0;i< options?.return?.pickupLocations?.length;i++)
            {
                index= options?.return?.pickupLocations[i]?.findIndex(_=> _?.value===location)
                if(index>-1)
                  {
                    return options?.return?.pickupLocations[i][index]?.coordinates
                    break
                  }
            }
        })
        returnCoordinates=[
            ...returnCoordinates, 
            options?.return?.dropOffLocation[options?.return?.dropOffLocation?.findIndex(_=>_.value===state?.return?.dropOffLocation)]?.coordinates
        ] 
    }
    let distance=0
    let duration=0
    if(epicRide.serviceType===1)
    {
        let result = await distanceMatrix(coordinates?.join(";"))
        for(let i=0;i<(result?.distances?.length-1);i++)
        {
            distance+=result?.distances[i][i+1]
        }
        for(let i=0;i<(result?.durations?.length-1);i++)
        {
            duration+=result?.durations[i][i+1]
        }
        if(returnCoordinates?.length)
        {
            result = await distanceMatrix(returnCoordinates?.join(";"))
            for(let i=0;i<(result?.distances?.length-1);i++)
            {
                distance+=result?.distances[i][i+1]
            }
            for(let i=0;i<(result?.durations?.length-1);i++)
            {
                duration+=result?.durations[i][i+1]
            }
        }
    }
    let tempArr
    tempArr={
        // Duration: epicRide?.serviceType===2?state?.wayTo?.hours:null,
        pickupDate: moment(state?.pickupDate).format("MM-DD-YYYY"),
        pickupTime: moment(state?.pickupTime).format("HH:mm"),
        travelMode: state?.travelMode,
        wayTo: {
            ...state?.wayTo,
            pickupLocation: state?.wayTo?.pickupLocations[0],
            wayPoints: state?.wayTo?.pickupLocations?.filter((_,i)=>i>0)
        },
        return: {
            ...state?.return,
            pickupLocation: state?.return?.pickupLocations[0],
            wayPoints: state?.return?.pickupLocations?.filter((_,i)=>i>0)
        },
        totalDistance:epicRide?.serviceType===2?null:(distance * 0.000621371).toFixed(2),
        totalTime:epicRide?.serviceType===2? state?.wayTo?.hours : (moment.utc(duration*1000).format('HH:mm')),
        options
    }
    dispather(actionsApi?.updateEpicRide(tempArr))
    setLoading(false)
    navigate("/booking")
  }

  const handleChange=(way, key, index, value)=>{
        if(key=== "dropOffLocation")
            state[way][key]=value
        else
        state[way][key]=state[way][key]?.map((_,i)=>{
            if(i===index)
            return value
            return _
        })
        setState({...state})
  }
  const handleState=(key, value, way)=>{
    if(way)
        state[way].hours=value
    setState({
        ...state,
        [key]:value
    })
  }
  const handleOptions=(way, key, index, value)=>{
    if(key=== "dropOffLocation")
        setOptions({
            ...options,
            [way]: {
                ...options[way],
                dropOffLocation: value
            }
        })
    else
        setOptions({
            ...options,
            [way]: {
                ...options[way],
                pickupLocations: options[way]['pickupLocations']?.map((_,i)=>{
                    if(i===index)
                        return value
                    return _
                })
            }
        })
  }
  console.log("state:", options)
  return (
    <div style={{width:'100%'}} >
        <Space direction='vertical' style={{width:'100%'}}>
            <Form
                layout='vertical'
                className={(lightGreyBg?'light-grey-bg':'bg-white form-height')+' px-3 py-4'}
                form={form}
            >
                <Row gutter={[12,12]}>
                    <Col sm={{span:12}} xs={{span:24}}>
                        <Form.Item
                            label={<Text strong>Pickup date</Text>}
                           
                        >
                            <DatePicker 
                                style={{width:'100%'}}
                                placement='topLeft'
                                size='large'
                                value={state?.pickupDate?moment(state?.pickupDate):null}
                                onChange={(value)=>{handleState('pickupDate', value)}}
                                />
                        </Form.Item>
                    </Col>
                    <Col sm={{span:12}} xs={{span:24}}>
                        <Form.Item
                            label={<Text strong>Pickup time</Text>}
                           
                        >
                            <TimePicker 
                                style={{width:'100%'}}
                                placement='topLeft'
                                size='large'
                                format={'HH:mm'}
                                value={state?.pickupTime?moment(state?.pickupTime):null}
                                onSelect={(value)=>{handleState('pickupTime', value)}}
                                />
                        </Form.Item>
                    </Col>
                    <Col sm={{span:0}} xs={{span:0}}>
                        <Form.Item
                            label={<Text strong>Travel mode</Text>}
                            
                        >
                            <Select
                                value={state?.travelMode}
                                size='large'
                                onChange={(value)=> handleState('travelMode', value)}
                            >
                                <Select.Option value={1}>One Way</Select.Option>
                                <Select.Option value={2} disabled>two Way</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Space>
                            <Text strong>Pickup Locations</Text>
                            <Button type='primary' size='small' shape='circle' onClick={()=>{addLocation('wayTo','pickupLocations')}}>
                                <PlusOutlined className='up' style={{fontSize:'12px'}}/>
                            </Button>
                        </Space>
                    </Col>
                    {/* {
                        state?.pickupLocations?.map((location, index)=>
                            <Col sm={{span:12}} xs={{span:24}} key={'pickupLocation-'+index}>
                                <Form.Item
                                    label={
                                        <Space>
                                            <Text strong>Pickup location</Text>
                                            {
                                                index > 0 &&
                                                <Button type='primary' size='small' shape='circle' onClick={()=>{removeLocation('pickupLocations', index)}}>
                                                    <MinusOutlined className='up' style={{fontSize:'12px'}}/>
                                                </Button>
                                            }
                                        </Space>
                                    }
                                    name='pickup'
                                    >
                                    <AutoComplete
                                        options={options?.pickupLocations[index]}
                                        value={location || ''}
                                        onSelect={(value)=>{
                                            handleChange("pickupLocations", index, value) 
                                        }}
                                        onChange={
                                            async (value)=>{
                                                handleChange("pickupLocations", index, value)
                                                let result=await getLocations(value)
                                                handleOptions("pickupLocations", index, [...result])
                                            }
                                        }
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>
                        )
                    } */}
                    {
                        state?.wayTo?.pickupLocations?.map((location, index)=>
                            <Col sm={{span:12}} xs={{span:24}} key={'pickupLocation-'+index}>
                                <Form.Item
                                    label={
                                        <Space>
                                            <Text strong>Pickup location</Text>
                                            {
                                                index > 0 &&
                                                <Button type='primary' size='small' shape='circle' onClick={()=>{removeLocation('wayTo','pickupLocations', index)}}>
                                                    <MinusOutlined className='up' style={{fontSize:'12px'}}/>
                                                </Button>
                                            }
                                        </Space>
                                    }
                                    >
                                    <AutoComplete
                                        options={options?.wayTo?.pickupLocations[index]}
                                        value={location || ''}
                                        onSelect={(value)=>{
                                            handleChange("wayTo", "pickupLocations", index, value) 
                                        }}
                                        onChange={
                                            async (value)=>{
                                                handleChange("wayTo", "pickupLocations", index, value)
                                                let result=await getLocations(value)
                                                handleOptions("wayTo", "pickupLocations", index, [...result])
                                            }
                                        }
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>
                        )
                        
                    }
                    {
                        // epicRide?.serviceType===1 &&
                        <Col sm={{span:12}} xs={{span:24}}>
                            <Form.Item
                                label={
                                    <Text strong>Drop-off location</Text>
                                }
                                >
                                <AutoComplete
                                    options={options?.wayTo?.dropOffLocation}
                                    value={state?.wayTo?.dropOffLocation || ''}
                                    onSelect={(value)=>{
                                        handleChange("wayTo", "dropOffLocation", -1, value) 
                                    }}
                                    onChange={ async (value)=>{
                                            handleChange("wayTo", "dropOffLocation", -1, value)
                                            let result=await getLocations(value)
                                            handleOptions("wayTo", "dropOffLocation", -1, [...result])
                                        }
                                    }
                                    size='large'
                                    />
                            </Form.Item>
                        </Col>
                    }
                    {
                        epicRide?.serviceType===2 &&
                        <Col sm={{span:12}} xs={{span:24}}>
                            <Form.Item
                                label={<Text strong>DURATION (IN HOURS)</Text>}
                            >
                                <Select 
                                    value={state?.wayTo?.hours}
                                    onChange={(value)=>{handleState('hours', value, 'wayTo')}}
                                    size='large'
                                    >
                                    {
                                        new Array(22)?.fill(null)?.map((_, index)=>
                                            <Select.Option value={index+3} key={'duration-'+index}>
                                                <Text strong>{index+3} hour(s)</Text>
                                            </Select.Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    }
                    {
                        state?.travelMode===2 &&
                        <Col span={24}>
                            <Space>
                                <Text strong>Pickup Locations (return)</Text>
                                <Button type='primary' size='small' shape='circle' onClick={()=>{addLocation('return','pickupLocations')}}>
                                    <PlusOutlined className='up' style={{fontSize:'12px'}}/>
                                </Button>
                            </Space>
                        </Col>
                    }
                    {
                        state?.travelMode===2 &&
                        state?.return?.pickupLocations?.map((location, index)=>
                            <Col sm={{span:12}} xs={{span:24}} key={'pickupLocation-'+index}>
                                <Form.Item
                                    label={
                                        <Space>
                                            <Text strong>Pickup location</Text>
                                            {
                                                index > 0 &&
                                                <Button type='primary' size='small' shape='circle' onClick={()=>{removeLocation('return','pickupLocations', index)}}>
                                                    <MinusOutlined className='up' style={{fontSize:'12px'}}/>
                                                </Button>
                                            }
                                        </Space>
                                    }
                                    >
                                    <AutoComplete
                                        options={options?.return?.pickupLocations[index]}
                                        value={location || ''}
                                        onSelect={(value)=>{
                                            handleChange("return", "pickupLocations", index, value) 
                                        }}
                                        onChange={
                                            async (value)=>{
                                                handleChange("return", "pickupLocations", index, value)
                                                let result=await getLocations(value)
                                                handleOptions("return", "pickupLocations", index, [...result])
                                            }
                                        }
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>
                        )
                        
                    }
                    {
                        state?.travelMode===2 && epicRide?.serviceType===1 &&
                        <Col sm={{span:12}} xs={{span:24}}>
                            <Form.Item
                                label={
                                    <Text strong>Drop-off location</Text>
                                }
                                >
                                <AutoComplete
                                    options={options?.return?.dropOffLocation}
                                    value={state?.return?.dropOffLocation || ''}
                                    onSelect={(value)=>{
                                        handleChange("return", "dropOffLocation", -1, value) 
                                    }}
                                    onChange={ async (value)=>{
                                            handleChange("return", "dropOffLocation", -1, value)
                                            let result=await getLocations(value)
                                            handleOptions("return", "dropOffLocation", -1, [...result])
                                        }
                                    }
                                    size='large'
                                    />
                            </Form.Item>
                        </Col>
                    }
                    {
                        state?.travelMode===2 && epicRide?.serviceType===2 &&
                        <Col sm={{span:12}} xs={{span:24}}>
                            <Form.Item
                                label={<Text strong>DURATION (IN HOURS)</Text>}
                            >
                                <Select 
                                    value={state?.return?.hours}
                                    onChange={(value)=>{handleState('hours', value, 'return')}}
                                    size='large'
                                    >
                                    {
                                        new Array(22)?.fill(null)?.map((_, index)=>
                                            <Select.Option value={index+3} key={'duration-'+index}>
                                                <Text strong>{index+3} hour(s)</Text>
                                            </Select.Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    }
                    <Col span={24} className='justify-end'>
                        <Button
                            type='primary'
                            size='large'
                            onClick={nextStep}
                            loading={loading}
                            // htmlType='submit'
                            >
                            Calculate & Pay
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Space>
    </div>
  )
}

export default Tab1