import React, {useEffect, useState} from 'react'
import {Space, Typography, Image, Card, Button } from 'antd'
import './luxridefleet.css'
import {SectionTop} from '../../ui-elements/sectionTop'
import Slider from 'react-slick'
import {
    UserOutlined,
    LockOutlined,
} from '@ant-design/icons'
import { vehicles } from '../../Data/Vechicles'
import { useNavigate } from 'react-router-dom'
const { Title,Text } = Typography;
const LuxrideFleet = ({Data}) => {
    const navigate= useNavigate()
    var settings = {
        infinite: true,
        speed: 700,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        dots:false,
        responsive: [
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    return (
    <div>
        <div className={"light-bg py-5"} style={{overflowX:'hidden'}}>
            <div className='flex-col-center'>
                <SectionTop 
                    description={Data?.description || 'Our fleets includes Mercedes, Bentley and Range Rovers.'}
                    description2={Data?.description2 ||""}
                    title={Data?.title || 'Our Fleets'}
                    />
                <div style={{width:'93%'}}>
                <Slider {...settings}>
                    {
                        vehicles?.map((sliderd,index)=>
                            <div key={index}>
                                <Card 
                                    data-aos-delay={300*(index+1)}
                                    data-aos-duration={500}
                                    data-aos-once="true"
                                    data-aos="fade-up-right"
                                    className=' bg-white me-3 border-0'
                                    style={{minHeight:'100%'}}
                                    >
                                    <Space direction='vertical' style={{width:"100%"}}>
                                        <div className='center' style={{height:'191px'}}>
                                            <Image 
                                                src={'/luxride/'+sliderd?.image2} 
                                                preview={false} 
                                                alt='epic-ride-london'
                                                style={{maxHeight:index?'210px':'191px'}}
                                                />
                                        </div>
                                        <Space  direction='vertical'>
                                            <Title level={4} className='brand-color mb-1'>
                                                {
                                                    sliderd?.tag
                                                }
                                            </Title>
                                            <Title level={3}>
                                                {
                                                    sliderd?.name
                                                }
                                            </Title>
                                        </Space>
                                        <div className='space-between'>
                                            {
                                                index<=3?
                                                <Text strong className='brand-color-blue'>
                                                    {
                                                        'From '+sliderd?.pricePerHour
                                                    }
                                                </Text>
                                                :
                                                <Text strong className='brand-color-blue'>
                                                    Contact Us
                                                </Text>
                                            }
                                            <Space size={13}>
                                                <Space>
                                                    <UserOutlined className='brand-color up'/>
                                                    <Text strong>{sliderd?.passenger} Passenger</Text>
                                                </Space>
                                                <Space>
                                                    <LockOutlined className='brand-color up'/>
                                                    <Text strong>{sliderd?.bags} Bags</Text>
                                                </Space>
                                            </Space>
                                        </div>
                                        <div className='justify-end'>
                                            {
                                                index<=3?
                                                <Button 
                                                    type='primary'
                                                    onClick={()=> navigate("/booking")}
                                                    >
                                                    Book Now
                                                </Button>:
                                                <Button 
                                                    type='primary'
                                                    onClick={()=> navigate("/contact-us")}
                                                    >
                                                    Contact Us
                                                </Button>
                                            }
                                        </div>
                                    </Space>
                                </Card>
                            </div>
                        )
                    }
                </Slider>
                </div>
            </div>
        </div>     
    </div>
  )
}


export default LuxrideFleet