import React from 'react'
import { Col, Row, Typography, Space, Image } from 'antd'

const { Title } = Typography;

export const ExecutiveTransferServices = () => {
  return (
    <div className='py-5 justify-center'>
        <div className='width-93'>
            <Row gutter={[32,32]}>
                <Col lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}}>
                    <Space direction='vertical' size={30}>
                        <div>
                            <Title 
                                level={4} 
                                className='brand-color'
                                data-aos="fade-down"
                                data-aos-duration="800" 
                                data-aos-once="true"
                                data-aos-delay="300"
                                >
                                EXECUTIVE CHAUFFEUR SERVICE COMPANY
                            </Title>
                            <Title 
                                level={1} 
                                className='my-0'
                                data-aos="fade-up-right"
                                data-aos-duration="800" 
                                data-aos-once="true"
                                data-aos-delay="300"
                                >
                                Executive Transfer Services:
                            </Title>
                        </div>
                        <Space 
                            direction='vertical'
                            data-aos="fade-right"
                            data-aos-duration="800" 
                            data-aos-once="true"
                            data-aos-delay="300"
                            >
                            <p>
                                Our executive transfer services are designed to cater to the discerning needs of business professionals and corporate clients. We understand that time is of the essence, and every minute counts when it comes to executive travel. With our impeccable service and attention to detail, we ensure that your executive transfers are seamless, efficient, and comfortable.
                            </p>
                            <p>
                                Our team of highly trained chauffeurs is well-versed in providing top-notch service to executives. They are experienced in navigating the city's busiest streets, ensuring prompt arrivals and departures for your important meetings, conferences, or corporate events.
                            </p>
                            <p>
                                Our fleet of executive vehicles exudes sophistication and professionalism. From luxurious sedans to spacious SUVs, each vehicle is equipped with advanced amenities and features, providing a comfortable and productive environment for executives on the move.
                            </p>
                            <p>
                                We prioritize confidentiality, professionalism, and reliability in all our executive transfers. Trust us to handle your transportation needs with the utmost care, allowing you to focus on your business objectives while we take care of the logistics. Experience the epitome of executive travel with our dedicated executive transfer services.
                            </p>
                        </Space>
                    </Space>
                </Col>
                <Col 
                    lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}} 
                    data-aos="fade-left"
                    data-aos-duration="800" 
                    data-aos-once="true"
                    data-aos-delay="300"
                    >
                    <div style={{height:"100%", width:'100%'}} className='brand-bg-blue p-3 py-5 center'>
                        <Image 
                            src='/luxride/Executive Transfer Services.jpg' 
                            alt='epic-ride-london' 
                            className="img" 
                            width={'100%'} 
                            preview={false}
                            data-aos="zoom-in"
                            data-aos-duration="800" 
                            data-aos-once="true"
                            data-aos-delay="300"
                            />
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}