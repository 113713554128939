import React from 'react'
import { Row, Col, Space, Typography } from 'antd';

const { Title, Text } = Typography;

const LuxrideSouthamDecide = () => {
  return (
    <div className='dflex decide-bg'>
        <div className='width93'>
            <Row>
                <Col span={24}>
                    <Space style={{width:"100%",justifyContent:"center",textAlign:"center"}}>
                        <Title level={1} className='text-white'>
                            You can decide exactly which car you want and our chauffeur will drive it to you.
                        </Title>
                    </Space>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default LuxrideSouthamDecide