import React from 'react'
import { Row, Col, Typography, Card, Space } from 'antd'
import './luxridesoutham.css'

const { Title, Text } = Typography;


const LuxrideSouthamLinefleet = () => {

    const data = {
        title:"Our Top of the Line Fleet",
        desc:"Our top of the line fleet includes:",
        cardData:[
            {
                img:"e-class-m.jpg",
                innertitle:"Mercedes Benz - E Class",
                desccard: <span>For years, the Mercedes E Class is known as one of the best executive cars. It perfectly matches with the executive movements and airport transfers. The exterior is catchy while the interior is all <span className='text-primary'>comfy and luxurious</span>. With the comfortable seats and our experienced chauffeurs, the rides are smooth as butter</span>
            },
            {
                img:"s-class-m.jpg",
                innertitle:"Mercedes Benz - S Class",
                desccard: <span>The S Class Mercedes has been a trademark, a standard for <span className='text-primary'>VIP</span> and <span className='text-primary'>luxury travel</span>. It has all the features of a modern day lavish vehicle and can be a great companion if you are looking to make a statement. The state of the art exterior captures the attention whereas the interior is just as if you are sitting on a comfortable couch.</span>
            },
            {
                img:"v-class-m.jpg",
                innertitle:"Mercedes Benz - V Class",
                desccard: <span>For long business trips, <span className='text-primary'>family outings</span>, and sightseeing, the Mercedes V Class is the perfect companion. It gives you the space you need for your luggage and you can easily go on journeys without tiring yourself. If you are moving with your family or have <span className='text-primary'>luggage to accommodate</span>, the V Class is perfect for you.</span>
            },
            {
                img:"range-rover.jpg",
                innertitle:"Range Rover",
                desccard: <span>We have a fleet that caters to the needs of everyone. So, for those who love SUVs, we provide the stunning Range Rover for your <span className='text-primary'>luxury transfer service in Southampton</span>. It is a great option for sports and events as it gives the vibes that can make you stand out from the crowd.</span>
            },
            {
                img:"bently.jpg",
                innertitle:"Bentley Mulsanne",
                desccard: <span>The name, Bentley Mulsanne, itself takes you on a ride to the land of pleasure and warmth. The vehicle is a staple for executive and corporate transfers. Make someone feel significant and exclusive by ordering a Mulsanne.</span>
            },
            {
                img:"rolls-royce-phan.jpg",
                innertitle:"Rolls Royce Phantom VIII",
                desccard: <span>Relaxing, satisfactory, classy, luxurious, exhilarating, peaceful, and what not? Rolls Royce Phantom VIII speaks for itself. It is perfect for business and celebrity transfers to and from London airports and hotels.</span>
            },
        ]
    }

  return (
    <div className='dflex bg-top-line' style={{marginTop:"30px"}}>
        <div className='width93'>
            <Row gutter={[32,32]} style={{justifyContent:"center"}}>
                <Col span={24}>
                    <Space size={0} direction='vertical' style={{width:"100%",justifyContent:"center",textAlign:"center"}}>
                        <Title level={2} className='text-white'>{data?.title}</Title>
                        <Text className='text-white' strong>{data?.desc}</Text>
                    </Space>
                </Col>
                {
                    data?.cardData?.map((cardd,cd)=>
                        <Col xs={24} sm={12} md={12} lg={8} xl={6} key={cd}>
                            <Card
                                hoverable
                                style={{background:"#000",borderRadius:"12px",border:"0px",height:"100%"}}
                                cover={
                                        <img  
                                            src={"/luxride/"+cardd?.img} 
                                            style={{borderTopLeftRadius:"12px",borderTopRightRadius:"12px"}} 
                                            alt='epic-ride-london'
                                            />
                                    }
                            >
                                <Space size={15} direction='vertical'>
                                    <Title level={4} className='text-white'>{cardd?.innertitle}</Title>
                                    <Text className='text-white'>
                                        {cardd?.desccard}
                                    </Text>
                                </Space>
                            </Card>
                        </Col>
                    )
                }
            </Row>
        </div>
    </div>
  )
}

export default LuxrideSouthamLinefleet