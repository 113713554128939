import React from 'react'
import { Col, Row, Typography, Space, Image } from 'antd'

const { Title } = Typography;

export const AirportTransferServices = () => {
  return (
    <div className='py-5 justify-center'>
        <div className='width-93'>
            <Row gutter={[32,32]}>
                <Col lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}}>
                    <Space direction='vertical' size={30}>
                        <div>
                            <Title 
                                level={4} 
                                className='brand-color'
                                data-aos="fade-down"
                                data-aos-duration="800" 
                                data-aos-once="true"
                                data-aos-delay="300"
                                >
                                EXECUTIVE CHAUFFEUR SERVICE COMPANY
                            </Title>
                            <Title 
                                level={1} 
                                className='my-0'
                                data-aos="fade-up-right"
                                data-aos-duration="800" 
                                data-aos-once="true"
                                data-aos-delay="300"
                                >
                                Airport Transfer Services:
                            </Title>
                        </div>
                        <Space 
                            direction='vertical'
                            data-aos="fade-right"
                            data-aos-duration="800" 
                            data-aos-once="true"
                            data-aos-delay="300"
                            >
                            <p>
                                Our airport transfer services are designed to make your journey to and from the airport as seamless and stress-free as possible. We understand the importance of punctuality when it comes to catching a flight or arriving at your destination on time, which is why we prioritize reliability and efficiency.
                            </p>
                            <p>
                                With our dedicated team of professional chauffeurs and a fleet of luxurious vehicles, we ensure that you experience the utmost comfort and convenience throughout your journey. Our chauffeurs are highly trained, experienced, and familiar with the best routes to ensure timely arrivals and departures.
                            </p>
                            <p>
                                Whether you're traveling alone or with a group, we have a range of vehicles to accommodate your specific needs. From sleek sedans to spacious SUVs, each vehicle in our fleet is meticulously maintained and equipped with modern amenities, ensuring a smooth and enjoyable ride.
                            </p>
                            <p>
                                Sit back, relax, and let our airport transfer service take care of your transportation needs. We guarantee a hassle-free experience, allowing you to start or end your trip on the right note.
                            </p>
                        </Space>
                    </Space>
                </Col>
                <Col 
                    lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}} 
                    data-aos="fade-left"
                    data-aos-duration="800" 
                    data-aos-once="true"
                    data-aos-delay="300"
                    >
                    <div style={{height:"100%", width:'100%'}} className='brand-bg-blue p-3 py-5 center'>
                        <Image 
                            src='/luxride/airport-transfers.jpg' 
                            alt='epic-ride-london' 
                            className="img" 
                            width={'100%'} 
                            preview={false}
                            data-aos="zoom-in"
                            data-aos-duration="800" 
                            data-aos-once="true"
                            data-aos-delay="300"
                            />
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}