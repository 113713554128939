import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload, Form, Typography} from 'antd';
import React from 'react';
const props = {
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  headers: {
    authorization: 'authorization-text',
  },
  onChange(info) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status) {
      {
        // message.success(`${info.file.name} file uploaded successfully`)
      }
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
}
export const FileUpload = ({name, label, required, message, multiple}) => (
    <Form.Item
        name={name}
        label= {<Typography.Text strong>{label}</Typography.Text>}
        rules={[
          {
          required,
          message,
          },
        ]}
      >
        <Upload {...props} multiple={multiple || false}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
    </Form.Item>
)