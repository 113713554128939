import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useRef, useEffect, useState } from 'react';
import Map, { GeolocateControl } from "react-map-gl";

import "mapbox-gl/dist/mapbox-gl.css";
import "./index.css"
//mapboxgl.accessToken = 'pk.eyJ1IjoiYWJkdWxoYW5hbjE5OTgiLCJhIjoiY2xjaHpvMXprMDI0ZTNxbW1zaXhwczUycCJ9.15PM9FLDUYf9vGrz9uZ0xg';
const MapContainer=({height})=>{
    const mapContainer = useRef(null);
    const map = useRef(null);
 
   
    useEffect(() => {
       

        },[])
    return (
       
            <div style={{height:"400px", width:'100%'}}>
                <Map
                    mapboxAccessToken="MAPBOX_TOKEN"
                    initialViewState={{
                    longitude: -100,
                    latitude: 40,
                    zoom: 3.5,
                    }}
                    mapStyle="mapbox://styles/mapbox/streets-v12"
                >
                    <GeolocateControl
                    positionOptions={{ enableHighAccuracy: true }}
                    trackUserLocation={true}
                    />
                </Map>
            </div>
    )
}
export default MapContainer

