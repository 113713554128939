export const vehicles = [ 
    {
        vechicleID: 1,
        img:"car1.png",
        image2:"car13.png",
        name:"MERCEDES-BENZ E-CLASS",
        pricePerHour:'£55.00/Hour',
        prices:[85, 1.90, 1.70],
        hourlyPrice:55.00,
        bags:2,
        passenger:2,
        tag:'BUSINESS CLASS',
        desc:`No Hidden, Waiting, Airport Parking, Congestion/tolls Charges - & We Offer Free 60 Minute Waiting At Airport And 15 Minutes At Any Other Address.`,
        extraServices:[
            {
                icons:"glass.png",
                icontext:"Free water",
            },
            {
                icons:"hand-shake.png",
                icontext:"Meet & Greet",
            },
            {
                icons:"wifi.png",
                icontext:"WiFi available",
            },
            {
                icons:"passenger.png",
                icontext:"3 Passengers",
            },
            {
                icons:"history.png",
                icontext:"60 Min Airport Waiting",
            },
            {
                icons:"bags.png",
                icontext:"2 Bags",
            },
            {
                icons:"newspaper.png",
                icontext:"Newspaper",
            },
        ]  
    },
    {
        vechicleID: 3,
        img:"car3.png",
        image2:'car12.png',
        name:"MERCEDES-BENZ V-CLASS",
        pricePerHour:'£65.00/Hour',
        prices:[110, 1.90, 1.70],
        hourlyPrice:65.00,
        bags:4,
        passenger:7,
        tag:'BUSINESS CLASS',
        desc:`No Hidden, Waiting, Airport Parking, Congestion/tolls Charges - & We Offer Free 60 Minute Waiting At Airport And 15 Minutes At Any Other Address.`,
        extraServices:[
            {
                icons:"glass.png",
                icontext:"Free water",
            },
            {
                icons:"hand-shake.png",
                icontext:"Meet & Greet",
            },
            {
                icons:"wifi.png",
                icontext:"WiFi available",
            },
            {
                icons:"passenger.png",
                icontext:"7 Passengers",
            },
            {
                icons:"bags.png",
                icontext:"6 Bags",
            },
            {
                icons:"history.png",
                icontext:"60 Min Airport Waiting",
            },
            {
                icons:"newspaper.png",
                icontext:"Newspaper",
            },
        ]  
    }, 
    {
        vechicleID: 2,
        img:"car2.png",
        image2:'car11.png',
        name:"MERCEDES-BENZ S-CLASS",
        pricePerHour:'£70.00/Hour',
        prices:[120, 2.18, 2.10],
        hourlyPrice:70.00,
        bags:2,
        passenger:2,
        tag: "LUXURY CLASS",
        desc:`No Hidden, Waiting, Airport Parking, Congestion/tolls Charges - & We Offer Free 60 Minute Waiting At Airport And 15 Minutes At Any Other Address.`,
        extraServices:[
            {
                icons:"glass.png",
                icontext:"Free water",
            },
            {
                icons:"hand-shake.png",
                icontext:"Meet & Greet",
            },
            {
                icons:"wifi.png",
                icontext:"WiFi available",
            },
            {
                icons:"passenger.png",
                icontext:"3 Passengers",
            },
            {
                icons:"bags.png",
                icontext:"2 Bags",
            },
            {
                icons:"history.png",
                icontext:"60 Min Airport Waiting",
            },
            {
                icons:"newspaper.png",
                icontext:"Newspaper",
            },
        ]  
    },
    {
        vechicleID: 4,
        img:"car5.png",
        image2:'car19.png',
        name:"Range Rover",
        pricePerHour:'£130.00/Hour',
        prices:[215, 4.14, 3.92],
        hourlyPrice:130.00,
        bags:2,
        passenger:2,
        tag:"LUXURY CLASS",
        desc:`No Hidden, Waiting, Airport Parking, Congestion/tolls Charges - & We Offer Free 60 Minute Waiting At Airport And 15 Minutes At Any Other Address.`,
        extraServices:[
            {
                icons:"glass.png",
                icontext:"Free water",
            },
            {
                icons:"hand-shake.png",
                icontext:"Meet & Greet",
            },
            {
                icons:"wifi.png",
                icontext:"WiFi available",
            },
            {
                icons:"passenger.png",
                icontext:"3 Passengers",
            },
            {
                icons:"bags.png",
                icontext:"3 Bags",
            },
            {
                icons:"history.png",
                icontext:"60 Min Airport Waiting",
            }
        ]  
    }, 
    {
        vechicleID: 5,
        name:"Rolls Royce Phantom",
        tag:`ROYAL CLASS`,
        image2:"car8.png",
        passenger:2,
        bags:2,
    },
    {
        vechicleID: 6,
        name:"Bentley Mulsanne",
        tag:`ROYAL CLASS`,
        image2:"car16.png",
        passenger:2,
        bags:2,
    }
]