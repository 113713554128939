import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import  './index.css'
import {ArrowRightOutlined, CaretDownFilled} from '@ant-design/icons';
import {Typography, Image} from  'antd'
const {Text}= Typography

class Navbar extends Component
{
    constructor(props) {
        super(props)
        this.state = {
            IsNavShowing:false
        }
    }
    showLargeNav=()=>{
        var navbarSmall=document.getElementsByClassName("repla-navbar-small")[0];
        navbarSmall.setAttribute("class","repla-navbar-small secondary-bg animation repla-navbar-small-move")
        var navbar=document.getElementsByClassName("repla-navbar")[0];
        navbar.setAttribute('class',"repla-navbar secondary-bg repla-navbar-trans")
        this.setState({IsNavShowing:!this.state.IsNavShowing})
    }
    hideLargeNav=()=>{
        var navbarSmall=document.getElementsByClassName("repla-navbar-small")[0];
        navbarSmall.setAttribute("class","repla-navbar-small animation")
        var navbar=document.getElementsByClassName("repla-navbar")[0];
        navbar.setAttribute('class',"repla-navbar")
        this.setState({IsNavShowing: !this.state.IsNavShowing})
    }
    render()
    {
        return  (
            <>
                <div  className='repla-navbar-container' style={{position:'relative'}}>
                    <div className='w-100'>
                        <div className="repla-navbar-small secondary-bg">
                                <div className="repla-navbar-inner">
                                    <div className='repla-navbar-left'>
                                        <img 
                                            src={'/luxride/logo.png'} 
                                            width={'100%'}
                                            alt='epic-ride-london'
                                            />
                                    </div>
                                    <div className="repla-navbar-right">
                                        <div className="sp-cover" onClick={this.showLargeNav}>
                                            <span className="sp sp-1 sp-1-click"></span>
                                            <span className="sp sp-2 sp-2-click"></span>
                                            <span className="sp sp-3 sp-3-click"></span>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div className='repla-navbar' style={{flexDirection:"column",justifyContent:"space-between"}}>
                        <div className="repla-navbar-inner">
                            <div className='repla-navbar-left'>
                                <Image 
                                    src='/luxride/logo.png' 
                                    width={'100%'} 
                                    className='one'
                                    alt='epic-ride-london'
                                    preview={false}
                                    />
                                <img 
                                    src='/luxride/close.png' 
                                    className='two' 
                                    onClick={this.hideLargeNav}
                                    alt='epic-ride-london'
                                    />
                            </div>
                            <ul className="repla-navbar-middle">
                                <li className='items py-2'>
                                    <NavLink to="/" className="nav-item" onClick={this.hideLargeNav}>
                                        <div className='align-center'>
                                            <ArrowRightOutlined className="nav-arrow animation text-dark"/>
                                            <Text strong className='my-0 item-name'>Home</Text>
                                        </div>
                                    </NavLink>
                                </li>
                                <li className="items py-2">
                                    <NavLink to='/about-us' className="nav-item">
                                        <div className='align-center'>
                                            <ArrowRightOutlined className='nav-arrow animation text-dark'/>
                                            <Text strong className='my-0 item-name'>Epic</Text>
                                            <CaretDownFilled className='nav-arrowDown animation text-white'/>
                                        </div>
                                    </NavLink>
                                    <ul className='nav-item-menu secondary-bg'>
                                        <li>
                                            <NavLink to='/about-us' className='p-2 ' onClick={this.hideLargeNav}>
                                                <span className='brand-bg sp animation'></span>
                                                <Text strong className='my-0 text-white'>About us</Text>
                                            </NavLink>
                                        </li>
                                        <li className='onlymobile'> 
                                            <NavLink to='/fleets' className='p-2 ' onClick={this.hideLargeNav}>
                                                <span className='brand-bg sp animation'></span>
                                                <Text strong className='my-0 text-white'>Fleet</Text>
                                            </NavLink>
                                        </li>
                                        <li className='onlymobile'>
                                            <NavLink to='/services' className='p-2' onClick={this.hideLargeNav}>
                                                <span className='brand-bg sp animation'></span>
                                                <Text strong className='my-0 text-white'>Chauffeur Services</Text>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/faqs' className='p-2' onClick={this.hideLargeNav}>
                                                <span className='brand-bg sp animation'></span>
                                                <Text strong className='my-0 text-white'>FAQs</Text>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/get-a-quote' className='p-2' onClick={this.hideLargeNav}>
                                                <span className='brand-bg sp animation'></span>
                                                <Text strong className='my-0 text-white'>Get a Quote</Text>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/driver-registration' className='p-2' onClick={this.hideLargeNav}>
                                                <span className='brand-bg sp animation'></span>
                                                <Text strong className='my-0 text-white'>Driver Registration</Text>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/terms-&-conditions' className='p-2' onClick={this.hideLargeNav}>
                                                <span className='brand-bg sp animation'></span>
                                                <Text strong className='my-0 text-white'>Terms & Condition</Text>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li className="items py-2">
                                    <NavLink to='/fleets' className="nav-item">
                                        <div className='align-center'>
                                            <ArrowRightOutlined className='nav-arrow animation text-dark'/>
                                            <Text strong className='my-0 item-name'>Fleet</Text>
                                            <CaretDownFilled className='nav-arrowDown animation text-white'/>
                                        </div>
                                    </NavLink>
                                    <ul className='nav-item-menu secondary-bg'>
                                        <li>
                                            <NavLink to='/fleets/e-class' className='p-2 ' onClick={this.hideLargeNav}>
                                                <span className='brand-bg sp animation'></span>
                                                <Text strong className='my-0 text-white'>E Class</Text>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/fleets/v-class' className='p-2' onClick={this.hideLargeNav}>
                                                <span className='brand-bg sp animation'></span>
                                                <Text strong className='my-0 text-white'>V Class</Text>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/fleets/s-class' className='p-2' onClick={this.hideLargeNav}>
                                                <span className='brand-bg sp animation'></span>
                                                <Text strong className='my-0 text-white'>S Class</Text>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/fleets/range-rover' className='p-2' onClick={this.hideLargeNav}>
                                                <span className='brand-bg sp animation'></span>
                                                <Text strong className='my-0 text-white'>Ranger Rover</Text>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/fleets/rolls-royce' className='p-2' onClick={this.hideLargeNav}>
                                                <span className='brand-bg sp animation'></span>
                                                <Text strong className='my-0 text-white'>Rolls Royce</Text>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/fleets/bentley' className='p-2' onClick={this.hideLargeNav}>
                                                <span className='brand-bg sp animation'></span>
                                                <Text strong className='my-0 text-white'>Bentley</Text>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li className="items py-2">
                                    <NavLink to="/services" className="nav-item">
                                        <div className='align-center'>
                                            <ArrowRightOutlined className='nav-arrow animation text-dark'/>
                                            <Text strong className='my-0 item-name'>Chauffeur Services</Text>
                                            <CaretDownFilled className='nav-arrowDown animation text-white'/>
                                        </div>
                                    </NavLink>
                                    <ul className='nav-item-menu secondary-bg'>
                                        <li>
                                            <NavLink to='/services/airport-transfer-services' className='p-2' onClick={this.hideLargeNav}>
                                                <span className='brand-bg sp animation'></span>
                                                <Text strong className='my-0 text-white'>Airport transfer</Text>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/services/event-transfer-services' className='p-2' onClick={this.hideLargeNav}>
                                                <span className='brand-bg sp animation'></span>
                                                <Text strong className='my-0 text-white'>Event transfer</Text>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/services/executive-transfer-services' className='p-2' onClick={this.hideLargeNav}>
                                                <span className='brand-bg sp animation'></span>
                                                <Text strong className='my-0 text-white'>Executive transfer</Text>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/services/long-distance-chauffeur-services' className='p-2' onClick={this.hideLargeNav}>
                                                <span className='brand-bg sp animation'></span>
                                                <Text strong className='my-0 text-white'>Long distance chauffeur</Text>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/services/london-sightseeing-services' className='p-2' onClick={this.hideLargeNav}>
                                                <span className='brand-bg sp animation'></span>
                                                <Text strong className='my-0 text-white'>London sightseeing</Text>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/services/private-aviation-services' className='p-2' onClick={this.hideLargeNav}>
                                                <span className='brand-bg sp animation'></span>
                                                <Text strong className='my-0 text-white'>Private aviation</Text>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/services/southampton-executive-transfer-services' className='p-2' onClick={this.hideLargeNav}>
                                                <span className='brand-bg sp animation'></span>
                                                <Text strong className='my-0 text-white'>Southampton executive transfer</Text>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/services/wedding-transfer' className='p-2' onClick={this.hideLargeNav}>
                                                <span className='brand-bg sp animation'></span>
                                                <Text strong className='my-0 text-white'>Wedding transfer</Text>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li className='items py-2'>
                                    <NavLink to="/contact-us" className="nav-item" onClick={this.hideLargeNav}>
                                        <div className='align-center'>
                                            <ArrowRightOutlined className='nav-arrow animation text-dark'/>
                                            <Text strong className='my-0 item-name'>Contact Us</Text>
                                        </div>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className='parentsoc'>
                            <div className='social-media-icons'>
                                <NavLink to="https://www.facebook.com/people/Epic-Ride-London/61550257006200/" className="nav-item" onClick={this.hideLargeNav}>
                                    <div className='align-center'>
                                        <img src="/assets/icons/facebook.png" alt="" className='w-soc' />
                                    </div>
                                </NavLink>
                                <NavLink to="https://www.reddit.com/user/EpicRideLondon/" className="nav-item" onClick={this.hideLargeNav}>
                                    <div className='align-center'>
                                        <img src="/assets/icons/reddit.png" alt="" className='w-soc' />
                                    </div>
                                </NavLink>
                                <NavLink to="https://www.quora.com/profile/Epic-Ride-London" className="nav-item" onClick={this.hideLargeNav}>
                                    <div className='align-center'>
                                        <img src="/assets/icons/quora.png" alt="" className='w-soc' />
                                    </div>
                                </NavLink>
                                <NavLink to="https://www.instagram.com/epicridelondon/" className="nav-item" onClick={this.hideLargeNav}>
                                    <div className='align-center'>
                                        <img src="/assets/icons/instagram.png" alt="" className='w-soc' />
                                    </div>
                                </NavLink>
                                <NavLink to="https://www.pinterest.com/EpicRideLondon/" className="nav-item" onClick={this.hideLargeNav}>
                                    <div className='align-center'>
                                        <img src="/assets/icons/pinterest.png" alt="" className='w-soc' />
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    componentDidMount(state)
    {
        var navbar=document.getElementsByClassName("repla-navbar")[0]
        var navbarSmall=document.getElementsByClassName("repla-navbar-small")[0];
        window.addEventListener("resize",()=>{scrol();})
        function scrol()
                {
                    if(window.innerWidth>970)
                    {
                        if(window.pageYOffset<11)
                        {
                            navbar.setAttribute("class","repla-navbar animation transparent-bg")
                        }
                        else
                        {
                            navbar.setAttribute("class","repla-navbar animation repla-navbar-move")
                        }
                    }
                    else
                    {
                        navbar.setAttribute("class","repla-navbar")
                        if(window.pageYOffset<11)
                            navbarSmall.setAttribute("class","repla-navbar-small transparent-bg animation")
                        else
                            navbarSmall.setAttribute("class","repla-navbar-small animation repla-navbar-small-move")
                    }
                }  
                scrol();
                window.onscroll = function() {scrol()}
    }
}
export default Navbar;
