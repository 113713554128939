import React, { useState, useEffect } from 'react'
import {useParams} from 'react-router-dom'
import { Row, Col, Space, Typography, Image, Button } from 'antd'
import './mercedesservice.css'
import ImageGallery from 'react-image-gallery';
import { dataclass } from './data';

const { Title, Text } = Typography;

const MercedesService = () => {
    let { fleet } = useParams();
    const [state, setState]= useState(null)
    useEffect(()=>{
        if(fleet==='v-class')
            setState({...dataclass[0]?.vClass})
        else if(fleet==='bentley')
            setState({...dataclass[0]?.bentley})
        else if(fleet==='rolls-royce')
            setState({...dataclass[0]?.rollsRoyce})
        else if(fleet==='range-rover')
            setState({...dataclass[0]?.rangerRover})
        else if(fleet==='e-class')
            setState({...dataclass[0]?.eClass})
        else if(fleet==='s-class')
            setState({...dataclass[0]?.sClass})
        else 
        setState(null)
    },[fleet])
    const data = 
        {
            title:"Mercedes Benz S Class Chauffeur Service In London",
            tableContent:[
                {
                    text:'Passengers',
                    oppositetext:'3'
                },
                {
                    text:'Bag',
                    oppositetext:'2'
                },
                {
                    text:"ENGINE",
                    oppositetext:"3000",
                },
                {
                    text:"INTERIOR COLOR",
                    oppositetext:"Black",
                },
                {
                    text:"POWER",
                    oppositetext:"285",
                },
                {
                    text:"FUEL TYPE",
                    oppositetext:"Diesel",
                },
                {
                    text:"LENGTH",
                    oppositetext:"5.1 meters",
                },
                {
                    text:"TRANSMISSION",
                    oppositetext:"Auto",
                },
                {
                    text:"EXTRAS",
                    oppositetext:"Leather Seats,LED",
                },
            ],
            title2:'Luxurious Mercedes S Class Hire in London',
            desc1:'Mercedes S class hire in London is always a good choice for multiple purposes. The new Mercedes S-Class has been designed with sensual purity as an expression of modern luxury. It delivers the quality of service on every promise. The standard of luxury and innovation, Mercedes S Class at Pegasus Chauffeur comes with very competitive prices in London.',
            desc2:'There’s never been a better time to consider a Mercedes S Class, until now. With the unbeatable service, executive luxury and competitive price, we are offering a luxury chauffeur driven S-Class hire in London. Book our Mercedes S Class hire in London and UK wide to drive your most important clients and discerning delegates.',
            title3:'Mercedes S Class Specifications',
            speclist:[
                {
                    list:'Room for 4 passengers'
                },
                {
                    list:'Accommodation for 2 large luggage'
                },
                {
                    list:'Driving assistance'
                },
                {
                    list:'Individual Rear seat package'
                },
                {
                    list:'Nappa leather seats'
                },
                {
                    list:'Enhanced security system'
                },
                {
                    list:'Brake assistance'
                },
                {
                    list:'Panic alarm'
                },
                {
                    list:'Power handling'
                },
                {
                    list:'Traction control'
                },
                {
                    list:'Climate control'
                },
            ],
            title4:'Extra Compliments in Our Mercedes S Class Hire',
            complimentlist:[
                {
                    list:'Bottled water'
                },
                {
                    list:'Tissues'
                },
                {
                    list:'Magazines/daily newspapers'
                },
                {
                    list:'WiFi'
                },
                {
                    list:'Child/booster seats (on request)'
                },
            ],
            title5:'Mercedes S Class Chauffeur Driven Car is Popular for...',
            subtitle:'Financial Roadshows',
            desc3:'At Pegasus Chauffeur, we deliver thousands of roadshows every year providing a seamless chauffeuring experience to the biggest name in pharmaceuticals, banking sectors and several other demanding companies around the globe.',
            subtitle2:'Business Meetings',
            subtitle2a:'Corporate Events',
            desc4:'For a larger group, we often consider our MVP (Mercedes V class). But Mercedes S class has also been very popular for the limited passengers. With a luxury seating of 3 passengers, 1 driver and almost 2 luggage, Mercedes S class offer the first-class chauffeur service in London, UK. We handle multiple journeys per hour with our fast and highly efficient team members. We have our online booking software which enables us to handle multiple booking simultaneously, providing real-time monitoring of the vehicles of your discerning delegates.',
            subtitle3:'VIP Chauffeur Service',
            desc5:'Our VIP clients demand extra security and peace of mind. Mercedes S Class is the perfect choice for passengers travelling in higher-risk locations. We manage a secure transportation service to your desired location and supply you with the most appropriate car for this job. The black Mercedes S class hire with the most professional chauffeurs ensures a seamless experience and stress-free travel experience for all.',
            desc6:'Our highly experienced staff keeps in touch with the assigned chauffeur and the passenger or the passenger’s team to ensure you receive the ride you were looking for. Mercedes S class fulfills all the expectations and even exceeds them. The wait is over now! Just get a Mercedes S Class hire in London from Pegasus Chauffeur London, UK. Call us now to know more!'

        }
    
        const smallblock = [
            {
                image:"icon-1-c.png",
                title:"4",
                textc:"SEATING CAPACITY"
            },
            {
                image:"icon-2-c.png",
                title:"AUTOMATIC",
                textc:"TRANSMISION"
            },
            {
                image:"icon-3-c.png",
                title:"1500 CC",
                textc:"ENGINE"
            },
            {
                image:"icon-4-c.png",
                title:"FULL AC",
                textc:"COOLER"
            },
        ]
   

  return (
        <div className='py-5'>
            <Row gutter={[32,24]} style={{justifyContent:"center"}}>
                <Col xs={24} sm={24} md={24} lg={14}>
                    <div>
                        <ImageGallery 
                            items={state?.slider || []} 
                            autoPlay={true} 
                            showNav={false} 
                            showPlayButton={false}
                            />
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={10}>
                    <Space  style={{width:"100%",justifyContent:"center"}} size={30} direction='vertical'>
                        <Space  style={{width:"100%"}} direction='vertical'>
                            {
                                data?.tableContent?.map((tbcontent,tbc)=>
                                <div className='space-between border-bottom mt-3' key={tbc} style={{width:"100%"}}>
                                    <Text strong>{tbcontent?.text}</Text>
                                    <Text strong>{tbcontent?.oppositetext}</Text>
                                </div>
                                )
                            }
                        </Space>
                        <Button
                            type='primary'
                            size='large'
                            block
                            >
                            Book Now
                        </Button>
                    </Space>
                </Col>
            </Row>
            <Row gutter={[32, 32]} className='py-5'>    
                <Col 
                    lg={{span:12}} sm={{span:24}} xs={{span:24}} 
                    className='center'
                    >
                    <Space direction='vertical'>
                        <div>
                            <Title level={2} className='brand-color-blue'>
                                {
                                    state?.heading
                                }
                            </Title>
                            <Title level={1} className='my-0'>
                                {
                                    state?.details?.title
                                }
                            </Title>
                        </div>
                        <p className='my-0'>
                            {
                                state?.details?.firstParagraph
                            }
                        </p>
                        <p className='my-0'>
                            {
                                state?.details?.secondParagraph
                            }
                        </p>
                        <p className='my-0'>
                            {
                                state?.details?.thirdParagraph
                            }
                        </p>
                    </Space>
                </Col>
                <Col
                    lg={{span:12}} sm={{span:24}} xs={{span:24}} 
                    className='center'
                    >
                    {
                        state?.details?.image
                    }
                </Col>
            </Row>
            <Row gutter={[32,32]} className='my-5' style={{justifyContent:"center"}}>
                {
                    smallblock?.map((smalblk, index)=>
                    <Col 
                        xl={{span:6}} lg={{span:6}} md={{span:12}} sm={{span:12}} xs={{span:24}}
                        key={index}
                        >
                        <Space style={{border:"1px solid #E4E7EC",padding:"10px",width:"100%",textAlign:"center"}} size={0} direction='vertical'>
                            <Image 
                                src={'/luxride/'+smalblk?.image} 
                                alt='epic-ride-london'
                                preview={false}
                                />
                            <Title level={3} style={{marginBottom:"0px"}} className='pt-2'>{smalblk?.title}</Title>
                            <Text className='fs-12' strong>{smalblk?.textc}</Text>
                        </Space>
                    </Col>
                    )
                }            
            </Row>
        </div>
  )
}

export default MercedesService