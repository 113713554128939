import React, { useState, useEffect } from 'react'
import { Row, Col, Space, Typography, Image, Button, Divider } from 'antd'
import { useSelector, useDispatch} from 'react-redux'
import { actionsApi } from '../../shared'
import { QuantityCounter } from '../../components'
import { vehicles } from '../../Data/Vechicles'
const { Title, Text } = Typography

const ListedCars = () => {
    const {epicRide}= useSelector(state => state)
    const dispatch= useDispatch()
    const [current, setCurrent]= useState(null)
    const [state, setState]=useState([])
    const [extras, setExtras]= useState(null)
    console.log("epicRide:", epicRide)
    useEffect(()=>{
        if(epicRide)
        {
            setExtras(epicRide?.extras)
        }
    }, [epicRide])
    useEffect(()=>{
        setState([...vehicles?.filter((_, index)=> index<4)?.map(car => {
            let price=0
            if(epicRide?.serviceType===2)
            price= (car?.hourlyPrice * epicRide?.Duration).toFixed(2)
            else
            {
                if(epicRide?.Distance<=15)
                    price= car?.prices[0]
                else if(epicRide?.Distance>15 && epicRide?.Distance<=70)
                    price= (car?.prices[0]+ (car?.prices[1]*(epicRide?.Distance-15))).toFixed(2)
                else if(epicRide?.Distance<=1000)
                    price=  (car?.prices[0]+ (car?.prices[2]*(epicRide?.Distance-70))).toFixed(2)
            }
            return ({
                ...car,
                price
            })
        })])
    },[])

    useEffect(()=>{
        if(state)
        {
            let index= vehicles?.findIndex(vehicle=> vehicle?.vechicleID===epicRide?.vechicleID)
            console.log("index:", index)
            if(index)
                setCurrent(index)
        }
    },[state])
    console.log("current:", current)
    const setVehicle=(index)=>{
        setCurrent(index)
        let data={
            vechicleID: vehicles[index]?.vechicleID,
            VehicleName: vehicles[index]?.name,
            vehicleAmount: state[index]?.price,
            TotalBagCount: vehicles[index]?.bags,
            BagCount: 0,
            TotalPassengersCount: vehicles[index]?.passenger,
            PassengersCount: 0
        }
        dispatch(actionsApi?.updateEpicRideVehicle(data))
        const element = document.getElementById("details-box")
        element.scrollIntoView(false);
    }
  return (
    <Space 
        direction='vertical'
        size={45}
        style={{width:'100%'}}
        >
        {
            state?.map((car, index)=>
                    <Row 
                        key={'car-'+index} 
                        gutter={[16,16]}
                        style={{margin:0}}
                        >
                        <Col xs={24} sm={24} md={8}>
                            <Image 
                                src={'/luxride/'+car?.img} 
                                alt='epic-ride-london'
                                preview={false}
                                />
                        </Col>
                        <Col xs={24} sm={24} md={16}>
                            <Row gutter={[16,16]}>
                                <Col xs={24} sm={18}>
                                    <Title level={3} className='my-0'>{car?.name}</Title>
                                    <Title level={3} className='my-0 brand-color'>£ {(car?.price)}</Title>
                                </Col>
                                <Col xs={24} sm={6} className='align-end-center'>
                                    <Button  
                                        size='large' 
                                        shape='round' 
                                        onClick={()=>{setVehicle(index)}}
                                        type={current===(index)?'primary':'default'}
                                        >
                                            Select vehicle
                                    </Button>
                                </Col>
                                <Col span={24}>
                                    <Text
                                        strong
                                        style={{textAlign:"center",display:"block"}}>
                                        {
                                            car?.desc
                                        }
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Space style={{width:"100%",justifyContent:'center',flexWrap:"wrap"}} size={20}>
                                {
                                    car?.extraServices?.map((service, s)=>
                                    <Space size={10} key={'service-'+s}>
                                        <span>
                                            <Image 
                                                src={'/luxride/icons/'+service?.icons} 
                                                alt='epic-ride-london'
                                                preview={false}
                                                />
                                        </span>
                                        <Text strong>{service?.icontext}</Text>
                                    </Space>
                                    )
                                }
                            </Space>
                        </Col>
                        {
                            index< state?.length-1 &&
                            <Divider/>
                        }
                    </Row>
            )
        }
        <Row gutter={[32,24]}>
            <Col span={24}>
                <Space size={20}>
                    <Title level={3} className='my-0 brand-color-blue'>Passenger and luggages:</Title>
                </Space>
            </Col>
            <Col span={24}>
                <div className='space-between'>
                    <Text strong>
                        Passenger. {epicRide?.vehicle?.vechicleID ? `max ${epicRide?.vehicle?.TotalPassengersCount} seats available.`: ''}
                    </Text>
                    <QuantityCounter
                        name='PassengersCount'
                        quantity={epicRide?.vehicle?.PassengersCount}
                        available_stock={epicRide?.vehicle?.TotalPassengersCount}
                        passAndBags
                        />
                </div>
                <Divider/>
                <div className='space-between'>
                    <Text strong>
                        luggages and Bags. {epicRide?.vehicle?.vechicleID ? `max ${epicRide?.vehicle?.TotalBagCount} Bags space available.`: ''}
                    </Text>
                    <QuantityCounter
                        name='BagCount'
                        quantity={epicRide?.vehicle?.BagCount}
                        available_stock={epicRide?.vehicle?.TotalBagCount}
                        passAndBags
                        />
                </div>
            </Col>
        </Row>
        <Row gutter={[32,24]}>
            <Col span={24}>
                <Space size={20}>
                    <Title level={3} className='my-0 brand-color-blue'>Extra Options:</Title>
                </Space>
            </Col>
            <Col span={24}>
                <div className='space-between'>
                    <Text strong>
                        Child Seat <span className='brand-color'>£{extras?.childSeat?.price}</span>. Baby car seat for children aged 0-36 months
                    </Text>
                    <QuantityCounter
                        name='childSeat'
                        quantity={extras?.childSeat?.quantity}
                        available_stock={extras?.childSeat?.maxQuantity}
                    />
                </div>
                <Divider/>
                <div className='space-between'>
                    <Text strong>
                        Bouquet of Flowers <span className='brand-color'>£{extras?.bouquet?.price}</span>. A bouquet of seasonal flowers prepared by a local florist
                    </Text>
                    <QuantityCounter
                        name='bouquet'
                        quantity={extras?.bouquet?.quantity}
                        available_stock={extras?.bouquet?.maxQuantity}
                    />
                </div>
                <Divider/>
                <div className='space-between'>
                    <Text strong>
                        Champagne <span className='brand-color'>£{extras?.champagne?.price}</span>. French Champagne Brut 0.75l Bottle
                    </Text>
                    <QuantityCounter
                        name='champagne'
                        quantity={extras?.champagne?.quantity}
                        available_stock={extras?.champagne?.maxQuantity}
                    />
                </div> 
            </Col>
        </Row>
    </Space>
  )
}

export default ListedCars