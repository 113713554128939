import React from 'react'
import { Typography, Space, Divider, Row, Col } from 'antd'
import { useSelector } from 'react-redux'
const { Title, Text } = Typography

const LuxrideSidebar = () => {
    const {epicRide}= useSelector(state=>state)
    console.log("hanan:",epicRide)
  return (
    <div style={{position:"sticky",top:"0"}}>  
        <div className='light-bg px-3 py-4'>
            <Space size={20} direction='vertical' style={{width:"100%"}}>
                <Title level={4}>Summary</Title>
                <Space direction='vertical'>
                    <Text>SERVICE TYPE</Text>
                    <Text strong>
                        {
                            epicRide?.serviceType===1 ? "Distance" : "Hourly"
                        }
                    </Text>
                </Space>
            </Space>
            <Divider/>
            <Space direction='vertical'>
                <Text>TRANSFER TYPE</Text>
                <Text strong>
                {
                    epicRide?.travelMode===1? 'one way' : 'two way'
                }
                </Text>
            </Space>
            <Divider/>
            <Space direction='vertical'>
                <Text>FROM</Text>
                <Text strong>
                    {
                        epicRide?.wayTo?.pickupLocation
                    }
                </Text>
            </Space>
            <Divider/>
            {
                epicRide?.wayTo?.wayPoints?.length>0 &&
                <>
                    <Space direction='vertical'>
                        <Text>WAYPOINTS</Text>
                        <ul style={{listStyleType:'circle',margin:0}}>
                            {
                                epicRide?.wayTo?.wayPoints?.map((wayPoints, index)=>
                                    <li key={'waypoint-'+index}>
                                        <Text strong>
                                            {
                                                wayPoints
                                            }
                                        </Text>
                                    </li>
                                )
                            }
                        </ul>
                    </Space>
                    <Divider/>
                </>
            }
            <Space direction='vertical'>
                <Text>To</Text>
                <Text strong>
                    {
                        epicRide?.wayTo?.dropOffLocation
                    }
                </Text>
            </Space>
            <Divider/>
            <Space direction='vertical'>
                <Text>PICKUP DATE, TIME</Text>
                <Text strong>
                    {
                        epicRide?.pickupDate &&
                        epicRide?.pickupDate+" "+epicRide?.pickupTime
                    }
                </Text>
            </Space>
            <Divider/>
            <Space size={20} style={{width:"100%",justifyContent:"space-between"}}>
                <Space direction='vertical'>
                    <Text>Total distance</Text>
                    <Text strong>
                        {
                            epicRide?.Distance ? epicRide?.Distance +' mi' : '0 mi'
                        }
                    </Text>
                </Space>
                <Space direction='vertical'>
                    <Text>Total time</Text>
                    <Text strong>
                        {
                            epicRide?.Duration ? epicRide?.Duration +( epicRide?.serviceType===2 ? ' hours':'')
                            : "0 hrs 0 mins"
                        }
                    </Text>
                </Space>
            </Space>
            <Divider/>
            <Space direction='vertical' style={{width:"100%"}}>
                <Space style={{width:"100%",justifyContent:"space-between"}}>
                    <Text>Total</Text>
                    <Text strong>
                        {
                            epicRide?.vehicle?.vehicleAmount || epicRide?.extrasAmount ? 
                                ('£'+(parseFloat(epicRide?.vehicle?.vehicleAmount) + epicRide?.extrasAmount).toFixed(2)): '£0.00' 
                        }
                    </Text>
                </Space>
                <Space style={{width:"100%",justifyContent:"space-between"}}>
                    <Text>To Pay (100% deposit)</Text>
                    <Text strong>
                        {
                            epicRide?.vehicle?.vehicleAmount || epicRide?.extrasAmount ? 
                                ('£'+(parseFloat(epicRide?.vehicle?.vehicleAmount) + epicRide?.extrasAmount).toFixed(2)): '£0.00' 
                        }
                    </Text>
                </Space>
            </Space>
        </div>
    </div>
  )
}

export default LuxrideSidebar