import {
    loadDashboard, 
    updateEpicRide, 
    updateEpicRideVehicle,
    updateServiceType,
    updateExtras
} from "./action/epicRide"
export const actionsApi = {
    loadDashboard,
    updateEpicRide,
    updateEpicRideVehicle,
    updateServiceType,
    updateExtras
}
