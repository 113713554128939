import {Space, Image, Typography} from "antd"
import "./index.css"
export const ChoosePaymentMethod= ({paymentMethod, setPaymentMethod})=>{
    return (
        <div className="center">
            <Space className="payment-methods-cover">
                <Space 
                    className={"payment-method p-2 " + (paymentMethod===1 ? " payment-method-selected":'') } 
                    onClick={()=>{setPaymentMethod(1)}}
                    size={5}
                    direction="vertical"
                    >
                    <Typography.Title level={5} className="my-0 width-100 text-center">
                        Credit/Debit Card
                    </Typography.Title>
                    <Space>
                        <Image 
                            src='/luxride/icons/visa.png' 
                            alt='epic ride london' 
                            style={{width:'55px'}} 
                            preview={false}
                            />
                        <Image 
                            src='/luxride/icons/american-express.png' 
                            alt='epic ride london' 
                            style={{width:'55px'}} 
                            preview={false}
                            /> 
                        <Image 
                            src='/luxride/icons/master.png' 
                            alt='epic ride london' 
                            style={{width:'55px'}} 
                            preview={false}
                            />
                    </Space>
                </Space>
                <div 
                    className={"payment-method p-2" + (paymentMethod===2 ? " payment-method-selected":'')} 
                    onClick={()=>{setPaymentMethod(2)}}
                    >
                    <Image 
                        src="/assets/paypal.png" 
                        alt="epic ride london" 
                        preview={false}
                        />
                </div>
            </Space>
        </div>
    )
}