import { NavLink } from "react-router-dom";
import './index.css'
import {Button, Space, Typography} from  'antd'
const {Title}=Typography;
const BreadCrumb=({title,pageName,breadcrumb})=>
    {
        return(
            <div 
                style={{backgroundImage:`url(${'/luxride/london-big-ben-night.jpg'})`,width:'100%'}}
                className='title flex-col-center-center py-5'
            >
                <Space direction="vertical" className='my-5' style={{width:'93%'}}>
                        <Title 
                            level={1} 
                            className='justify-center brand-color my-0'
                            data-aos="fade-down"
                            data-aos-duration="1000" 
                            data-aos-once="true"
                            data-aos-delay="500"
                            >
                            {title}
                        </Title>
                        <div 
                            className="justify-center"
                            data-aos="fade-up"
                            data-aos-duration="1000" 
                            data-aos-once="true"
                            data-aos-delay="500"
                            >
                            {
                                breadcrumb.map(
                                    (breadCrumb,b)=> 
                                        <Typography.Text strong key={'bread-crumb-'+b}>
                                            <NavLink to={breadCrumb?.to} className='text-white'>{breadCrumb?.name}</NavLink>
                                            <span className="mx-2 text-white">/</span>
                                        </Typography.Text>
                                    )
                            }
                            <Typography.Text strong  className="brand-color">{pageName}</Typography.Text>
                        </div>
                        <Space 
                            className="justify-center"
                            data-aos="fade-up"
                            data-aos-duration="1000" 
                            data-aos-once="true"
                            data-aos-delay="500"
                            >
                            <NavLink to='/'>
                                <Button type="primary">Book Now</Button>
                            </NavLink>
                            <NavLink to='/contact-us'>
                                <Button type="primary" className="btn-blue">Contact Us</Button>
                            </NavLink>
                        </Space>
                </Space>
            </div>
        )
    }
export {BreadCrumb}