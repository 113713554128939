import React from 'react'
import Choose from '../../components/luxridetrip'
import LuxrideRegion from '../../components/luxrideregion'
import LuxrideFaqs from '../../components/luxridefaqs'
import LuxrideMainSlider from '../../components/luxridemainslider/LuxrideMainSlider'
import LuxrideFleet from '../../components/luxridefleet/LuxrideFleet'
import LuxrideTestimonial from '../../components/luxridetestimonial'
import LuxrideFourSec from '../../components/luxrideFour'
import { BookingInstructions, CarsGroup} from '../../components'
import LuxrideTabsForm from '../../components/luxridetabsform'
import { LuxrideLastestnews } from '../../components'
import "./index.css"
const LuxrideHomePage = () => {
  return (
    <div>
        <LuxrideMainSlider />
        <div  
          className='py-5 calculator'
          >
           <div className='width-93'>
            <LuxrideTabsForm lightGreyBg={true}/>
           </div>
        </div>
        <LuxrideFleet />
        <BookingInstructions/>
        <LuxrideTestimonial />
        <LuxrideRegion />
        <LuxrideFourSec />
        <LuxrideFaqs />
        <CarsGroup/>
        <LuxrideLastestnews />
        <Choose />
    </div>
  )
}

export default LuxrideHomePage